import React from "react";
import { RiBardFill } from "react-icons/ri";
import resident from "../../assets/images/residentIdentification.svg";
import vehicles from "../../assets/images/vehicleValidation.svg";
import visitors from "../../assets/images/visitors.svg";


const SecurityFeatures = () => {
  return (
    <section className=" py-10">
      <div className="container mx-auto px-4 mt-5 xl:mt-24 flex flex-col items-center justify-center md:w-[65%] xl:mb-[70px] lg:w-[75%] ">
        <div className="inline-flex  items-center bg-orange-50 gap-2.5 px-5 py-2 rounded-full mx-auto justify-center ">
          <RiBardFill className="text-[#FFB466] text text-2xl " />
          <p className="font-inter text-xs md:text-lg text-new-black-1000 font-medium">
            Features
          </p>
        </div>
        <p
          className="font-sora text-center text-[30px] leading-[40px] font-semibold my-4 xl:my-5 xl:text-5xl xl:leading-[60px]"
          style={{ textWrap: "balance" }}
        >
          Simplifying Living Experiences Across Africa
        </p>
      </div>
      <div className="space-y-4 md:space-y-10 p-4">
        <div className="mx-auto container flex flex-col sm:flex-row gap-4 md:gap-10 justify-center ">
          <div className="rounded-lg border border-grey-100 flex-1 max-w-[594px] overflow-hidden bg-white">
            <div className="p-6 xl:p-[66px] xl:pb-[45px] ">
              <p className="font-sora font-semibold text-new-black-1000 sm:text-lg lg:text-2xl">
              Residents Identification
              </p>
              <p className="font-inter text-xs text-[#4D4D4D] mt-3 leading-[150%]   lg:text-base xl:leading-[26px] md:min-h-[104px]">
              Our community safety tool allows you access low-cost, low-maintenance resident identification using unique single-use QR codes and NFC Tags.
              </p>
            </div>
            <div className="bg-light-green-500 h-[287px] md:h-[320px] lg:h-[468px] relative  overflow-hidden">
              <img
                src={resident}
                alt=""
                className="absolute left-1/2 -translate-x-1/2 xl:top-[59px] scale-[80%] md:scale-[80%] lg:scale-[80%] lg:top-14"
              />
            </div>
          </div>
          <div className="rounded-lg border border-grey-100 flex-1 max-w-[594px] overflow-hidden bg-white">
            <div className="p-6 xl:p-[66px] xl:pb-[45px] ">
              <p className="font-sora font-semibold text-new-black-1000 sm:text-lg lg:text-2xl">
              Vehicles Validation
              </p>
              <p className="font-inter text-xs text-[#4D4D4D] mt-3 leading-[150%]   lg:text-base xl:leading-[26px] md:min-h-[104px]">
              Easily identify residents, regular visitors, and vehicles using digital codes, cards and car stickers (NFC and RFID).
              </p>
            </div>
            <div className="bg-orange-100 h-[287px] md:h-[320px] lg:h-[468px] relative  overflow-hidden">
              <img
                src={vehicles}
                alt=""
                className="absolute left-1/2 -translate-x-1/2 xl:top-[59px] scale-[80%] md:scale-[80%] lg:scale-100 lg:top-14"
              />
            </div>
          </div>
        </div>
       
        <div className="mx-auto container flex flex-col sm:flex-row gap-4 md:gap-10 justify-center ">
          <div className="rounded-lg border border-grey-100 flex-1 max-w-[594px] overflow-hidden bg-white">
            <div className="p-6 xl:p-[66px] xl:pb-[45px] ">
              <p className="font-sora font-semibold text-new-black-1000 sm:text-lg lg:text-2xl">
              Visitors & Support Staff Access Control
              </p>
              <p className="font-inter text-xs text-[#4D4D4D] mt-3 leading-[150%]   lg:text-base xl:leading-[26px] md:min-h-[104px]">
              Screen visitors and regular domestic staff digitally using secure, single-use access codes. Book repeating visits for regular visitors or use our digital cards for long-term visitors.
              </p>
            </div>
            <div className="bg-blue-150 h-[287px] md:h-[320px] lg:h-[468px] relative  overflow-hidden">
              <img
                src={visitors}
                alt=""
                className="absolute left-1/2 -translate-x-1/2 xl:top-[59px] scale-[80%] md:scale-[80%] lg:scale-100 lg:top-14 max-w-[402px]"
              />
            </div>
          </div>
          <div className="rounded-lg flex-1 max-w-[594px] overflow-hidden"></div>
        </div>
      </div>
    </section>
  );
};

export default SecurityFeatures;
