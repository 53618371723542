import React, { useState } from "react";
import { Helmet } from "react-helmet";
import NavBarDark from "../../components/NAVBAR/NavBarDark";
import Footer from "../../components/FOOTER/Footer";
import FAQ from "../../components/FAQ/FAQ";
import HowItWorksDesktop from "../../components/HowItWorks/howItWorksDesktop";
import { security } from "../../components/HowItWorks/howItWorksData";
import DownloadCTA from "../../components/HowItWorks/DownloadCTA";
import HowItWorksMobile from "../../components/HowItWorks/howItWorksMobile";

const SecurityHowItWorks = () => {
  const [accordion1, setAccordion1] = useState(1);

  return (
    <>
      <Helmet>
        <title>
          Get Started - Venco | All-in-one Community Management Software
        </title>
        <meta
          property="og:title"
          content="Get Started - Venco | All-in-one Community Management Software"
        />
        <meta
          name="twitter:title"
          content="Get Started - Venco | All-in-one Community Management Software"
        />
      </Helmet>
      <NavBarDark />
      <section className="container mx-auto my-10 xl:py-[100px] p-4">
        <p
          className="rounded-full px-6 py-2 bg-light-purple-150  center w-fit mx-auto leading-8 font-medium text-xs
        
        
        "
        >
          How it works
        </p>

        <div className="h-5 md:h-5"></div>
        <p className="font-sora font-semibold text-center md:text-3xl lg:text-4xl xl:text-5xl md:max-w-[75%] mx-auto leading-normal lg:leading-normal xl:leading-[66px]">
          Venco Security App Access Walkthrough for Security Personnel
        </p>
        <div className="h-3 md:h-5"></div>
        <p className="tet-[#4D4D4D] text-sm lg:text-xl text-center md:max-w-[55%] mx-auto">
          To access the Venco Security App and enhance the security measures in
          your estate, please follow these simple steps:
        </p>
        <div className="h-10 lg:h-40"></div>
        {/* Content Section Mobile */}
        <HowItWorksMobile
          data={security}
          scrollContainerProp="adminContainer1"
          scrollIndicatorProp="adminIndicator1"
        />

        <HowItWorksDesktop
          accordion={accordion1}
          setAccordion={setAccordion1}
          data={security}
          compId="admin1"
        />
      </section>
      <DownloadCTA />
      <FAQ />
      <Footer />
    </>
  );
};

export default SecurityHowItWorks;
