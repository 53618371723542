import React from "react";
import { Helmet } from "react-helmet";

import Footer from "../components/FOOTER/Footer";
import { Link } from "react-router-dom";
import NavBarDark from "../components/NAVBAR/NavBarDark";

const PageNotFound = () => {
  
  return (
    <>
      <Helmet>
        <title>
          Page not Found - Venco | All-in-one Community Management Software
        </title>
        <meta
          property="og:title"
          content="Home - Venco | All-in-one Community Management Software"
        />
        <meta
          name="twitter:title"
          content="Home - Venco | All-in-one Community Management Software"
        />
      </Helmet>
      <NavBarDark />
      <div class="container min-h-[70vh] center flex-col">
        <p className="font-sora text-green-500 font-semibold pt-2.5 md:pt-5  text-[26px] leading-[32px] md:text-4xl lg:text-5xl xl:text-6xl text-center">
          Page not Found
        </p>
        <div className="h-6"></div>
        <Link to="/" className="primaryButton px-4 md:px-8">
          {" "}
          Go to Home
        </Link>
      </div>
      <Footer />
      <div>PageNotFound</div>
    </>
  );
};

export default PageNotFound;
