import React from "react";
import { RiBardFill } from "react-icons/ri";
import community from "../../assets/images/communityDashboard.svg";
import billCollect from "../../assets/images/billCollections.svg";
import easyIssue from "../../assets/images/easyIssue.svg";
import smartVending from "../../assets/images/smartVending.svg";
import communication from "../../assets/images/communicationManagement.png";

const AdminFeatures = () => {
  return (
    <section className="bg-[#f8f8f8] py-10 mt-5">
      <div className="container mx-auto px-4 mt-5 xl:mt-24 flex flex-col items-center justify-center md:w-[65%] xl:mb-[70px] lg:w-[75%] ">
        <div className="inline-flex  items-center bg-blue-100 gap-2.5 px-5 py-2 rounded-full mx-auto justify-center ">
          <RiBardFill className="text-[#6CCBF2] text text-2xl " />
          <p className="font-inter text-xs md:text-lg text-new-black-1000 font-medium">
            Features
          </p>
        </div>
        <p
          className="font-sora text-center text-[30px] leading-[40px] font-semibold my-4 xl:my-5 xl:text-5xl xl:leading-[60px]"
          style={{ textWrap: "balance" }}
        >
          Simplifying Living Experiences Across Africa
        </p>
      </div>
      <div className="space-y-4 md:space-y-10 p-4">
        <div className="mx-auto container flex flex-col sm:flex-row gap-4 md:gap-10 justify-center ">
          <div className="rounded-lg border border-grey-100 flex-1 max-w-[594px] overflow-hidden bg-white">
            <div className="p-6 xl:p-[66px] xl:pb-[45px] ">
              <p className="font-sora font-semibold text-new-black-1000 sm:text-lg lg:text-2xl">
                Community Dashboard
              </p>
              <p className="font-inter text-xs text-[#4D4D4D] mt-3 leading-[150%]   lg:text-base xl:leading-[26px] md:min-h-[104px]">
                Access information about activities within your community all in
                one place from occupancy levels, total outstanding receivables,
                number of visitors, etc.
              </p>
            </div>
            <div className="bg-orange-50 h-[287px] md:h-[320px] lg:h-[468px] relative  overflow-hidden">
              <img
                src={community}
                alt=""
                className="absolute left-1/2 -translate-x-1/2 xl:top-[59px] scale-[80%] md:scale-[80%] lg:scale-[80%] lg:top-14"
              />
            </div>
          </div>
          <div className="rounded-lg border border-grey-100 flex-1 max-w-[594px] overflow-hidden bg-white">
            <div className="p-6 xl:p-[66px] xl:pb-[45px] ">
              <p className="font-sora font-semibold text-new-black-1000 sm:text-lg lg:text-2xl">
                Bills Collections
              </p>
              <p className="font-inter text-xs text-[#4D4D4D] mt-3 leading-[150%]   lg:text-base xl:leading-[26px]">
                Our facility management tool can solve late and non-payments of
                bills and dues within your community and implement revenue
                assurance measures. Generate financial reports and dashboards
                instantly.
              </p>
            </div>
            <div className="bg-orange-50 h-[287px] md:h-[320px] lg:h-[468px] relative  overflow-hidden">
              <img
                src={billCollect}
                alt=""
                className="absolute left-1/2 -translate-x-1/2 xl:top-[59px] scale-[80%] md:scale-[80%] lg:scale-100 lg:top-14"
              />
            </div>
          </div>
        </div>
        <div className="mx-auto container flex flex-col sm:flex-row gap-4 md:gap-10 justify-center ">
          <div className="rounded-lg border border-grey-100 flex-1 max-w-[594px] overflow-hidden bg-white">
            <div className="p-6 xl:p-[66px] xl:pb-[45px] ">
              <p className="font-sora font-semibold text-new-black-1000 sm:text-lg lg:text-2xl">
                Easy Issue Management
              </p>
              <p className="font-inter text-xs text-[#4D4D4D] mt-3 leading-[150%]   lg:text-base xl:leading-[26px] md:min-h-[104px]">
                Activate real-time issue reporting and resolution within the
                community. Implement Service Level Agreements and receive
                detailed issue tracking reports and dashboards.
              </p>
            </div>
            <div className="bg-orange-50 h-[287px] md:h-[320px] lg:h-[468px] relative  overflow-hidden">
              <img
                src={easyIssue}
                alt=""
                className="absolute left-1/2 -translate-x-1/2 xl:top-[59px] scale-[80%] md:scale-[80%] lg:scale-100 lg:top-14"
              />
            </div>
          </div>
          <div className="rounded-lg border border-grey-100 flex-1 max-w-[594px] overflow-hidden bg-white">
            <div className="p-6 xl:p-[66px] xl:pb-[45px] ">
              <p className="font-sora font-semibold text-new-black-1000 sm:text-lg lg:text-2xl">
                Smart Utility Vending
              </p>
              <p className="font-inter text-xs text-[#4D4D4D] mt-3 leading-[150%]   lg:text-base xl:leading-[26px] md:min-h-[104px]">
                Automate utility vending, purchase pre-paid energy meters,
                access power purchase and consumption reports. Our platform work
                with any STS-compliant meter such as Conlog, Momas, Hexing,
                Miraton Rose.
              </p>
            </div>
            <div className="bg-orange-50 h-[287px] md:h-[320px] lg:h-[468px] relative  overflow-hidden">
              <img
                src={smartVending}
                alt=""
                className="absolute left-1/2 -translate-x-1/2 xl:top-[59px] scale-[80%] md:scale-[80%] lg:scale-100 lg:top-14"
              />
            </div>
          </div>
        </div>
        <div className="mx-auto container flex flex-col sm:flex-row gap-4 md:gap-10 justify-center ">
          <div className="rounded-lg border border-grey-100 flex-1 max-w-[594px] overflow-hidden bg-white">
            <div className="p-6 xl:p-[66px] xl:pb-[45px] ">
              <p className="font-sora font-semibold text-new-black-1000 sm:text-lg lg:text-2xl">
                Communication Management
              </p>
              <p className="font-inter text-xs text-[#4D4D4D] mt-3 leading-[150%]   lg:text-base xl:leading-[26px] md:min-h-[104px]">
                Stop maintaining outdated email lists. Enable seamless and
                regular communication with residents within the community based
                on up-to-date contact details maintained for each property.
              </p>
            </div>
            <div className="bg-orange-50 h-[287px] md:h-[320px] lg:h-[468px] relative  overflow-hidden">
              <img
                src={communication}
                alt=""
                className="absolute left-1/2 -translate-x-1/2 xl:top-[59px] scale-[80%] md:scale-[80%] lg:scale-100 lg:top-14 max-w-[402px]"
              />
            </div>
          </div>
          <div className="rounded-lg flex-1 max-w-[594px] overflow-hidden"></div>
        </div>
      </div>
    </section>
  );
};

export default AdminFeatures;
