import React from "react";

const BlogHero = () => {
  return (
    <>
    <section className="w-full max-h-[605px] h-[25vh] bg-grey-50 center xl:h-[400px] ">
    <div className="container mx-auto px-4">
      <p className="font-sora text-green-500 font-semibold pt-2.5 md:pt-5  text-[26px] leading-[32px] md:text-4xl lg:text-5xl xl:text-6xl text-center">
      The Venco Blog
      </p>

      <div className="h-4 lg:h-6"></div>
      <p className=" w-3/4  md:w-2/3 text-center text-sm leading-[22px] md:text-lg lg:text-xl  lg:leading-[32px] mx-auto font-inter text-green-500 max-w-[683px] ">
      We simplify living experiences across Africa with our all-in-one
          community management solution.
      </p>
      
    </div>
  </section>
    </>
  );
};

export default BlogHero;
