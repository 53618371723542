import React from "react";


import howCTA from "../../assets/svg/howCTA.svg";

import { Link } from "react-router-dom";

const HowCTAComp = ({ title, link }) => {
  return (
    <section className=" w-full bg-light-purple-50 p-1 relative overflow-hidden">
      <div className="container mx-auto px-4 my-10 xl:my-[170px] relative z-[1]">
        <p className="font-sora text-green-500 font-semibold pt-2.5 md:pt-5  text-[26px] leading-[137.5%] md:text-3xl lg:text-4xl xl:text-5xl lg:leading-[137.5%] xl:leading-[66px] text-center lg:max-w-[68%] mx-auto">
          {title}
        </p>

        <div className="h-5 md:h-6 lg:h-10"></div>
        <div className="flex justify-center gap-4 flex-col md:flex-row">
          <Link
            to={link}
            className="py-4 px-10 text-green-500 bg-light-green-500 hover:bg-light-green-600 rounded-lg font-semibold duration-300 transition-all text-sm md:text-base xl:text-lg text-center flex center gap-1.5"
          >
            See how it works
          </Link>
        </div>
      </div>
      <div className="absolute top-1/2 -translate-y-1/2 left-0  hidden lg:block z-[0] w-full">
        <img src={howCTA} alt="" srcset="" />
      </div>
      {/* <div className="absolute top-1/3 -translate-y-1/2  hidden lg:block z-[0] -left-24">
        <img src={howCTAleft} alt="" srcset="" />
      </div>
      <div className="absolute top-2/3 -translate-y-1/2  hidden lg:block z-[0] -right-24">
        <img src={howCTAleft} alt="" srcset="" />
      </div> */}
    </section>
  );
};

export default HowCTAComp;
