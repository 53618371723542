import React from "react";

// import Hero from "../components/HERO/Hero";
import Stats from "../components/HOMEPAGE/Stats";
import AllInOne from "../components/HOMEPAGE/AllInOne";
import KeyFeatures from "../components/HOMEPAGE/KeyFeatures";
import FeaturesTab from "../components/HOMEPAGE/FeaturesTab";
import Testimonial from "../components/HOMEPAGE/Testimonial";
import Logos from "../components/HOMEPAGE/Logos";
import UnlockCTA from "../components/HOMEPAGE/UnlockCTA";
import JoinTheBest from "../components/HOMEPAGE/JoinTheBest";
import Footer from "../components/FOOTER/Footer";
import HeroVariation from "../components/HERO/HeroVariation";
// import NavBar from "../components/NAVBAR/NavBar";
import NavBarDark from "../components/NAVBAR/NavBarDark";
import { Helmet } from "react-helmet";

const HOME = () => {
  return (
    <>
      <Helmet>
        <title>Home - Venco | All-in-one Community Management Software</title>
        <meta
          property="og:title"
          content="Home - Venco | All-in-one Community Management Software"
        />
        <meta
          name="twitter:title"
          content="Home - Venco | All-in-one Community Management Software"
        />
      </Helmet>
      <NavBarDark />
      {/* <NavBar /> */}
      {/* <Hero /> */}
      <HeroVariation />
      <Stats />
      <AllInOne />
      <KeyFeatures />
      <FeaturesTab />
      <Testimonial />
      <Logos />
      <UnlockCTA />
      <JoinTheBest />
      <Footer />
    </>
  );
};

export default HOME;
