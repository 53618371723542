import React from "react";
// import NavBar from "../components/NAVBAR/NavBar";
import NavBarDark from "../components/NAVBAR/NavBarDark";
import Stats from "../components/HOMEPAGE/Stats";
import Testimonial from "../components/HOMEPAGE/Testimonial";
import FAQ from "../components/FAQ/FAQ";
import JoinTheBest from "../components/HOMEPAGE/JoinTheBest";
import Footer from "../components/FOOTER/Footer";
import SecurityHero from "../components/SECURITY/SecurityHero";
import SecurityFeatures from "../components/SECURITY/SecurityFeatures";
import { Helmet } from "react-helmet";
import HowCTAComp from "../components/HowItWorks/HowCTAComp";

const SECURITYPage = () => {
  return (
    <>
      <Helmet>
        <title>
          Security - Venco | All-in-one Community Management Software
        </title>
        <meta
          property="og:title"
          content="Security - Venco | All-in-one Community Management Software"
        />
        <meta
          name="twitter:title"
          content="Security - Venco | All-in-one Community Management Software"
        />
      </Helmet>
      <NavBarDark />
      {/* <NavBar /> */}
      <SecurityHero />
      <Stats />
      <SecurityFeatures />
      <HowCTAComp
        title={
          " Venco is dedicated to reinforcing the safety and security of your community."
        }
        link={"/how-it-works-for-security"}
      />
      <Testimonial />
      <FAQ />
      <JoinTheBest />
      <Footer />
    </>
  );
};

export default SECURITYPage;
