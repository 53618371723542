import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import Footer from "../components/FOOTER/Footer";
import JoinTheBest from "../components/HOMEPAGE/JoinTheBest";
import NavBarDark from "../components/NAVBAR/NavBarDark";

import { RiLink, RiLinkedinBoxFill, RiTwitterFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

const BlogIndividualPage = () => {
  const { slug } = useParams();
  // const [blogList, setBlogList] = useState();
  const [currentBlog, setCurrentBlog] = useState();
  const blogList = useSelector((state) => state.blogs.data);

  useEffect(() => {
    const filterBlog = blogList?.filter((item, index) => {
      return item.slug === slug;
    });
    setCurrentBlog(filterBlog);
    // eslint-disable-next-line
  }, [blogList]);

  const currentUrl = window.location.href;
  // eslint-disable-next-line
  const linkedinShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
    currentUrl
  )}&title=${encodeURIComponent(
    currentBlog && currentBlog[0].title?.rendered
  )}`;
  // eslint-disable-next-line
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    currentUrl
  )}&text=${encodeURIComponent(currentBlog && currentBlog[0].title?.rendered)}`;

  const [toggleTooltip, setToggleTooltip] = useState(false);
  return (
    <>
      {currentBlog && (
        <Helmet>
          <title>{`${currentBlog[0].title?.rendered} - Venco | All-in-one Community Management Software`}</title>
          <meta
            property="og:title"
            content={`${currentBlog[0].title?.rendered} - Venco | All-in-one Community Management Software`}
          />
          <meta
            name="twitter:title"
            content={`${currentBlog[0].title?.rendered} - Venco | All-in-one Community Management Software`}
          />
          <meta
            property="og:image"
            content={currentBlog[0].yoast_head_json?.og_image[0].url}
          />
          <meta
            property="og:site_name"
            content="Venco | All-in-one Community Management Software"
          />
          <meta
            name="twitter:title"
            content={`${currentBlog[0].title?.rendered} - Venco | All-in-one Community Management Software`}
          />
          <meta
            name="twitter:description"
            content={currentBlog[0].yoast_head_json?.og_description}
          />
          <meta
            name="twitter:creator"
            content={currentBlog[0].yoast_head_json?.twitter_creator}
          />
          <meta name="twitter:label1" content="Written by" />
          <meta
            name="twitter:data1"
            content={
              currentBlog[0].yoast_head_json?.twitter_misc[
                "Estimated reading time"
              ]
            }
          />
          <meta name="twitter:label2" content="Est. reading time" />
          <meta
            name="twitter:data2"
            content={currentBlog[0].yoast_head_json?.twitter_misc["Written by"]}
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            property="og:image:width"
            content={currentBlog[0].yoast_head_json?.og_image[0].width}
          />
          <meta
            property="og:image:height"
            content={currentBlog[0].yoast_head_json?.og_image[0].height}
          />
          <meta
            property="og:image:type"
            content={currentBlog[0].yoast_head_json?.og_image[0].type}
          />
        </Helmet>
      )}
      <section className="bg-[#F9F9F9]">
        <NavBarDark />
        {currentBlog ? (
          <section className="container mx-auto p-4 py-10 xl:py-24">
            <p
              className="max-w-[1262px] text-zinc-900 text-2xl leading-[150%] xl:text-[56px] font-semibold font-['Sora'] xl:leading-[75px]"
              dangerouslySetInnerHTML={{
                __html: currentBlog[0].title?.rendered,
              }}
            ></p>
            <div className="h-10"></div>
            <div className="flex flex-col sm:flex-row justify-between gap-2.5">
              <div className="flex items-center gap-2.5 text-sm text-[#8C94A6] font-inter font-medium">
                <p>{format(Date.parse(currentBlog[0].date), "MMM dd, yyyy")}</p>
                <div className="h-2 w-2 rounded-full bg-green-150" />
                <p>
                  {
                    currentBlog[0].yoast_head_json.twitter_misc[
                      "Estimated reading time"
                    ]
                  }{" "}
                  read
                </p>
              </div>
              <div className="w-full h-[1px] bg-[#D9D9D9] sm:hidden" />
              <div className="flex items-center gap-5 justify-between sm:justify-start">
                <p className="text-sm text-[#8C94A6] font-inter font-medium">
                  Share this article on
                </p>
                <div className="flex items-center gap-3">
                  <a
                    href={linkedinShareUrl}
                    target="_blank"
                    rel="noreferrer"
                    className=" rounded-full bg-white h-10 w-10 center"
                  >
                    <RiLinkedinBoxFill className=" text-xl text-[#282A2C] md:text-[#8C94A6] hover:text-[#282A2C]" />
                  </a>
                  <a
                    href={twitterShareUrl}
                    target="_blank"
                    rel="noreferrer"
                    className=" rounded-full bg-white h-10 w-10 center"
                  >
                    <RiTwitterFill className=" text-xl text-[#282A2C] md:text-[#8C94A6] hover:text-[#282A2C]" />
                  </a>
                  <span className="relative">
                    <p
                      className=" rounded-full bg-white h-10 w-10 center"
                      onClick={() => {
                        navigator.clipboard.writeText(currentUrl);
                        setToggleTooltip(true);
                        setTimeout(() => {
                          setToggleTooltip(false);
                        }, 1500);
                      }}
                    >
                      <RiLink className=" text-xl text-[#282A2C] md:text-[#8C94A6] hover:text-[#282A2C]" />
                    </p>
                    {toggleTooltip && (
                      <div className="absolute left-1/2 -translate-x-1/2 bottom-full mb-2">
                        <div
                          className={`px-3 ${"py-2"} rounded-lg relative font-inter bg-light-green-500 `}
                        >
                          <p className="text-xs font-medium ">Copied</p>

                          {/* top Center */}
                          <div className="w-3 h-3 bg-light-green-500 rotate-45 absolute rounded-[1px] -bottom-[5px] left-1/2 -translate-x-1/2" />
                        </div>
                      </div>
                    )}
                  </span>
                </div>
              </div>
              <div className="w-full h-[1px] bg-[#D9D9D9] sm:hidden" />
            </div>
            <div className="h-20"></div>
            <div className="overflow-hidden rounded-2xl  relative md:h-[70vh] xl:h-[90vh] max-h-[900px] ">
              <img
                src={currentBlog[0]?.jetpack_featured_media_url}
                alt=""
                className="object-cover w-full h-full md:absolute inset-0"
              />
            </div>
            <div className="h-20"></div>
            <div
              dangerouslySetInnerHTML={{
                __html: currentBlog[0].content?.rendered,
              }}
              className="space-y-4 font-inter max-w-[1000px] xl:text-lg xl:leading-[170%] mx-auto text-sm "
            ></div>
          </section>
        ) : (
          <div className="min-h-screen"></div>
        )}
        <JoinTheBest />
        <Footer />
      </section>
    </>
  );
};

export default BlogIndividualPage;
