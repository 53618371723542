import React from "react";

import { Helmet } from "react-helmet";
import IssuesReporting from "../../assets/svg/ReportIssues.svg";
import Footer from "../../components/FOOTER/Footer";
import JoinTheBest from "../../components/HOMEPAGE/JoinTheBest";

const IssuesReportingPage = () => {
  return (
    <>
      <Helmet>
        <title>
          Updates - Venco | All-in-one Community Management Software
        </title>
        <meta
          property="og:title"
          content="Updates - Venco | All-in-one Community Management Software"
        />
        <meta
          name="twitter:title"
          content="Updates - Venco | All-in-one Community Management Software"
        />
      </Helmet>

      <section className=" w-full bg-white p-1 relative overflow-hidden">
        <div className="container mx-auto px-4 md:px-8 my-10 xl:my-[20px] lg:pb-11 relative z-[1] ">
          <div className="space-y-8 sm:max-w-[300px] md:max-w-full lg:max-w-full lg:p-5  cursor-pointer group">
            <div className="space-y-3 md:space-y-2.5 mt-4">
              <p className="font-sor font-semibold text-new-black-950 capitalize text-2xl">
                Issues reporting is not enabled in your community
              </p>

              <div className="flex  mt-5  md:mt-0">
                <div className="px-2 py-2.5 flex items-center space-x-3  font-sora text-xs text-new-black-400 font-medium">
                  <div className="w-2 h-2 bg-green-200 rounded-full"> </div>
                  <div>2 min read</div>
                </div>
              </div>

              <div className="h-[1px] w-full md:hidden bg-grey-200 my-[50px] "></div>

              <div className="md:min-h-[150px] overflow-hidden w-full rounded-[6.5px] relative">
                <img
                  src={IssuesReporting}
                  alt=""
                  className="object-contain w-full h-auto max-h-[200px] md:max-h-[500px]"
                />
              </div>
            </div>
            <div className="">
              <div className="space-y-1 md:space-y-4 mt-0 md:mt-4">
                <div className="text-sm  space-y-5 leading-[22px] font-inter text-new-grey-500">
                  <p className=" md:text-[16px] text-[14px] font-inter">
                    Do you have an issue you would like to report? Rather than
                    walking to the Community Administration building, you can
                    report any issue you have on your Community Management app
                    with the right details and have your community administrator
                    attend to them directly.
                  </p>
                  <p className=" md:text-[16px] text-[14px] font-inter">
                    Contact your Facility Manager or Join a community to enable
                    Issues reporting for your community today!
                  </p>

                  <p className="font-bold text-[14px] text-black capitalize">
                    How to join a community
                  </p>
                  <ul className="list-disc">
                    <li className="ml-4">Click on the “Join Now” button.</li>
                    <li className="ml-4">
                      Search and select your community by typing the name of
                      your estate and house number.
                    </li>
                    <li className="ml-4">Click the "Join Now" button.</li>
                  </ul>
                  <p className="font-bold text-[14px] capitalize text-black">
                    Benefits of Issues Reporting
                  </p>
                  <ul className="list-disc">
                    <li className="ml-4">
                      You can get a direct response to your issue wherever you
                      are.
                    </li>
                    <li className="ml-4">
                      You can view your history of previous issues.
                    </li>
                    <li className="ml-4">
                      You can edit the status of your issue if it had been
                      closed by the Facility manager.
                    </li>
                  </ul>
                  <p className="font-bold text-[14px] capitalize text-black">
                    How to use the Issue reporting feature
                  </p>
                  <ul className="list-disc">
                    <li className="ml-4"> Click on Report issue</li>
                    <li className="ml-4">
                      Report an issue by selecting the Issue type, subject and
                      brief description of the issue. you can attach photos from
                      gallery or take a picture
                    </li>
                    <li className="ml-4">
                      History - This shows a list of issues reported against the
                      unit and their status (Open, replied, closed).
                    </li>
                  </ul>
                  <p className="font-[500] text-[14px] capitalize text-black">
                    Note: You can change the status of an issue from open to
                    closed, can reopen a closed issue and leave a comment for
                    the Facility manager.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <JoinTheBest />
      <Footer />
    </>
  );
};

export default IssuesReportingPage;
