import React from "react";
// import NavBar from "../components/NAVBAR/NavBar";
import NavBarDark from "../components/NAVBAR/NavBarDark";
import Footer from "../components/FOOTER/Footer";
import JoinTheBest from "../components/HOMEPAGE/JoinTheBest";
import PrivacyHero from "../components/PRIVACY/PrivacyHero";
import PrivacyBody from "../components/PRIVACY/PrivacyBody";
import { Helmet } from "react-helmet";

const PrivacyPage = () => {
  return (
    <>
      <Helmet>
        <title>
          Privacy Policy - Venco | All-in-one Community Management Software
        </title>
        <meta
          property="og:title"
          content="Privacy Policy - Venco | All-in-one Community Management Software"
        />
        <meta
          name="twitter:title"
          content="Privacy Policy - Venco | All-in-one Community Management Software"
        />
      </Helmet>
      <NavBarDark />
      {/* <NavBar /> */}
      <PrivacyHero />
      <PrivacyBody />
      <JoinTheBest />
      <Footer />
    </>
  );
};

export default PrivacyPage;
