import React, { useEffect, useRef, useState } from "react";
// import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { AnimatePresence, motion } from "framer-motion";
import {
  defaultCountries,
  FlagEmoji,
  parseCountry,
} from "react-international-phone";
import { CaretDown, CaretUp } from "phosphor-react";
import { useSelector } from "react-redux";

const CustomPhoneInput = ({
  selectedCountry,
  setSelectedCountry,
  flag = true,
}) => {
  const popupRef = useRef(null);
  const listRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const presentCountry = useSelector((state) => state.geoDetails.country);

  useEffect(() => {
    if (presentCountry !== "Global") {
      const detectedCountry = defaultCountries.find((item) => {
        return item[0] === presentCountry;
      });
      setSelectedCountry(parseCountry(detectedCountry));
    }
  }, [presentCountry, setSelectedCountry]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    const handleEscapeKey = (event) => {
      if (event.keyCode === 27) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  //   Scroll the Elements on Keypress
  useEffect(() => {
    const handleKeyPress = (event) => {
      const { key } = event;
      if (listRef.current && /[a-zA-Z]/.test(key)) {
        const items = listRef.current.getElementsByClassName("countryName");
        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          const text = item.textContent.trim().toUpperCase();

          if (text.startsWith(key.toUpperCase())) {
            listRef.current.scrollTop = item.offsetTop;
            break;
          }
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const handleTogglePopup = () => {
    setIsOpen(!isOpen);
  };

  let allCountries = defaultCountries;
  const sortedCountries = allCountries
    .map((c) => parseCountry(c))
    .sort((a, b) => {
      const priorityCountries = ["ng", "gh", "ke"]; // ISO2 codes of Nigeria, Ghana, and Kenya
      const priorityA = priorityCountries.indexOf(a.iso2);
      const priorityB = priorityCountries.indexOf(b.iso2);

      // If both countries are in the priority list, sort them based on their order in the list
      if (priorityA !== -1 && priorityB !== -1) {
        return priorityA - priorityB;
      }

      // If one country is in the priority list and the other is not, prioritize the one in the list
      if (priorityA !== -1) {
        return -1;
      }

      if (priorityB !== -1) {
        return 1;
      }

      // If neither country is in the priority list, sort them alphabetically
      return a.name.localeCompare(b.name);
    });

  const container = {
    hidden: { opacity: 0 },
    exit: {
      opacity: 0,
      y: -50,
      transition: {
        duration: 0.2,
      },
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.2,
      },
    },
  };

  return (
    <>
      <AnimatePresence>
        <div className="relative" ref={popupRef} key={"container"}>
          <div>
            <div
              className="flex gap-1 items-center cursor-pointer border border-new-grey-100 p-4 rounded-lg text-sm lg:text-base font-inter font-medium"
              onClick={handleTogglePopup}
            >
              {flag && (
                <div className="w-5 h-5 rounded-full overflow-hidden relative">
                  <FlagEmoji
                    iso2={selectedCountry.iso2}
                    style={{
                      width: "30px",
                      height: "30px",
                      position: "absolute",
                      top: -5,
                      translateX: "50%",
                      padding: 0,
                      border: "1px solid red",
                      transform: "scale(1.6)",
                    }}
                  />
                </div>
              )}
              <p className="text-md text-gray-500">
                +{selectedCountry.dialCode}
              </p>
              {isOpen ? (
                <CaretUp className="w-4 text-gray-400 ml-1" />
              ) : (
                <CaretDown className="w-4 text-gray-400 ml-1" />
              )}
            </div>
          </div>
          {isOpen && (
            <motion.div
              ref={listRef}
              variants={container}
              initial="hidden"
              animate="show"
              exit="exit"
              className="absolute top-full left-0 shadow-lg bg-gray-0 rounded-2xl w-[280px] md:w-[350px] h-[250px] overflow-auto p-3  scroll-smooth z-[10] bg-white"
            >
              {sortedCountries.map((country, i) => {
                return (
                  <motion.div
                    className="flex gap-3 items-center p-3 hover:bg-gray-50 rounded-xl"
                    key={i}
                    // variants={listItem}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -20 }}
                    transition={{ duration: 0.25, delay: i * 0.01 }}
                    onClick={() => {
                      setSelectedCountry(country);
                      // setCountryCode(country.iso2);
                      setIsOpen(!isOpen);
                    }}
                  >
                    <div className="w-5 h-5 rounded-full  overflow-hidden relative">
                      <FlagEmoji
                        iso2={country.iso2}
                        style={{
                          width: "30px",
                          height: "30px",
                          position: "absolute",
                          top: -5,
                          translateX: "50%",
                          padding: 0,
                          border: "1px solid red",
                          transform: "scale(1.6)",
                        }}
                      />
                    </div>
                    <p className="text-sm text-gray-500 countryName">
                      {country.name}
                    </p>
                    <p className="text-sm text-gray-500">+{country.dialCode}</p>
                  </motion.div>
                );
              })}
            </motion.div>
          )}
        </div>
        <div className="h-1" />
      </AnimatePresence>
    </>
  );
};

export default CustomPhoneInput;
