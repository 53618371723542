import React from "react";
import story from "../../assets/images/ourStory.webp";
import vision from "../../assets/images/vision.webp";
import solution from "../../assets/images/solution.webp";
const OurStory = () => {
  return (
    <section className="mx-auto container my-[70px]  xl:my-[170px]">
      <div
        className="flex gap-5 xl:gap-[128px] justify-center"
        data-aos="fade-up"
      >
        <div
          className="p-3 gap-3 flex flex-col items-center"
          data-aos="fade-up"
        >
          <div className="bg-blue-100 h-8 w-8 xl:h-[60px] xl:w-[60px] customPing duration-700 rounded-full center ">
            <div className="bg-blue-500 h-4 w-4 rounded-full xl:h-[30px] xl:w-[30px]"></div>
          </div>
          <div className="w-[1px] flex-1 bg-blue-500"></div>
        </div>
        <div className="md:max-w-[500px] lg:max-w-[800px]">
          <div className="pr-4" data-aos="fade-up">
            <p className="font-sora font-semibold text-2xl md:text-3xl lg:text-[4xl] mb-4 xl:text-[64px] my-4 xl:mt-8">
              Our Story
            </p>
            <p className="text-sm font-inter leading-[24px] text-[#020505] xl:text-xl xl:mt-[60px] xl:leading-[36px]">
              Venco is a technology company that provides solutions to enhance
              living experiences in residential and commercial communities in
              Africa, through our all-in-one technology platform that manages
              collections, service charge administration, utility vending,
              visitor access and other services associated with multi-unit
              property developments across Africa.
            </p>
          </div>
          <div
            className="mt-[30px] xl:mt-[60px] rounded-lg overflow-hidden"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <img src={story} alt="" />
          </div>
        </div>
      </div>
      <div className="h-10 lg:h-36"></div>
      <div
        className="flex gap-5 xl:gap-[128px] justify-center"
        data-aos="fade-up"
      >
        <div
          className="p-3 gap-3 flex flex-col items-center"
          data-aos="fade-up"
        >
          <div className="bg-blue-100 h-8 w-8 xl:h-[60px] xl:w-[60px] customPing duration-700 rounded-full center ">
            <div className="bg-blue-500 h-4 w-4 rounded-full xl:h-[30px] xl:w-[30px]"></div>
          </div>
          <div className="w-[1px] flex-1 bg-blue-500"></div>
        </div>
        <div className="md:max-w-[500px] lg:max-w-[800px]">
          <div className="pr-4" data-aos="fade-up">
            <p className="font-sora font-semibold text-2xl md:text-3xl lg:text-[4xl] mb-4 xl:text-[64px] my-4 xl:mt-8">
              Vision & Mission
            </p>
            <p className="text-sm font-inter leading-[24px] text-[#020505] xl:text-xl xl:mt-[60px] xl:leading-[36px]">
              <span className="font-bold">Our Vision:</span> To empower
              communities with innovative technology for seamless living
              experiences.
              <br />
              <br />
              <span className="font-bold">Our Mission:</span> To enhance the
              quality of life in residential and commercial properties through
              comprehensive, user-friendly solutions.
            </p>
          </div>
          <div
            className="mt-[30px] xl:mt-[60px] rounded-lg overflow-hidden"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <img src={vision} alt="" />
          </div>
        </div>
      </div>
      <div className="h-10 lg:h-36"></div>
      <div
        className="flex gap-5 xl:gap-[128px] justify-center"
        data-aos="fade-up"
      >
        <div
          className="p-3 gap-3 flex flex-col items-center"
          data-aos="fade-up"
        >
          <div className="bg-blue-100 h-8 w-8 xl:h-[60px] xl:w-[60px] customPing duration-700 rounded-full center ">
            <div className="bg-blue-500 h-4 w-4 rounded-full xl:h-[30px] xl:w-[30px]"></div>
          </div>
          <div className="w-[1px] flex-1 bg-blue-500"></div>
        </div>
        <div className="md:max-w-[500px] lg:max-w-[800px]">
          <div className="pr-4" data-aos="fade-up">
            <p className="font-sora font-semibold text-2xl md:text-3xl lg:text-[4xl] my-4 xl:mt-8 xl:text-[64px]">
              Our Solution
            </p>
            <p className="text-sm font-inter leading-[24px] text-[#020505] xl:text-xl xl:mt-[60px] xl:leading-[36px]">
              Venco offers a suite of solutions designed to streamline property
              management:
            </p>
            <ul className="text-sm font-inter leading-[24px] text-[#020505] xl:text-xl  xl:leading-[40px] list-disc list-inside pl-4">
              <li>
                <span className="font-bold">Collections Management:</span>{" "}
                Efficiently handle financial transactions.
              </li>
              <li>
                <span className="font-bold">
                  Service Charge Administration:
                </span>{" "}
                Simplify billing and payment processes.
              </li>
              <li>
                <span className="font-bold">Utility Vending:</span> Hassle-free
                utility payments.
              </li>
              <li>
                <span className="font-bold">Visitor Access:</span> Secure and
                convenient access management.
              </li>
              <li>
                <span className="font-bold">Other Services:</span> Tailored
                solutions for unique community needs.
              </li>
            </ul>
          </div>
          <div
            className="mt-[30px] xl:mt-[60px]"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <img src={solution} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurStory;
