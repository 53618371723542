import React from "react";
import { RiBuilding2Line, RiCommunityLine, RiGroupLine } from "react-icons/ri";

const VencoStat = () => {
  return (
    <section className="bg-green-500 py-[70px] xl:py-[170px] font-inter">
      <div className="w-fit mx-auto text-green-950 bg-light-green-500 px-20 py-3 rounded-full font-inter font-medium text-xs lg:text-sm xl:text-base">
        <p>Our Impact</p>
      </div>
      <div className="container mx-auto p-4 text-white">
        <div></div>
        <p className="font-sora font-semibold text-2xl lg:text-3xl xl:text-5xl text-center leading-[36px] lg:leading-[48px] xl:leading-[66px]">
          Our solutions have transformed countless communities, improving
          efficiency, reducing costs, and enhancing the overall living
          experience.
        </p>
        <section
          className="mt-10 lg:mt-[50px]  px-4 font-sora"
          id="statSection"
        >
          <div className="flex justify-center gap-14 md:gap-24 lg:gap-20 xl:gap-32 items-center flex-col md:flex-row">
            <div
              data-aos="fade-up"
              className="
        gap-2.5 lg:gap-6 xl:gap-8 flex items-center
        flex-col justify-center lg:flex-row md:justify-start
        "
            >
              <div className="rounded-full center text-light-green-500 p-2 lg:p-4 xl:p-6">
                <RiBuilding2Line className="text-[50px] lg:[42px] xl:text-[50px] " />
              </div>
              <div className="text-white font-medium">
                <p className="text-3xl lg:text-4xl xl:text-5xl whitespace-nowrap">
                  {" "}
                  <span className="value " akhi="">
                    11
                  </span>{" "}
                  Cities
                </p>
                <div className="h-1.5 lg:h-2 xl:h-2.5" />
                <p className="text-sm lg:text-xl font-inter text-center md:text-left">
                  Across Africa
                </p>
              </div>
            </div>

            <div
              data-aos="fade-up"
              data-aos-delay="50"
              data-aos-easing="ease-out"
              className="
        gap-4 lg:gap-6 xl:gap-8 flex items-center
        flex-col justify-center lg:flex-row md:justify-start
        "
            >
              <div className="rounded-full center text-light-green-500 p-2 lg:p-4 xl:p-6">
                <RiCommunityLine className="text-[50px] lg:[42px] xl:text-[50px] " />
              </div>
              <div className="text-white font-medium">
                <p className="text-3xl lg:text-4xl xl:text-5xl whitespace-nowrap">
                  <span className="value" akhi="">
                    19,800
                  </span>
                  +
                </p>
                <div className="h-1.5 lg:h-2 xl:h-2.5" />
                <p className="text-sm lg:text-xl font-inter text-center md:text-left">
                  Property Units
                </p>
              </div>
            </div>

            <div
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-easing="ease-out"
              className="
        gap-4 lg:gap-6 xl:gap-8 flex items-center
        flex-col justify-center lg:flex-row md:justify-start
        "
            >
              <div className="rounded-full center text-light-green-500 p-2 lg:p-4 xl:p-6">
                <RiGroupLine className="text-[50px] lg:[42px] xl:text-[50px] " />
              </div>
              <div className="text-white font-medium">
                <p className="text-3xl lg:text-4xl xl:text-5xl whitespace-nowrap">
                  <span className="value" akhi="">
                    21,000
                  </span>
                  +
                </p>
                <div className="h-1.5 lg:h-2 xl:h-2.5" />
                <p className="text-sm lg:text-xl font-inter text-center md:text-left">
                  Residents
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default VencoStat;
