
import React from "react";
import hero from "../../assets/images/adminHero.webp";
import SignUpButton from "../BUTTONS/SignUpButton";
import ViewDemoButton2 from "../BUTTONS/ViewDemoButton2";

const AdminHero = () => {
  return (
    <section className=" w-full">
      <div className="container mx-auto px-4">
        <div className="rounded-full bg-blue-100 w-fit mx-auto text-xs py-1.5 px-6 mt-[40px] xl:mt-[150px]">
          <p className="font-medium font-inter text-green-950 lg:text-xl lg:px-8 py-3">
            Venco for Facility Managers
          </p>
        </div>
        <p className="font-sora text-green-500 font-semibold pt-2.5 md:pt-5  text-[26px] leading-[32px] md:text-4xl lg:text-5xl xl:text-6xl text-center">
          Unleash Your Community's Potential
        </p>

        <div className="h-4 lg:h-6"></div>
        <p className=" w-4/5 md:w-2/3 text-center  text-sm leading-[22px] md:text-base lg:text-xl  md:leading-[28px] mx-auto font-inter max-w-[653px] xl:leading-[32px]">
        Empowering estate facility managers with efficient management solutions, fostering growth and development for all.
        </p>

        <div className="h-5 md:h-6 lg:h-10"></div>
        <div className="flex justify-center gap-4 flex-col md:flex-row">
          <SignUpButton />
          <ViewDemoButton2 />
        </div>
        <div className="h-[40px]" />
        <div className="md:w-full md:h-auto rounded-[20px] lg:max-h-[930px] h-[480px] flex justify-center items-center overflow-hidden">
          <img
            src={hero}
            alt=""
            className="h-full max-w-none md:h-auto md:w-full"
          />
        </div>
      </div>
    </section>
  );
};

export default AdminHero;
