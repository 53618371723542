import residentEx1 from "../../assets/howItWorks/residentEx1.webp";
import residentEx2 from "../../assets/howItWorks/residentEx2.webp";
import residentEx3 from "../../assets/howItWorks/residentEx3.webp";
import residentEx4 from "../../assets/howItWorks/residentEx4.webp";
import residentNew4 from "../../assets/howItWorks/residentNew4.webp";
import adminGetStarted from "../../assets/howItWorks/adminGetStarted.webp";
import adminSignUp from "../../assets/howItWorks/adminSignup.webp";
import adminOTP from "../../assets/howItWorks/adminOtp.webp";
import adminEmail from "../../assets/howItWorks/adminLogin.webp";
import adminPhoneNumber from "../../assets/howItWorks/AdminPhoneLogin.webp";

export const admin1 = [
  {
    label: (
      <p>
        Download <span className="font-bold">"Venco Admin App"</span>
      </p>
    ),
    bodyContent: [
      [
        <p>
          Visit your{" "}
          <a
            href="https://play.google.com/store/apps/details?id=co.venco.admin"
            target="_blank"
            rel="noopener noreferrer"
            className="underline underline-offset-[6px] decoration-[1.5px] text-light-green-850"
          >
            App Store 
          </a>
          
          {" "}or <a
            href="https://apps.apple.com/gb/app/venco-admin-estate-app/id1621299523?platform=iphone"
            target="_blank"
            rel="noopener noreferrer"
            className="underline underline-offset-[6px] decoration-[1.5px]  text-light-green-850"
          >
            Play Store 
          </a>{" "}
          to download, and install the Venco Admin app,
        </p>,
      ],
    ],
    image: adminSignUp,
  },
  {
    label: `Get Started`,
    bodyContent: [
      [
        "If you are a new user, click on the “sign-up” button and complete the form with a valid email and phone number.",
      ],
    ],
    image: adminGetStarted,
  },
  {
    label: `Input Phone Number`,
    bodyContent: [
      [
        <p>
          After successfully signing up, click on the{" "}
          <span className="font-bold">"Join Now"</span> option under the{" "}
          <span className="font-bold">
            “Request to join a community” section.
          </span>
        </p>,
      ],
      [
        "Search and select your community by typing the name of your estate and house number.",
      ],
      [
        <p>
          Click the <span className="font-semibold">"Join Now"</span> button.
        </p>,
      ],
    ],
    image: adminPhoneNumber,
  },
  {
    label: `OTP Verification`,
    bodyContent: [
      [<p>An OTP will be sent to your registered phone number.</p>],
      ["Enter the OTP to proceed into the app."],
    ],
    image: adminOTP,
  },
];
export const admin2 = [
  {
    label: (
      <p>
        Download <span className="font-bold">"Venco Admin App"</span>
      </p>
    ),
    bodyContent: [
      [
        <p>
          Visit your{" "}
          <a
            href="https://vnco.to/mobile"
            target="_blank"
            rel="noopener noreferrer"
            className="underline underline-offset-[5px] text-grey-850"
          >
            App store or Play Store
          </a>{" "}
          to download, and install the Venco Admin app,
        </p>,
      ],
    ],
    image: adminSignUp,
  },
  {
    label: `Get Started`,
    bodyContent: [
      [
        "If you are a new user, click on the “sign-up” button and complete the form with a valid email and phone number.",
      ],
    ],
    image: adminGetStarted,
  },
  {
    label: `Enter your Email Address`,
    bodyContent: [
      [
        <p>
          Input your registered email address and click on
          <span className="font-bold">“Submit".</span>
        </p>,
      ],
      [
        "Search and select your community by typing the name of your estate and house number.",
      ],
      [
        <p>
          Click the <span className="font-semibold">"Join Now"</span> button.
        </p>,
      ],
    ],
    image: adminEmail,
  },
  {
    label: `OTP Verification`,
    bodyContent: [
      [<p>An OTP will be sent to your registered phone number.</p>],
      ["Enter the OTP to proceed into the app."],
    ],
    image: adminOTP,
  },
];

export const residentNew = [
  {
    label: <p>Download App</p>,
    bodyContent: [
      [
        <p>
          Visit your{" "}
          <a
            href="https://play.google.com/store/apps/details?id=com.manqala.estatemanager&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            target="_blank"
            rel="noopener noreferrer"
            className="underline underline-offset-[6px] decoration-[1.5px] text-light-green-850"
          >
            App Store 
          </a>
          
          {" "}or <a
            href="https://apps.apple.com/gb/app/manqala-estate-manager/id1542447397#?platform=iphone"
            target="_blank"
            rel="noopener noreferrer"
            className="underline underline-offset-[6px] decoration-[1.5px]  text-light-green-850"
          >
            Play Store 
          </a>{" "}
          to download, and install the Venco Admin app,
        </p>,
      ],
    ],
    image: adminGetStarted,
  },
  {
    label: `Sign up`,
    bodyContent: [
      [
        <p>
          If you are a new user, click on the{" "}
          <span className="font-bold">“sign-up”</span> button and complete the
          form with a valid email and phone number.
        </p>,
      ],
    ],
    image: adminSignUp,
  },
  {
    label: `Join Your Community`,
    bodyContent: [
      [
        <p>
          After successfully signing up, click on the{" "}
          <span className="font-bold">"Join Now"</span> option under the{" "}
          <span className="font-bold">
            “Request to join a community” section.
          </span>
        </p>,
      ],
      [
        "Search and select your community by typing the name of your estate and house number.",
      ],
      [
        <p>
          Click the <span className="font-semibold">"Join Now"</span> button.
        </p>,
      ],
    ],
    image: residentEx4,
  },
  {
    label: `Request Approval`,
    bodyContent: [
      [
        <p>
          Get in touch with your Facility Admin to approve your join request.
        </p>,
      ],
    ],
    image: residentNew4,
  },
];

export const residentExisting = [
  {
    label: <p>Access Profile</p>,
    bodyContent: [
      [
        <p>
          Open the sidebar menu on your Venco app and click on{" "}
          <span className="font-bold">“My Profile & IDs”</span> and select{" "}
          <span className="font-bold">“My Profile.”</span>
        </p>,
      ],
    ],
    image: residentEx1,
  },
  {
    label: `Switch Estate`,
    bodyContent: [
      [
        <p>
          Click on <span className="font-bold">“Switch Estate”</span> and select{" "}
          <span className="font-bold">“Join another Community”.</span>
        </p>,
      ],
    ],
    image: residentEx2,
  },
  {
    label: `Join Now`,
    bodyContent: [
      [
        <p>
          After successfully signing up, click on the{" "}
          <span className="font-bold">"Join Now"</span> option under the{" "}
          <span className="font-bold">
            “Request to join a community” section.
          </span>
        </p>,
      ],
      [
        "Search and select your community by typing the name of your estate and house number.",
      ],
      [
        <p>
          Click the <span className="font-semibold">"Join Now"</span> button.
        </p>,
      ],
    ],
    image: residentEx3,
  },
  {
    label: `Select Your Community`,
    bodyContent: [
      [
        "Search and select your community by typing the name of your estate and house number.",
      ],
      [
        <p>
          Click the <span className="font-semibold">"Join Now"</span> button.
        </p>,
      ],
    ],
    image: residentEx4,
  },
];

export const security = [
  {
    label: `Request to be Added`,
    bodyContent: [
      [
        <p>
          <span className="font-bold">Contact your Estate Administrator: </span>
          Reach out to your estate/community administrator and express your
          interest in accessing the Venco Security App.{" "}
        </p>,
      ],
      [
        <p>
          <span className="font-bold">Administrator Action: </span>
          Ask the administrator to follow these steps in the Admin App:
          <ul className="list-disc ml-6 space-y-2 mt-2">
            <li>Visit community settings.</li>
            <li>Click on 'Security Personnel.</li>
            <li>Select 'Add a New Security.</li>
          </ul>
        </p>,
      ],
    ],
    image: residentEx2,
  },
  {
    label: `Log in to the Venco Security App`,
    bodyContent: [
      [
        <p>
          <span className="font-bold">Access the Security App: </span>
          Reach out to your estate/community administrator and express your
          interest in accessing the Venco Security App.{" "}
          <ul className="list-disc ml-6 space-y-2 mt-2">
            <li>
              Once your information is added by the administrator, download and
              install the Venco Security App from the PlayStore.
            </li>
            <li>Login with Registered Phone Number: </li>
            <li>
              Open the app and log in using the phone number registered by the
              administrator.
            </li>
          </ul>
        </p>,
      ],
      [
        <p>
          <span className="font-bold">Enter OTP:</span>
          Ask the administrator to follow these steps in the Admin App:
          <ul className="list-disc ml-6 space-y-2 mt-2">
            <li>
              You will receive a one-time password (OTP) on the registered phone
              number.
            </li>
            <li>Enter the OTP in the app to verify your identity.</li>
          </ul>
        </p>,
      ],
      [<p>Explore and Use the Venco Security App</p>],
    ],
    image: residentEx2,
  },
];
