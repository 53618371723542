export const faqQuestions = [{
    question: "What does Venco do?",
    answer: <>
        <p><span>Venco is a software platform that enables Facilities Managers, Developers, Estate Owners, and Residents Associations to manage processes in their estates such as </span></p>
        <ul className="list-inside list-disc pl-4">
            <li><span>Issue Management</span></li>
            <li><span>Ownership and Occupancy Details</span></li>
            <li><span>Demand Notice Generation</span></li>
            <li><span>Cash calls for Estate Projects</span></li>
            <li><span>Estate Communications and Notifications</span></li>
            <li><span>Online Payments for Services</span></li>
            <li><span>Payment Reconciliation </span></li>
            <li><span>Inventory Management for Fuel and Spares </span></li>
            <li><span>Visitor Management </span></li>
            <li><span>Service Provision Controls</span></li>
            <li> <span>Financial Reporting and so much more</span>
            </li>
        </ul>
    </>
},
{
    question: "Which payment gateways does Venco support?",
    answer: <p>Venco supports all major payment gateways in the countries where we operate. Do you want your payment gateway to be supported by Venco? Please get in touch </p>
},
{
    question: "Is the Venco system secure?",
    answer: <p>Venco is secure. It is hosted on a secure server, uses SSL to protect communications, and employs two-factor authentication to ensure that only authorized personnel has access to the software.</p>
},
{
    question: "Can I pay for demand notices online?",
    answer: <p>Yes. Venco can be used to electronically collect payments on behalf of estates and commercial properties.</p>
},
{
    question: "Can Venco be used to collect payments on behalf of estates?",
    answer: <p>Final beneficiaries such as Developers, Facilities Managers and Resident Associations can open accounts directly with payment gateways so that any payments collected go directly to their accounts. Alternatively, Venco can collect into its own wallet and disburse directly to beneficiaries.</p>
},
{
    question: "How does money collected by Venco get to the final beneficiaries?",
    answer: <p>Final beneficiaries such as Developers, Facilities Managers, and Resident Associations can open accounts directly with payment gateways so that any payments collected go directly to their accounts. Alternatively, Venco can collect into its own wallet and disburse directly to beneficiaries.</p>
},
{
    question: "Can Venco help with the monitoring of diesel consumption?",
    answer: <p>Venco provides tools that allow for the daily measurement and recording of fuel levels so that fuel consumption trends can be monitored. These tools allow measurements to be monitored by different individuals so that measurement figures can be cross-referenced against more than one source. These measurements can be taken in cheap mobile phones and collated on the Venco server.</p>
},
{
    question: "Can Venco help estates manage their finances and purchases?",
    answer: <p>Yes. Venco incorporates a full accounting and purchasing system that allows the booking and monitoring of accounting and purchasing transactions.</p>
},
{
    question: "Are your meters prepaid and STS compliant?",
    answer: <p>Yes, all Venco meters are prepaid meters and are STS compliant which is the industry standard for meters.</p>
},
{
    question: "What does Venco cost?",
    answer: <p>Please get in touch with us by heading on to the Contact Us page and complete the presented form for a quote.</p>
},
{
    question: "Can Venco vend tokens for utilities such as electricity and water?",
    answer: <p>Yes. Venco integrates with any STS-compliant vending server and is able to use this to generate and deliver tokens to estate residents and commercial property tenants.</p>
},
{
    question: "When I make a payment does Venco send receipts?",
    answer: <p>Yes. Once a payment is received into the system, either based on an online payment or through the processing of bank statement, Venco clears the receivable and generates a receipt that is sent to the payer.</p>
},
{
    question: "Does Venco have a visitor management system?",
    answer: <p>Yes. Our visitor management system allows residents to register guests using messaging clients like Telegram or through a mobile browser. The guests then receive SMS messages with pass codes that are verified at the gate before they are allowed entry. This cuts short the waiting time of visitors at estate gates.</p>
},
{
    question: "Can the appearance of documents sent out by Venco be customised?",
    answer: <p>Yes, our templates for notifications such as demand notices, payment reminders, receipts, newsletters and other communications are fully customizable with custom layouts, logos and colours.</p>
},
{
    question: "Can Venco distinguish between residents and property owners when generating bills?",
    answer: <p>Yes, it can. In some cases, different natures of estate expenses are to be borne by different players on an estate. For example, asset replacement costs (generator replacement, external repainting, etc) are borne by property owners. In contrast, day-to-day service charges are paid by residents (some of who may be tenants). Venco allows such bills to be separately sent to the respective actors.</p>
},
{
    question: "Can I create and generate reports on Venco?",
    answer: <p>Yes. Venco allows for the creation and generation of configurable estate reports that allows facilities management, residents associations and developers to identify trends on their estate and use these to make informed decisions</p>
},
{
    question: "Does Venco allow issues to be logged and tracked?",
    answer: <p>Yes, Venco provides robust but simple-to-use issue logging and management capabilities.</p>
},
{
    question: "Do the meters alert a user if it's on low credit?",
    answer: <p>There is a low credit alarm that beeps when the credit is less than 100units.</p>
},
]