import React from "react";
import hand from "../../assets/images/hand.webp";
import { useNavigate } from "react-router-dom";
const AllInOne = () => {
  const navigate = useNavigate();
  return (
    <section className="container mx-auto my-10 xl:my-28">
      <div
        className="w-full rounded-[20px] flex bg-gray-50   overflow-hidden
      flex-col md:flex-row p-4 md:px-10 gap-10 md:p-0 xl:p-0
      "
      >
        <div className=" md:w-1/2 lg:w-1/2 md:pt-10 lg:pt-0 xl:py-10 xl:pl-24 xl:pr-10 lg:flex lg:flex-col lg:justify-center">
          <p className="font-semibold text-new-black-950 text-[24px] md:text-2xl xl:text-4xl leading-normal font-sora xl:leading-[125%] text-center md:text-left">
            All-in-one Community Management Solution
          </p>
          <div className="h-4 md:h-[30px]"></div>
          <p className="leading-normal font-inter text-sm lg:text-[20px] xl:max-w-[565px] text-center md:text-left">
            Venco is an all-in-one community management solution for multi-unit
            residential and commercial communities. We provide best-in-class
            software for property managers, owners, and tenants in all types of
            communities.
          </p>
          <div className="h-[30px]"></div>
          <button
            className="py-4 px-10 text-green-500 bg-light-green-500 hover:bg-light-green-600 rounded-lg font-sora font-semibold duration-300 transition-all w-full md:w-fit text-sm lg:text-base"
            onClick={() => {
              navigate("/get-started");
            }}
          >
            Get Started
          </button>
        </div>
        <div className="bg-light-purple-150 w-full rounded-lg relative overflow-hidden  md:w-1/2 lg:w-1/2 xl:w-[714px] flex">
          <img src={hand} alt="" className="" />
        </div>
      </div>
    </section>
  );
};

export default AllInOne;
