import React from "react";
import Footer from "../components/FOOTER/Footer";
import AboutHero from "../components/ABOUT/AboutHero";
import OurStory from "../components/ABOUT/OurStory";
import JoinTheBest from "../components/HOMEPAGE/JoinTheBest";
import FAQ from "../components/FAQ/FAQ";

import Testimonial from "../components/HOMEPAGE/Testimonial";
import VencoStat from "../components/ABOUT/VencoStat";
// import Contact from "../components/ABOUT/Contact";
// import NavBar from "../components/NAVBAR/NavBar";
import NavBarDark from "../components/NAVBAR/NavBarDark";
import { Helmet } from "react-helmet";

const ABOUTPage = () => {
  return (
    <>
    <Helmet>
                <title>About - Venco | All-in-one Community Management Software</title>    
                <meta property="og:title" content="About - Venco | All-in-one Community Management Software" />
                <meta name="twitter:title" content="About - Venco | All-in-one Community Management Software" />
            </Helmet>
      <NavBarDark />
      {/* <NavBar /> */}
      <AboutHero />
      <OurStory />
      <VencoStat />
      <Testimonial />
      {/* <Contact /> */}
      <FAQ />
      <JoinTheBest />
      <Footer />
    </>
  );
};

export default ABOUTPage;
