import React from "react";

import AdminHero from "../components/ADMIN/AdminHero";
import Stats from "../components/HOMEPAGE/Stats";
import AdminFeatures from "../components/ADMIN/AdminFeatures";
import Testimonial from "../components/HOMEPAGE/Testimonial";
import FAQ from "../components/FAQ/FAQ";
import JoinTheBest from "../components/HOMEPAGE/JoinTheBest";
import Footer from "../components/FOOTER/Footer";
// import NavBar from "../components/NAVBAR/NavBar";
import NavBarDark from "../components/NAVBAR/NavBarDark";
import { Helmet } from "react-helmet";
import HowCTAComp from "../components/HowItWorks/HowCTAComp";

const ADMIN = () => {
  return (
    <>
      <Helmet>
        <title>FMs - Venco | All-in-one Community Management Software</title>
        <meta
          property="og:title"
          content="FMs - Venco | All-in-one Community Management Software"
        />
        <meta
          name="twitter:title"
          content="FMs - Venco | All-in-one Community Management Software"
        />
      </Helmet>
      <NavBarDark />
      {/* <NavBar /> */}
      <AdminHero />
      <Stats />
      <AdminFeatures />
      <HowCTAComp
        title={
          "Venco provides powerful tools to simplify community management for administrators and property managers."
        }
        link={"/how-it-works-for-admins"}
      />
      <Testimonial />
      <FAQ />
      <JoinTheBest />
      <Footer />
    </>
  );
};

export default ADMIN;
