import React, { useEffect, useState } from "react";
import logo from "../../assets/images/footerLogo.svg";
import { CaretDown, CaretUp, List, X } from "phosphor-react";
import { Link, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { setCountry } from "../../app/features/countrySlice";

const countries = [
  {
    flag: (
      <svg
        className={`fill-white group-hover:fill-white`}
        xmlns="http://www.w3.org/2000/svg"
        width={19}
        height={18}
        fill="none"
      >
        <path
          fillRule="evenodd"
          d="M9.273 18a9 9 0 0 0 9-9 9 9 0 1 0-18 0 9 9 0 0 0 9 9Zm7.364-8.86a8.11 8.11 0 0 0 0-.28v.28Zm-.044.678a7.37 7.37 0 0 1-4.599 6.027c.798-1.625 1.264-3.742 1.354-6.027h3.245Zm-7.319 6.546c.159 0 .316-.005.472-.015 1.124-1.33 1.845-3.77 1.964-6.531H6.836c.12 2.76.84 5.2 1.964 6.53.157.01.315.016.474.016Zm-2.722-.52a7.37 7.37 0 0 1-4.596-6.026h3.242c.09 2.284.556 4.401 1.354 6.026Zm10.04-7.662a7.37 7.37 0 0 0-4.597-6.027c.797 1.625 1.264 3.742 1.353 6.027h3.245Zm-4.882 0c-.12-2.76-.84-5.2-1.963-6.53a7.476 7.476 0 0 0-.947 0c-1.124 1.33-1.844 3.77-1.964 6.53h4.874Zm-6.512 0c.09-2.284.556-4.401 1.353-6.026a7.37 7.37 0 0 0-4.595 6.026h3.242Z"
          clipRule="evenodd"
        />
      </svg>
    ),
    label: "Global",
  },
  // {
  //   flag: (
  //     <svg
  //       className="h-4"
  //       xmlns="http://www.w3.org/2000/svg"
  //       viewBox="0 0 28 20"
  //       fill="none"
  //     >
  //       <g clipPath="url(#a)">
  //         <rect width={28} height={20} fill="#fff" rx={2} />
  //         <mask
  //           id="b"
  //           width={28}
  //           height={20}
  //           x={0}
  //           y={0}
  //           maskUnits="userSpaceOnUse"
  //           style={{
  //             maskType: "luminance",
  //           }}
  //         >
  //           <rect width={28} height={20} fill="#fff" rx={2} />
  //         </mask>
  //         <g mask="url(#b)">
  //           <path
  //             fill="#D02F44"
  //             fillRule="evenodd"
  //             d="M28 0H0v1.333h28V0Zm0 2.667H0V4h28V2.667ZM0 5.333h28v1.334H0V5.333ZM28 8H0v1.333h28V8ZM0 10.667h28V12H0v-1.333Zm28 2.666H0v1.334h28v-1.334ZM0 16h28v1.333H0V16Zm28 2.667H0V20h28v-1.333Z"
  //             clipRule="evenodd"
  //           />
  //           <path fill="#46467F" d="M0 0h12v9.333H0z" />
  //           <g filter="url(#c)">
  //             <path
  //               fill="url(#d)"
  //               fillRule="evenodd"
  //               d="M2.667 2a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0Zm2.667 0a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0Zm2 .667a.667.667 0 1 0 0-1.334.667.667 0 0 0 0 1.334ZM10.667 2a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0ZM3.334 4a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333Zm3.333-.667a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0Zm2 .667a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333Zm2 .667a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0Zm-3.333.667a.667.667 0 1 0 0-1.334.667.667 0 0 0 0 1.333Zm-2-.667a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0Zm-3.333.667A.667.667 0 1 0 2 4a.667.667 0 0 0 0 1.333ZM4 6a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0Zm2 .667a.667.667 0 1 0 0-1.334.667.667 0 0 0 0 1.334ZM9.334 6a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0Zm.667 2a.667.667 0 1 0 0-1.333A.667.667 0 0 0 10 8Zm-2-.667a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0ZM4.667 8a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333Zm-2-.667a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0Z"
  //               clipRule="evenodd"
  //             />
  //           </g>
  //         </g>
  //       </g>
  //       <defs>
  //         <linearGradient
  //           id="d"
  //           x1={1.334}
  //           x2={1.334}
  //           y1={1.333}
  //           y2={8}
  //           gradientUnits="userSpaceOnUse"
  //         >
  //           <stop stopColor="#fff" />
  //           <stop offset={1} stopColor="#F0F0F0" />
  //         </linearGradient>
  //         <clipPath id="a">
  //           <rect width={28} height={20} fill="#fff" rx={4} />
  //         </clipPath>
  //         <filter
  //           id="c"
  //           width={9.334}
  //           height={7.667}
  //           x={1.334}
  //           y={1.333}
  //           colorInterpolationFilters="sRGB"
  //           filterUnits="userSpaceOnUse"
  //         >
  //           <feFlood floodOpacity={0} result="BackgroundImageFix" />
  //           <feColorMatrix
  //             in="SourceAlpha"
  //             result="hardAlpha"
  //             values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
  //           />
  //           <feOffset dy={1} />
  //           <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
  //           <feBlend
  //             in2="BackgroundImageFix"
  //             result="effect1_dropShadow_1120_1304"
  //           />
  //           <feBlend
  //             in="SourceGraphic"
  //             in2="effect1_dropShadow_1120_1304"
  //             result="shape"
  //           />
  //         </filter>
  //       </defs>
  //     </svg>
  //   ),
  //   label: "United States",
  // },
  {
    flag: (
      <svg
        className="h-4"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 28 20"
        fill="none"
      >
        <g clipPath="url(#a)">
          <rect
            width={27.5}
            height={19.5}
            x={0.25}
            y={0.25}
            fill="#fff"
            stroke="#F5F5F5"
            strokeWidth={0.5}
            rx={1.75}
          />
          <mask
            id="b"
            width={28}
            height={20}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: "luminance",
            }}
          >
            <rect
              width={27.5}
              height={19.5}
              x={0.25}
              y={0.25}
              fill="#fff"
              stroke="#fff"
              strokeWidth={0.5}
              rx={1.75}
            />
          </mask>
          <g fill="#189B62" mask="url(#b)">
            <path d="M18.666 0h9.333v20h-9.333z" />
            <path
              fillRule="evenodd"
              d="M0 20h9.333V0H0v20Z"
              clipRule="evenodd"
            />
          </g>
        </g>
        <defs>
          <clipPath id="a">
            <rect width={28} height={20} fill="#fff" rx={4} />
          </clipPath>
        </defs>
      </svg>
    ),
    label: "Nigeria",
  },
  {
    flag: (
      <svg
        className="h-4"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 28 20"
        fill="none"
      >
        <g clipPath="url(#a)">
          <rect width={28} height={20} fill="#fff" rx={2} />
          <mask
            id="b"
            width={28}
            height={20}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: "luminance",
            }}
          >
            <rect width={28} height={20} fill="#fff" rx={2} />
          </mask>
          <g fillRule="evenodd" clipRule="evenodd" mask="url(#b)">
            <path fill="#E71F37" d="M0 6.667h28V0H0v6.667Z" />
            <path fill="#118B56" d="M0 20h28v-6.666H0V20Z" />
            <path fill="#FDD64C" d="M0 13.333h28V6.666H0v6.667Z" />
            <path
              fill="#262626"
              d="m14 11.612-1.96 1.418.744-2.302-1.955-1.425 2.419-.004L13.999 7l.752 2.3 2.418.003-1.954 1.425.744 2.302-1.96-1.418Z"
            />
          </g>
        </g>
        <defs>
          <clipPath id="a">
            <rect width={28} height={20} fill="#fff" rx={4} />
          </clipPath>
        </defs>
      </svg>
    ),
    label: "Ghana",
  },
  {
    flag: (
      <svg
        width="28"
        height="20"
        viewBox="0 0 28 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="28" height="20" rx="2" fill="white" />
        <mask
          id="mask0_2576_840"
          style={{
            maskType: "luminance",
          }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="28"
          height="20"
        >
          <rect width="28" height="20" rx="2" fill="white" />
        </mask>
        <g mask="url(#mask0_2576_840)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 5.33333H28V0H0V5.33333Z"
            fill="#262626"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 20H28V14.6666H0V20Z"
            fill="#018301"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 14.6667H28V5.33337H0V14.6667Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 13.3333H28V6.66663H0V13.3333Z"
            fill="#DC0808"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.0013 16.6667C15.3346 16.6667 17.3346 14.0501 17.3346 10C17.3346 5.94995 15.3346 3.33337 14.0013 3.33337C12.668 3.33337 10.668 5.94995 10.668 10C10.668 14.0501 12.668 16.6667 14.0013 16.6667Z"
            fill="#BC0000"
          />
          <mask
            id="mask1_2576_840"
            style={{
              maskType: "luminance",
            }}
            maskUnits="userSpaceOnUse"
            x="10"
            y="3"
            width="8"
            height="14"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.0013 16.6667C15.3346 16.6667 17.3346 14.0501 17.3346 10C17.3346 5.94995 15.3346 3.33337 14.0013 3.33337C12.668 3.33337 10.668 5.94995 10.668 10C10.668 14.0501 12.668 16.6667 14.0013 16.6667Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1_2576_840)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.66927 17.3334C10.5102 17.3334 12.0026 14.0502 12.0026 10.0001C12.0026 5.94999 10.5102 2.66675 8.66927 2.66675C6.82832 2.66675 5.33594 5.94999 5.33594 10.0001C5.33594 14.0502 6.82832 17.3334 8.66927 17.3334Z"
              fill="#262626"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19.3333 17.3334C21.1743 17.3334 22.6667 14.0502 22.6667 10.0001C22.6667 5.94999 21.1743 2.66675 19.3333 2.66675C17.4924 2.66675 16 5.94999 16 10.0001C16 14.0502 17.4924 17.3334 19.3333 17.3334Z"
              fill="#262626"
            />
            <g filter="url(#filter0_d_2576_840)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.0026 8.00008C14.3708 8.00008 14.6693 6.80617 14.6693 5.33341C14.6693 3.86066 14.3708 2.66675 14.0026 2.66675C13.6344 2.66675 13.3359 3.86066 13.3359 5.33341C13.3359 6.80617 13.6344 8.00008 14.0026 8.00008ZM14.0026 12.0001C14.3708 12.0001 14.6693 11.1047 14.6693 10.0001C14.6693 8.89551 14.3708 8.00008 14.0026 8.00008C13.6344 8.00008 13.3359 8.89551 13.3359 10.0001C13.3359 11.1047 13.6344 12.0001 14.0026 12.0001ZM14.0026 12.0001C13.6344 12.0001 13.3359 13.194 13.3359 14.6667C13.3359 16.1395 13.6344 17.3334 14.0026 17.3334C14.3708 17.3334 14.6693 16.1395 14.6693 14.6667C14.6693 13.194 14.3708 12.0001 14.0026 12.0001Z"
                fill="url(#paint0_linear_2576_840)"
              />
            </g>
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_2576_840"
            x="13.3359"
            y="2.66675"
            width="1.33203"
            height="15.6666"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2576_840"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2576_840"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_2576_840"
            x1="13.3359"
            y1="2.66675"
            x2="13.3359"
            y2="17.3334"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" />
            <stop offset="1" stop-color="#F0F0F0" />
          </linearGradient>
        </defs>
      </svg>
    ),
    label: "Kenya",
  },
];

const SelectCountry = ({
  currentCountry,
  setCurrentCountry,
  setCountryMenu,
  setCountryMenuMob,
}) => {
  const dispatch = useDispatch();
  return (
    <div className="bg-grey-50 border border-grey-150 p-3 space-y-4 rounded-[6px] w-fit">
      <p className="uppercase text-xs text-green-850 font-medium">
        Select Region
      </p>
      <div
        className={` cursor-pointer flex items-center gap-2.5 p-2.5 hover:bg-light-green-500 group rounded-md w-fit ${
          currentCountry === "Global" ? "bg-light-green-500" : ""
        }`}
        onClick={() => {
          setCurrentCountry("Global");
          dispatch(setCountry("Global"));
          setCountryMenu(false);
          setCountryMenuMob(false);
        }}
      >
        <div className="rounded-[4px] overflow-hidden">
          <svg
            className={`fill-[#11302D] group-hover:fill-[#11302D] ${
              currentCountry === "Global" ? "fill-[#11302D]" : ""
            }`}
            xmlns="http://www.w3.org/2000/svg"
            width={19}
            height={18}
            fill="none"
          >
            <path
              fillRule="evenodd"
              d="M9.273 18a9 9 0 0 0 9-9 9 9 0 1 0-18 0 9 9 0 0 0 9 9Zm7.364-8.86a8.11 8.11 0 0 0 0-.28v.28Zm-.044.678a7.37 7.37 0 0 1-4.599 6.027c.798-1.625 1.264-3.742 1.354-6.027h3.245Zm-7.319 6.546c.159 0 .316-.005.472-.015 1.124-1.33 1.845-3.77 1.964-6.531H6.836c.12 2.76.84 5.2 1.964 6.53.157.01.315.016.474.016Zm-2.722-.52a7.37 7.37 0 0 1-4.596-6.026h3.242c.09 2.284.556 4.401 1.354 6.026Zm10.04-7.662a7.37 7.37 0 0 0-4.597-6.027c.797 1.625 1.264 3.742 1.353 6.027h3.245Zm-4.882 0c-.12-2.76-.84-5.2-1.963-6.53a7.476 7.476 0 0 0-.947 0c-1.124 1.33-1.844 3.77-1.964 6.53h4.874Zm-6.512 0c.09-2.284.556-4.401 1.353-6.026a7.37 7.37 0 0 0-4.595 6.026h3.242Z"
              clipRule="evenodd"
            />
          </svg>
        </div>

        <p
          className={`font-sora  group-hover:text-new-grey-600 ${
            currentCountry === "Global"
              ? "text-new-grey-600"
              : "text-new-grey-600"
          }`}
        >
          Global
        </p>
      </div>
      {countries.slice(1).map((item, index) => {
        return (
          <div
            className={`cursor-pointer flex items-center gap-2.5 p-2.5 hover:bg-light-green-500 group rounded-md w-fit ${
              currentCountry === item.label ? "bg-light-green-500" : ""
            }`}
            onClick={() => {
              setCurrentCountry(item.label);
              dispatch(setCountry(item.label));
              setCountryMenu(false);
              setCountryMenuMob(false);
            }}
          >
            <div className="rounded-[4px] overflow-hidden">{item.flag}</div>
            <p
              className={`font-sora  group-hover:text-new-grey-600 ${
                currentCountry === item.label
                  ? "text-new-grey-600"
                  : "text-new-grey-600"
              } whitespace-nowrap`}
            >
              {item.label}
            </p>
          </div>
        );
      })}
    </div>
  );
};

const GetStarted = () => {
  return (
    <Link
      to="/get-started"
      className="py-[14px] px-8 text-green-500 bg-light-green-500 hover:bg-light-green-600 rounded-lg font-semibold duration-300 transition-all hidden lg:flex self-center lg:text-base "
    >
      Get Started
    </Link>
  );
};

const NavBarDark = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [productsMenu, setProductsMenu] = useState(false);
  const [productsMenuWeb, setProductsMenuWeb] = useState(false);
  const presentCountry = useSelector((state) => state.geoDetails.country);

  const [currentCountry, setCurrentCountry] = useState("Global");
  useEffect(() => {
    if (presentCountry) {
      setCurrentCountry(presentCountry);
    }
  }, [presentCountry]);

  const [countryMenu, setCountryMenu] = useState(false);
  const [countryMenuMob, setCountryMenuMob] = useState(false);

  const location = useLocation();
  const route = location.pathname.split("/")[1];
  const dropRoute = location.pathname.split("/");

  return (
    <>
      <nav className="w-full border-b border-[#4B4B4B33] sticky -top-1 z-[999] backdrop-blur-md bg-green-500 bg-opacity-100">
        <div className="container mx-auto h-[10vh] px-4 md:px-10 lg:px-4 flex justify-between items-center">
          <a
            className=" w-[100px] sm:w-[120px] md:w-[150px] lg:w-[120px] xl:w-[150px] hover:scale-105 transition-all duration-300"
            href="/"
          >
            <img src={logo} alt="logox" />
          </a>

          <div className="flex items-center gap-5">
            <div className=" items-center hidden lg:flex ">
              <div
                className="relative"
                onMouseEnter={() => setProductsMenuWeb(true)}
                onMouseLeave={() => {
                  setProductsMenuWeb(false);
                }}
              >
                <div
                  className={`menuDark cursor-pointer ${
                    route === "products" ? "text-light-green-500" : "text-white"
                  }`}
                  onClick={() => {
                    setProductsMenuWeb(!productsMenuWeb);
                  }}
                >
                  <p className="menuDark p-0">Products</p>
                  {!productsMenuWeb ? (
                    <CaretDown weight="bold" className="h-6 text-xl" />
                  ) : (
                    <CaretUp weight="bold" className="h-6 text-xl" />
                  )}
                </div>
                <AnimatePresence initial={false}>
                  {productsMenuWeb && (
                    <motion.div
                      key="content2"
                      initial="collapsed"
                      animate="open"
                      exit="collapsed"
                      variants={{
                        open: { opacity: 1 },
                        collapsed: { opacity: 0 },
                      }}
                      transition={{
                        duration: 0.8,
                        ease: [0.04, 0.62, 0.23, 0.98],
                      }}
                      className={`space-y-3  overflow-hidden absolute text-green-500 w-fit p-3 rounded-md bg-grey-50 border border-grey-150`}
                    >
                      <Link
                        to="/residents"
                        className={`dropDownMenu ${
                          dropRoute[2] === "residents"
                            ? "bg-light-green-500"
                            : ""
                        }`}
                        onClick={() => {
                          setProductsMenuWeb(false);
                        }}
                      >
                        Venco for Residents
                      </Link>
                      <Link
                        to="/admin"
                        className={`dropDownMenu ${
                          dropRoute[2] === "admin" ? "bg-light-green-500" : ""
                        }`}
                        onClick={() => {
                          setProductsMenuWeb(false);
                        }}
                      >
                        Venco for Facility Managers
                      </Link>
                      <Link
                        to="/security"
                        onClick={() => {
                          setProductsMenuWeb(false);
                        }}
                        className={`dropDownMenu ${
                          dropRoute[2] === "security"
                            ? "bg-light-green-500"
                            : ""
                        }`}
                      >
                        Venco for Security
                      </Link>
                      <Link
                        to="/meters"
                        onClick={() => {
                          setProductsMenuWeb(false);
                        }}
                        className={`dropDownMenu ${
                          dropRoute[2] === "meters" ? "bg-light-green-500" : ""
                        }`}
                      >
                        Smart Meters
                      </Link>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
              <Link
                to="/blog"
                className={`menuDark ${
                  route === "blog" ? "text-light-green-500" : "text-white"
                }`}
              >
                Blog{" "}
              </Link>
              <Link
                to="/contact-us"
                className={`menuDark ${
                  route === "contact-us" ? "text-light-green-500" : "text-white"
                }`}
              >
                Contact Us
              </Link>
              {/* <Link
                to="#"
                className="menuDark"
              >
                Careers
              </Link> */}
              <Link
                to="/about-us"
                className={`menuDark ${
                  route === "about-us" ? "text-light-green-500" : "text-white"
                }`}
              >
                About Us
              </Link>
              <Link
                to="/referrer"
                className={`menuDark ${
                  route === "referrer" ? "text-light-green-500" : "text-white"
                }`}
              >
                Referral Program
              </Link>
            </div>

            <div
              className="relative hidden lg:flex"
              onMouseEnter={() => setCountryMenu(true)}
              onMouseLeave={() => {
                setCountryMenu(false);
              }}
            >
              <div
                className="flex border-green-400 border rounded-lg p-2.5 gap-1.5 items-center cursor-pointer w-[65px] justify-center text-white hover:text-light-green-500"
                onClick={() => {
                  setCountryMenu(!countryMenu);
                }}
              >
                {
                  countries.find((country) => country.label === currentCountry)
                    .flag
                }
                {!countryMenu ? (
                  <CaretDown className="h-5" />
                ) : (
                  <CaretUp className="h-5" />
                )}
              </div>
              <AnimatePresence initial={false}>
                {countryMenu && (
                  <motion.div
                    key="content2"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: { opacity: 1, height: "auto" },
                      collapsed: { opacity: 0, height: 0 },
                    }}
                    transition={{
                      duration: 0.8,
                      ease: [0.04, 0.62, 0.23, 0.98],
                    }}
                    onMouseEnter={() => setCountryMenu(true)}
                    className="absolute top-[calc(100%)]  left-0 h-fit "
                  >
                    <SelectCountry
                      currentCountry={currentCountry}
                      setCurrentCountry={setCurrentCountry}
                      setCountryMenu={setCountryMenu}
                      setCountryMenuMob={setCountryMenuMob}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
            <GetStarted />
          </div>

          <div
            className="text-white text-[26px] cursor-pointer lg:hidden"
            onClick={() => {
              setToggleMenu(!toggleMenu);
            }}
          >
            {toggleMenu ? (
              <X className="transition-all duration-300" />
            ) : (
              <List className="transition-all duration-300" />
            )}
          </div>
        </div>
      </nav>
      {toggleMenu && (
        <div className="fixed top-[10vh] bg-white rounded-b-[20px] w-[calc(100%-20px)]  left-1/2 -translate-x-1/2 border text-new-grey-500 lg:hidden z-[100] -mt-[3px]">
          <div className="space-y-5 px-4 pt-4">
            <div
              className="flex  items-center justify-between"
              onClick={() => {
                setProductsMenu(!productsMenu);
              }}
            >
              <p
                className={`font-inter font-medium block  ${
                  route === "products" ? "text-green-400" : ""
                }`}
              >
                Products
              </p>
              {!productsMenu ? (
                <CaretDown className="h-5" />
              ) : (
                <CaretUp className="h-5" />
              )}
            </div>
            <AnimatePresence initial={false}>
              {productsMenu && (
                <motion.div
                  key="content2"
                  initial="collapsed"
                  animate="open"
                  exit="collapsed"
                  variants={{
                    open: { opacity: 1, height: "auto" },
                    collapsed: { opacity: 0, height: 0 },
                  }}
                  transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                  className={`space-y-5  ${
                    productsMenu ? "h-auto" : "h-0"
                  } overflow-hidden`}
                >
                  <div className="h-[1px] border-dashed border-opacity-20 border-b border-1 w-full border-[#4B4B4B]" />
                  <Link
                    to="/residents"
                    className={`font-inter font-medium px-5 block ${
                      dropRoute[2] === "residents" ? "text-green-400" : ""
                    }`}
                  >
                    Venco for Residents
                  </Link>
                  <Link
                    to="/admin"
                    className={`font-inter font-medium px-5 block ${
                      dropRoute[2] === "admin" ? "text-green-400" : ""
                    }`}
                  >
                    Venco for Facility Managers
                  </Link>
                  <Link
                    to="/security"
                    className={`font-inter font-medium px-5 block ${
                      dropRoute[2] === "security" ? "text-green-400" : ""
                    }`}
                  >
                    Venco for Security
                  </Link>
                  <Link
                    to="/meters"
                    className={`font-inter font-medium px-5 block ${
                      dropRoute[2] === "meters" ? "text-green-400" : ""
                    }`}
                  >
                    Smart Meters
                  </Link>
                </motion.div>
              )}
            </AnimatePresence>

            <Link
              to="/blog"
              className={`font-inter font-medium block  ${
                route === "blog" ? "text-green-400" : ""
              }`}
            >
              Blog
            </Link>

            <Link
              to="/contact-us"
              className={`font-inter font-medium block  ${
                route === "contact-us" ? "text-green-400" : ""
              }`}
            >
              Contact Us
            </Link>
            {/* <Link to="/#" className="font-inter font-medium block">
              Careers
            </Link> */}
            <Link
              to="/about-us"
              className={`font-inter font-medium block  ${
                route === "about-us" ? "text-green-400" : ""
              }`}
            >
              About us
            </Link>
            <Link
                to="/referrer"
                className={`font-inter font-medium block ${
                  route === "referrer" ? "text-green-400" : ""
                }`}
              >
                Referral Program
              </Link>
          </div>
          <div className="bg-[#4B4B4B] bg-opacity-20 w-full h-[1px] my-5" />

          <div className="relative">
            <div
              className="bg-grey-50 px-2.5 py-4 m-4 rounded-lg flex justify-between items-center"
              onClick={() => {
                setProductsMenu(false);
                setCountryMenuMob(!countryMenuMob);
              }}
            >
              <div className="flex items-center gap-2.5">
                <div className="rounded-[4px] overflow-hidden">
                  {
                    countries.find(
                      (country) => country.label === currentCountry
                    ).flag
                  }
                </div>
                <p className="font-sora text-new-grey-600">
                  {
                    countries.find(
                      (country) => country.label === currentCountry
                    ).label
                  }
                </p>
              </div>
              {!countryMenuMob ? (
                <CaretDown className="h-5" />
              ) : (
                <CaretUp className="h-5" />
              )}
            </div>
            {countryMenuMob && (
              <div
                className="absolute top-[calc(100%)] left-4"
                onClick={() => {
                  setToggleMenu(false);
                }}
              >
                <SelectCountry
                  currentCountry={currentCountry}
                  setCurrentCountry={setCurrentCountry}
                  setCountryMenu={setCountryMenu}
                  setCountryMenuMob={setCountryMenuMob}
                />
              </div>
            )}
          </div>

          <div className="w-full px-4 pb-4">
            <Link
              to="/get-started"
              className="py-4 px-10 text-green-500 bg-light-green-500 hover:bg-light-green-500 rounded-lg font-semibold duration-300 text-center transition-all w-full inline-flex justify-center "
            >
              Get Started
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default NavBarDark;
