
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';


// Define the API endpoint URL

// Create the asynchronous thunk for fetching the wallet data
export const fetchCountry = createAsyncThunk(
    'country/getGeoDetails',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get("https://community.venco.africa/api/method/community.v1.community.fetch_country");
            return response.data;
        } catch (error) {
            // Handle any errors and return with rejectWithValue
            // console.log(error)
            return rejectWithValue(error.response.data);
        }
    }
);

// Create the setCountry reducer



// Create the Bank List slice
const blogs = createSlice({
    name: 'Blog List',
    initialState: {
        data: null,
        loading: false,
        country: 'Global',
        error: null,
        status: 'idle', // 'idle', 'pending', 'fulfilled', 'failed'
    },
    reducers: {
        setCountry: (state, action) => {
            state.country = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCountry.pending, (state) => {
                state.loading = true;
                state.status = 'pending';
                state.error = null;
            })
            .addCase(fetchCountry.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload.message.data[0];
                state.status = 'fulfilled';
                state.error = null;

                state.country = ['Nigeria', 'Ghana', 'Kenya'].includes(action.payload.message.data[0].country_name) ? action.payload.message.data[0].country_name : 'Global';
            })
            .addCase(fetchCountry.rejected, (state, action) => {
                state.loading = false;
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

// Export the wallet slice actions and reducer
export const { setCountry } = blogs.actions;
export default blogs.reducer;
