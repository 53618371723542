import React from "react";
import NavBarDark from "../components/NAVBAR/NavBarDark";
import Footer from "../components/FOOTER/Footer";
import { Helmet } from "react-helmet";

const ContactPage = () => {
  //   const [inputValues, setInputValues] = useState({
  //     // community info
  //     communityName: "",
  //     communityID: "",
  //     noOfProperties: "",
  //     country: "",
  //     state: "",
  //     streetAddress: "",
  //     communityDomainName: "",
  //     isForExistingGroup: false,

  //     // personal info
  //     firstName: "",
  //     lastName: "",
  //     email: "",
  //     phoneNo: "",
  //     customRole: "",
  //     userRole: "",
  //     userId: "",

  //     // meters
  //     electricMeters: false,
  //     waterMeters: false,
  //     gasMeters: false,
  //     noMeters: false,

  //     communityGroup: null,
  //     otp: "",
  //   });

  //   const handleInputChange = (event) => {
  //     const target = event.target;
  //     const value =
  //       target.type === "checkbox" || target.type === "radio"
  //         ? target.checked
  //         : target.value;
  //     const name = target.name;
  //     setInputValues((prevState) => ({
  //       ...prevState,
  //       [name]: value,
  //     }));
  //   };

  return (
    <>
      <Helmet>
        <title>
          Contact - Venco | All-in-one Community Management Software
        </title>
        <meta
          property="og:title"
          content="Contact - Venco | All-in-one Community Management Software"
        />
        <meta
          name="twitter:title"
          content="Contact - Venco | All-in-one Community Management Software"
        />
      </Helmet>
      <NavBarDark />
      <section className="container mx-auto my-10 xl:py-[100px] p-4">
        {/* <p className="text-center font-sora font-semibold text-2xl lg:text-6xl">
          Contact Form
        </p>
        <div className="h-5"></div>
        <p className="text-center font-inter">
          Kindly fill the form below to send us a message or a mail about your
          inquiries
        </p>

        <div className="h-[70px]" /> */}

        <div className="max-w-[671px]  mx-auto space-y-8">
          <iframe
            data-tally-src="https://tally.so/embed/wobPbX?alignLeft=1&amp;transparentBackground=1&amp;dynamicHeight=1"
            width="100%"
            height="100%"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            title="Let's Talk"
            data-tally-embed-widget-initialized="1"
            loading="lazy"
            scrolling="no"
            src="https://tally.so/embed/wobPbX?alignLeft=1&amp;transparentBackground=1&amp;dynamicHeight=1&amp;originPage=%2Fcontact-us%2F"
            id="iFrameResizer0"
            className="h-[820px] sm:h-[750px] lg:h-[700px]"
            style={{
              width: "1px",
              minWidth: "100%",
              overflow: "auto",
            }}
          ></iframe>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ContactPage;

// <form className="max-w-[671px] mx-auto space-y-8">
//   {/* Full name */}
//   <div className="">
//     <label htmlFor="email" className="formLabel">
//       Full Name
//     </label>
//     <input
//       type="text"
//       value={inputValues.fullname}
//       name="fullname"
//       id="fullname"
//       placeholder="Your Full Name"
//       onChange={handleInputChange}
//       autoComplete="name"
//       required
//       // className={this.props.is_erred ? "outline-input-field-erred" : (this.state.is_focus ? "outline-input-field-active" : "outline-input-field")}
//       className="formInput"
//     />
//   </div>

//   {/* Email */}
//   <div className="">
//     <label htmlFor="email" className="formLabel">
//       Email Address
//     </label>
//     <input
//       type="email"
//       value={inputValues.email}
//       name="email"
//       id="email"
//       placeholder="Email Address"
//       onChange={handleInputChange}
//       autoComplete="email"
//       required
//       // className={this.props.is_erred ? "outline-input-field-erred" : (this.state.is_focus ? "outline-input-field-active" : "outline-input-field")}
//       className="formInput"
//     />
//   </div>
//   {/* Subject */}
//   <div className="">
//     <label htmlFor="email" className="formLabel">
//       Subject
//     </label>
//     <input
//       type="text"
//       value={inputValues.subject}
//       name="subject"
//       id="subject"
//       placeholder="Your Request Subject"
//       onChange={handleInputChange}
//       required
//       // className={this.props.is_erred ? "outline-input-field-erred" : (this.state.is_focus ? "outline-input-field-active" : "outline-input-field")}
//       className="formInput"
//     />
//   </div>

//   {/* Your Request */}
//   <div className="">
//     <label htmlFor="email" className="formLabel">
//       Your Request
//     </label>
//     <textarea
//       type="text"
//       rows="5"
//       value={inputValues.subject}
//       name="subject"
//       id="subject"
//       placeholder="Please enter your request here"
//       onChange={handleInputChange}
//       required
//       // className={this.props.is_erred ? "outline-input-field-erred" : (this.state.is_focus ? "outline-input-field-active" : "outline-input-field")}
//       className="formInput"
//     />
//   </div>
//   <div className="flex justify-end">
//     <button type="submit" className="primaryButton px-10">
//       Submit
//     </button>
//   </div>
// </form>
