import React, { useState } from "react";
import footerLogo from "../../assets/images/footerLogo.svg";
import appstore from "../../assets/images/appstore.svg";
import playstore from "../../assets/images/playstore.svg";
// import twitter from "../../assets/images/twitter.svg";
// import facebook from "../../assets/images/facebook.svg";
// import instagram from "../../assets/images/instagram.svg";
import dataProtection from "../../assets/images/dataProtection.png";
import { Link } from "react-router-dom";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import Modal from "react-modal";
import { X } from "phosphor-react";
import Spinner from "../BUTTONS/Spinner";
import {
  RiFacebookFill,
  RiInstagramFill,
  RiLinkedinFill,
} from "react-icons/ri";

const Subscribe = () => {
  const customStyles = {
    content: {
      zIndex: 99999,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      backdropFilter: "blur(2px)",
    },
  };
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [err, setErr] = useState("");
  const [success, setSuccess] = useState("");
  const [isRecaptchaVerified, setRecaptchaVerified] = useState(false);
  const recaptchaRef = React.createRef();
  const onRecaptchaChange = (value) => {
    setRecaptchaVerified(value);
  };

  const handleSubmit = async () => {
    setLoading(true);

    if (!isRecaptchaVerified) {
      toast.error("Error submitting, please verify reCAPTCHA and try again", {
        position: "top-center",
        autoClose: 15000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoading(false);
      return false;
    }
    if (email === "") {
      setErr("email cannot be empty");
      return;
    }

    const result = await axios.get(
      `https://office.Venco.africa/api/method/add_subscriber?email=${email}`
    );

    setLoading(false);

    if (result.status === 200) {
      setLoading(false);
      setSuccess(result.data.message);
    }
  };

  const toggleModal = (value) => {
    setModalIsOpen(value);
  };

  function isValidEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  return (
    <form className="flex flex-col md:flex-row gap-5 w-full lg:w-fit items-center xl:w-2/3 xl:max-w-[934px]">
      <p className="text-white font-medium text-sm text-center font-sora whitespace-nowrap">
        Subscribe to our newsletter
      </p>

      {success === "" ? (
        <>
          <div className="w-full relative">
            <input
              type="email"
              name="email"
              id=""
              required
              value={email}
              onChange={(e) => {
                setErr("");
                setEmail(e.target.value);
              }}
              placeholder="What’s your email?"
              className={`bg-transparent focus:outline-none border  w-full px-5 rounded-lg py-4 placeholder:text-new-grey-400 text-white  text-sm font-inter  xl:h-[72px] ${
                err
                  ? "border-red-500 focus:border-red-500 mb-2"
                  : "border-[#6d7277] focus:border-light-green-500"
              }`}
            />
            {err && (
              <>
                <p className="text-red-300 text-sm font-inter left-0 -bottom-3 absolute">
                  {err}
                </p>
              </>
            )}
          </div>

          {!loading ? (
            <button
              type="submit"
              className="w-full bg-light-green-500 px-5 rounded-lg py-4 font-sora font-bold text-sm lg:text-base xl:text-lg text-green-500 xl:h-[72px] xl:w-[320px]"
              onClick={(e) => {
                e.preventDefault();

                if (!isValidEmail(email)) {
                  setErr("Invalid Email Address");
                }

                if (email !== "" && isValidEmail(email)) {
                  // console.log(isValidEmail(email));
                  toggleModal(true);
                }
              }}
            >
              Subscribe
            </button>
          ) : (
            <button className="w-full bg-light-green-500 px-5 rounded-lg py-4 font-sora font-bold text-sm lg:text-base xl:text-lg text-green-500 xl:h-[72px] xl:w-[320px] center">
              <Spinner color="black" />
              Loading
            </button>
          )}
        </>
      ) : (
        <p className="text-light-green-500 text-sm font-semibold">
          {success}, Thank you.
        </p>
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => toggleModal(false)}
        style={customStyles}
        // contentLabel="Vessel Modal"
        // overlayClassName="modal-overlay"
        className="vessel-modal"
      >
        <div className="mt-[250px] bg-white rounded-xl p-5 m-4 max-w-[460px] lg:p-[30px]">
          <>
            <div className="flex items-center justify-end pb-2">
              <div className="" onClick={() => toggleModal(false)}>
                <X weight="bold" className="text-base font-semibold" />
              </div>
            </div>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 25,
              }}
            >
              <ReCAPTCHA
                ref={recaptchaRef}
                tabindex={1}
                sitekey={"6LcstiIoAAAAAAdRrvboVRDpAcjT5DXizvjxqAth"}
                onChange={onRecaptchaChange}
              />
            </span>
            <div className="flex pt-4">
              <button
                className="primaryButton flex-1"
                onClick={() => {
                  if (isRecaptchaVerified) {
                    setLoading(true);
                    toggleModal(false);
                    handleSubmit();
                  }
                }}
              >
                Subscribe
              </button>
            </div>
          </>
        </div>
      </Modal>
    </form>
  );
};

const SocialMedia = () => {
  return (
    <>
      <div className="flex gap-5 items-center md:hidden">
        <p className="text-white font-medium text-sm text-center md:text-left font-inter whitespace-nowrap">
          Follow us
        </p>
        <div className="flex gap-2.5 text-white">
          <a
            href="http://faceboook.com/vencohq"
            target="_blank"
            rel="noopener noreferrer"
            className="w-8 h-8 bg-[#111111] rounded-sm center p-1"
          >
            <RiFacebookFill className="text-[28px] " />
          </a>

          <a
            href="http://twitter.com/vencohq"
            target="_blank"
            rel="noopener noreferrer"
            className="w-8 h-8 bg-[#111111] rounded-md center p-1"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              viewBox="0 0 24 24"
            >
              <path
                fill="rgba(255,255,255,1)"
                d="M8 2H1l8.26 11.014L1.45 22H4.1l6.388-7.349L16 22h7l-8.608-11.478L21.8 2h-2.65l-5.986 6.886L8 2Zm9 18L5 4h2l12 16h-2Z"
              />
            </svg>
          </a>
          <a
            href="http://instagram.com/vencohq"
            target="_blank"
            rel="noopener noreferrer"
            className="w-8 h-8 bg-[#111111] rounded-md center p-1"
          >
            <RiInstagramFill className="text-[28px] " />
          </a>
          <a
            href="https://www.linkedin.com/company/Venco-hq/"
            target="_blank"
            rel="noopener noreferrer"
            className="w-8 h-8 bg-[#111111] rounded-md center p-1"
          >
            <RiLinkedinFill className="text-[28px] " />
          </a>
        </div>
      </div>
      <div className="hidden md:block space-y-5 text-sm lg:text-base  text-center md:text-left text-white">
        <p className="text-new-grey-400 font-semibold">Follow Us</p>
        <div className="flex gap-2.5">
          <a
            href="http://faceboook.com/vencohq"
            target="_blank"
            rel="noopener noreferrer"
            className="w-8 h-8 bg-[#111111] rounded-sm center p-1"
          >
            <RiFacebookFill className="text-[28px] " />
          </a>

          <a
            href="http://twitter.com/vencohq"
            target="_blank"
            rel="noopener noreferrer"
            className="w-8 h-8 bg-[#111111] rounded-md center p-1"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              viewBox="0 0 24 24"
            >
              <path
                fill="rgba(255,255,255,1)"
                d="M8 2H1l8.26 11.014L1.45 22H4.1l6.388-7.349L16 22h7l-8.608-11.478L21.8 2h-2.65l-5.986 6.886L8 2Zm9 18L5 4h2l12 16h-2Z"
              />
            </svg>
          </a>
          <a
            href="http://instagram.com/vencohq"
            target="_blank"
            rel="noopener noreferrer"
            className="w-8 h-8 bg-[#111111] rounded-md center p-1"
          >
            <RiInstagramFill className="text-[28px] " />
          </a>
          <a
            href="https://www.linkedin.com/company/Venco-hq/"
            target="_blank"
            rel="noopener noreferrer"
            className="w-8 h-8 bg-[#111111] rounded-md center p-1"
          >
            <RiLinkedinFill className="text-[28px] " />
          </a>
        </div>
      </div>
    </>
  );
};

const DownloadApp = () => {
  return (
    <div className="flex flex-col mt-10 md:mt-0">
      <div>
        <p className="text-white font-medium text-sm text-center md:text-left font-sora whitespace-nowrap">
          Download our app
        </p>
        <div className="flex gap-2.5 mt-5 md:mt-3 lg:mt-5 flex-row md:flex-col lg:flex-row ">
          <a
            href="https://apps.apple.com/us/app/Venco-resident/id1542447397"
            target="_blank"
            rel="noopener noreferrer"
            className="md:w-[150px]  lg:w-auto xl:w-[165px] max-w-[191px]"
          >
            <img src={playstore} alt="app store button" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.manqala.estatemanager"
            target="_blank"
            rel="noopener noreferrer"
            className="md:w-[150px]  lg:w-auto xl:w-[165px] max-w-[191px]"
          >
            <img src={appstore} alt="app store button" />
          </a>
        </div>
      </div>

      <div className="mt-auto hidden md:block">{/* <SocialMedia /> */}</div>
    </div>
  );
};

const CompanyLinks = () => {
  return (
    <div className="space-y-5 text-sm lg:text-base text-white">
      <p className="text-new-grey-400 font-semibold">Company</p>
      <Link to="/about-us" className="block">
        About
      </Link>
      <Link to="/faq" className="block">
        FAQs
      </Link>
      <Link to="/contact-us" className="block">
        Contact Us
      </Link>
      <Link to="/referrer" className="block">
        Referral Program
      </Link>
      <Link to="/privacy-policy" className="block">
        Privacy Policy
      </Link>
      <Link to="/terms-and-condition" className="block whitespace-nowrap">
        Terms of Service
      </Link>
    </div>
  );
};

const ProductLinks = () => {
  return (
    <div className="space-y-5 text-sm lg:text-base text-white">
      <p className="text-new-grey-400 font-semibold">Products</p>
      <Link to="/products/residents" className="block">
        Venco for Residents
      </Link>
      <Link to="/products/admin" className="block whitespace-nowrap">
        Venco for Facility Managers
      </Link>
      <Link to="/products/security" className="block">
        Venco for Security
      </Link>
      <Link to="/products/meters" className="block">
        Smart Meters
      </Link>
    </div>
  );
};

// const ContactUs = () => {
//   return (
//     <div className="space-y-5 text-sm lg:text-base  text-center md:text-left text-white">
//       <p className="text-new-grey-400 font-semibold">Contact Us</p>
//       <a
//         href="mailto:info@Venco.africa"
//         target="_blank"
//         rel="noopener noreferrer"
//         className="block"
//       >
//         Email: info@Venco.africa
//       </a>
//     </div>
//   );
// };

const Footer = () => {
  return (
    <footer className="bg-black py-24 p-4 lg:py-24 xl:py-[170px]" id="footer">
      <div className="container mx-auto">
        <div className="flex justify-between items-center">
          <div className="hidden lg:block">
            <img src={footerLogo} alt="" className="w-[150px]" />
            <p className="text-white italic font-sora text-sm mt-2.5 ">
              Simplifying Living Experiences
            </p>
          </div>
          <Subscribe />
        </div>

        <div className="w-full h-[1px] bg-[#282A2C] my-10 hidden lg:my-16 xl:my-20 md:block"></div>

        <div className="block md:hidden">
          <DownloadApp />
        </div>
        <div className="w-full h-[1px] bg-[#282A2C] my-10   md:hidden"></div>
        <div className=" md:hidden">
          <img src={footerLogo} alt="" className="w-[150px] mx-auto" />
          <p className="text-white italic font-sora text-sm mt-2.5 text-center">
            Simplifying Living Experiences
          </p>
        </div>
        <div className="flex justify-between">
          <div className="hidden md:flex flex-col ">
            <DownloadApp />
            <div className=" mt-auto">
              <img
                src={dataProtection}
                alt=""
                className="md:max-w-[140px] lg:max-w-[180px]"
              />
            </div>
          </div>

          <div className="flex justify-between md:justify-start md:gap-6 border-y border-[#282A2C] py-6 my-10  md:py-0 md:my-0 md:border-transparent w-full md:w-fit xl:w-2/3 xl:max-w-[934px] xl:gap-24 xl:justify-end">
            <CompanyLinks />
            <ProductLinks />
            <div className="hidden md:flex flex-col gap-[50px] items">
              <SocialMedia />
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-center md:hidden border-b border-[#282A2C] pb-6 mb-10  md:pb-0 md:mb-0  ">
          <div className=" mb-[30px] mx-auto">
            <img
              src={dataProtection}
              alt=""
              className="mx-auto max-w-[180px]"
            />
          </div>
          <SocialMedia />
        </div>
        <div className="w-full h-[1px] bg-[#282A2C] my-10 lg:my-16 xl:my-20 hidden md:block"></div>
        <p className="text-center text-sm lg:text-base">
          <span className="text-white  font-normal">
            Copyright 2023 Venco, Inc. All rights reserved. Click to view{" "}
          </span>
          <Link
            to="/privacy-policy"
            className="text-light-green-500  font-normal underline underline-offset-4"
          >
            Privacy policy
          </Link>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
