import { CheckCircle } from "phosphor-react";
import React from "react";

const MeterFeatures = () => {
  return (
    <section className="container mx-auto my-6 xl:my-[170px]">
      <p
        className="font-sora text-center text-[30px] leading-[40px] font-semibold my-4 xl:my-5 xl:text-5xl xl:leading-[60px]"
        style={{ textWrap: "balance" }}
      >
        Meters Built To Empower
      </p>
      <div className="flex flex-wrap gap-[50px] justify-center p-4 md:p-10">
        <div className="w-full md:w-[40%] h-[450px] lg:w-[33%] lg:h-[542px] max-w-[400px] space-y-7 bg-orange-50 rounded-lg px-6 lg:px-[50px] flex flex-col items-center justify-center border border-orange-500">
          <div className="h-[120px] w-[120px] rounded-full bg-orange-100 center">
            <CheckCircle
              weight="fill"
              className="text-[40px] text-orange-500"
            />
          </div>
          <p className="font-sora text-xl lg:text-2xl font-semibold text-black-950 text-center">
            Real Estate Developers
          </p>
          <p className="font-inter  lg:text-lg text-[#4D4D4D] text-center">
            Make your projects more compelling for buyers by delivering homes
            along with Venco software
          </p>
        </div>
        <div className="w-full md:w-[40%] h-[450px] lg:w-[33%] lg:h-[542px] max-w-[400px] space-y-7 bg-light-purple-50 rounded-lg px-6 lg:px-[50px] flex flex-col items-center justify-center border border-light-purple-500">
          <div className="h-[120px] w-[120px] rounded-full bg-light-purple-100 center">
            <CheckCircle weight="fill" className="text-[40px] text-purple-50" />
          </div>
          <p className="font-sora text-xl lg:text-2xl font-semibold text-black-950 text-center">
            Facilities Managers
          </p>
          <p className="font-inter  lg:text-lg text-[#4D4D4D] text-center">
            Monitor and manage water & energy production and consumption with
            our easy to use software
          </p>
        </div>
        <div className="w-full md:w-[40%] h-[450px] lg:w-[33%] lg:h-[542px] max-w-[400px] space-y-7 bg-blue-50 rounded-lg px-6 lg:px-[50px] flex flex-col items-center justify-center border border-blue-500">
          <div className="h-[120px] w-[120px] rounded-full bg-blue-100 center">
            <CheckCircle weight="fill" className="text-[40px] text-blue-500" />
          </div>
          <p className="font-sora text-xl lg:text-2xl font-semibold text-black-950 text-center">
            Residents Associations
          </p>
          <p className="font-inter  lg:text-lg text-[#4D4D4D] text-center">
            Account for and allocate the cost of water & energy with no stress
          </p>
        </div>
        <div className="w-full md:w-[40%] h-[450px] lg:w-[33%] lg:h-[542px] max-w-[400px] space-y-7 bg-light-green-50 rounded-lg px-6 lg:px-[50px] flex flex-col items-center justify-center border border-light-green-500">
          <div className="h-[120px] w-[120px] rounded-full bg-light-green-200 center">
            <CheckCircle
              weight="fill"
              className="text-[40px] text-light-green-700"
            />
          </div>
          <p className="font-sora text-xl lg:text-2xl font-semibold text-black-950 text-center">
            Energy Service Providers
          </p>
          <p className="font-inter  lg:text-lg text-[#4D4D4D] text-center">
            Make your projects more compelling for buyers by delivering homes
            along with Venco software
          </p>
        </div>
        <div className="w-full md:w-[40%] h-[450px] lg:w-[33%] lg:h-[542px] max-w-[400px] space-y-7 bg-yellow-100 rounded-lg px-6 lg:px-[50px] flex flex-col items-center justify-center border border-yellow-600">
          <div className="h-[120px] w-[120px] rounded-full bg-yellow-200 center">
            <CheckCircle
              weight="fill"
              className="text-[40px] text-yellow-700"
            />
          </div>
          <p className="font-sora text-xl lg:text-2xl font-semibold text-black-950 text-center">
            Home and Business Owners
          </p>
          <p className="font-inter  lg:text-lg text-[#4D4D4D] text-center">
            Monitor and manage water & energy production and consumption with
            our easy to use software
          </p>
        </div>
        <div className="w-full md:w-[40%]  lg:w-[33%]  max-w-[400px] space-y-7  rounded-lg px-6 lg:px-[50px] flex flex-col items-center justify-center"></div>
      </div>
    </section>
  );
};

export default MeterFeatures;
