import { CaretRight, MagnifyingGlass } from "phosphor-react";
import React, { useState } from "react";
import featuredImage from "../../assets/images/blogFeatureImage.webp";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

// eslint-disable-next-line
const blogIndividual = () => {
  return (
    <div className="md:flex md:gap-8">
      <div className="md:w-1/2">
        <img src={featuredImage} alt="" />
      </div>
      <div className="md:w-1/2">
        <div className="flex justify-between mt-5 items-center md:mt-0">
          <p className="px-5 py-2.5 bg-grey-50 rounded-full font-sora text-xs text-new-black-400 font-medium">
            Estate Management
          </p>
          <p className="font-inter text-sm text-new-grey-500">
            August 14, 2023
          </p>
        </div>
        <div className="space-y-3 md:space-y-2.5 mt-4">
          <p className="font-sora font-semibold text-new-black-950 text-2xl">
            How to Lower Your Household Energy Costs
          </p>
          <p className="text-sm leading-[22px] font-inter text-new-grey-500">
            Venco simplifies community management for multi-unit properties,
            providing easy-to-use applications for property owners and occupants
            to access communal services.
          </p>
          <div className="w-fit flex gap-1.5 items-center text-new-grey-500 cursor-pointer group">
            <p className="underline underline-offset-4  font-medium text-sm">
              Read the article
            </p>
            <CaretRight className="h-[18px] group-hover:translate-x-1 duration-300 transition-all" />
          </div>
        </div>

        <div className="h-[1px] w-full bg-grey-200 my-[50px] md:hidden"></div>
      </div>
    </div>
  );
};

const BlogFilterButton = ({ item, blogFilter, setBlogFilter }) => {
  return (
    <p
      className={`cursor-pointer py-2.5 px-8  rounded-full  text-xs xl:text-xl font-sora font-medium whitespace-nowrap snap-start hover:bg-light-green-500 ${
        item === blogFilter ? "bg-light-green-500" : ""
      }`}
      onClick={() => {
        setBlogFilter(item);
      }}
    >
      {item}
    </p>
  );
};

const LatestBlog = ({ blogList }) => {
  const navigate = useNavigate();
  const [blogFilter, setBlogFilter] = useState("All Posts");
  const filterList = [
    "All Posts",
    "Real Estate",
    "Finance",
    "Business",
    "Technology",
  ];

  return (
    <section className="container mx-auto p-4 my-8 lg:my-[96px] ">
      <div className="flex justify-between">
        <p className="font-sora font-semibold text-xl text-new-black-950 hidden md:block xl:text-5xl">
          The Latest Posts
        </p>
        <div className="rounded-full border border-grey-400 py-2 px-5 flex items-center gap-2.5 w-full md:w-[445px] focus-within:border-light-green-600">
          <MagnifyingGlass className="text-grey-400 h-[14px]" />
          <input
            type="search"
            className="flex-1 placeholder:text-grey-400 placeholder:text-sm text-base focus:outline-none"
            placeholder="Search for Keywords"
          />
        </div>
      </div>
      <div className="w-full h-[1px] bg-grey-200 md:my-6 lg:my-10 hidden md:block " />
      <div className="w-full overflow-x-scroll snap-x snap-start mt-5 no-scrollbar">
        <div className="w-fit gap-1.5 flex items-center flex-wrap">
          {filterList.map((item, index) => {
            return (
              <BlogFilterButton
                item={item}
                blogFilter={blogFilter}
                setBlogFilter={setBlogFilter}
              />
            );
          })}
        </div>
      </div>

      <div className="mt-[50px] mb-[30px]">
        <p className="font-sora font-semibold text-xl text-new-black-950 md:hidden">
          The Latest Posts
        </p>
        <div className="w-full h-[1px] bg-grey-200 mt-5 md:hidden" />
      </div>

      {blogList && (
        <div className="md:flex md:gap-8 md:border border-light-purple-200 md:p-8 md:rounded-[12px] items-center">
          <div className="md:w-1/2 xl:w-2/3 rounded-2xl overflow-hidden">
            <img src={blogList[0].jetpack_featured_media_url} alt="" />
          </div>
          <div className="md:w-1/2 max-w-[545px]">
            <div className="flex justify-between mt-5 items-center md:mt-0">
              <p className="px-5 py-2.5 bg-grey-50 rounded-full font-sora text-xs text-new-black-400 font-medium md:hidden">
                Estate Management
              </p>
              <p className="font-inter text-sm text-new-grey-500 font-medium">
                {format(Date.parse(blogList[0].date), "MMM dd, yyyy")}
              </p>
            </div>
            <div className="space-y-3 md:space-y-2.5 mt-4 xl:space-y-[30px] ">
              <p
                className="font-sora font-semibold text-new-black-950 text-2xl xl:text-[32px] lg:leading-[49px]"
                dangerouslySetInnerHTML={{ __html: blogList[0].title.rendered }}
              >
                {/* How to Lower Your Household Energy Costs */}
              </p>
              <p
                className="text-sm leading-[22px] font-inter text-new-grey-500 xl:text-base xl:leading-[150%]"
                dangerouslySetInnerHTML={{
                  __html: blogList[0].excerpt.rendered,
                }}
              >
                {/* Venco simplifies community management for multi-unit properties,
                providing easy-to-use applications for property owners and
                occupants to access communal services. */}
              </p>
              <div className="flex justify-between items-center">
                <p className="px-5 py-2.5 bg-grey-50 rounded-full font-sora text-xs text-new-black-400 font-medium hidden md:block xl:text-[18px] xl:px-10 xl:py-4">
                  Estate Management
                </p>

                <div
                  className="w-fit flex gap-1.5 items-center text-new-grey-500 cursor-pointer group"
                  onClick={() => {
                    navigate(`/blog/${blogList[0].slug}`);
                  }}
                >
                  <p className="underline underline-offset-4  font-medium text-sm">
                    Read the article
                  </p>
                  <CaretRight className="h-[18px] group-hover:translate-x-1 duration-300 transition-all" />
                </div>
              </div>
            </div>

            <div className="h-[1px] w-full bg-grey-200 my-[50px] md:hidden"></div>
          </div>
        </div>
      )}
    </section>
  );
};

export default LatestBlog;
