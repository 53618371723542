import axios from "axios"

const baseURL = 'https://community.venco.africa/api/method'


export const ReferralSignUp = async (userInput) => {
    try {
        const response = await axios
            .post(`${baseURL}/community.v1.auth.signup`, userInput)
        return response.data
    } catch (err) {
        throw err
    }
}
export const ResendToken = async (userInput) => {
    try {
        const response = await axios
            .post(`${baseURL}/community.v1.auth.resend_token`, userInput)
        return response.data
    } catch (err) {
        throw err
    }
}


export const verifyPhoneNumber = async (userInput) => {
    console.log(userInput)
    try {
        const response = await axios
            .post(`${baseURL}/community.v1.auth.challenge`, userInput)
        return response.data
    } catch (err) {
        throw err
    }
}
export const referCommunity = async (userInput) => {
    console.log(userInput)
    try {
        const response = await axios
            .post(`${baseURL}/community.v1.community.refer_community`, userInput)
        console.log(response);
        return response.data
    } catch (err) {
        throw err
    }
}