import React from "react";
import hero from "../../assets/images/residentsHero.webp";

import appIcon from "../../assets/images/appIcon.svg";
import { Link } from "react-router-dom";

const ResidentsHero = () => {
  return (
    <section className=" w-full">
      <div className="container mx-auto px-4">
        <div className="rounded-full bg-light-purple-100 w-fit mx-auto text-xs py-1.5 px-6 mt-[40px] xl:mt-[150px]">
          <p className="font-medium font-inter text-green-950 lg:text-xl lg:px-8 py-3">
            Venco for Residents
          </p>
        </div>
        <p className="font-sora text-green-500 font-semibold pt-2.5 md:pt-5  text-[26px] leading-[32px] md:text-4xl lg:text-5xl xl:text-6xl text-center">
          Seamless Living Experience
        </p>

        <div className="h-4 lg:h-6"></div>
        <p className=" w-4/5 md:w-2/3 text-center  text-sm leading-[22px] md:text-base lg:text-xl  md:leading-[28px] mx-auto font-inter max-w-[653px] xl:leading-[32px]">
          We simplify living experiences across Africa with our all-in-one
          community management solution.
        </p>

        <div className="h-5 md:h-6 lg:h-10"></div>
        <div className="flex justify-center gap-4 flex-col md:flex-row">
          <Link
            to="/how-it-works-for-residents"
            className="py-4 px-10 text-green-500 bg-light-green-500 hover:bg-light-green-600 rounded-lg font-semibold duration-300 transition-all text-sm md:text-base xl:text-lg text-center flex center gap-1.5"
            // onMouseEnter={() => {
            //   setToggleTooltip(true);
            // }}
            // onMouseLeave={() => {
            //   setToggleTooltip(false);
            // }}
          >
            Join your community
          </Link>
          <div className="flex justify-center gap-4 flex-col md:flex-row">
            <a
              target="_blank"
              alt="download-app-button"
              rel="noreferrer"
              href="https://vnco.to/mobile"
              className="flex py-4 px-10 border-green-500  text-green-500 rounded-lg border font-semibold items-center justify-center gap-2.5 hover:bg-light-green-100 duration-300 transition-all text-sm md:text-base xl:text-lg"
            >
              <img src={appIcon} alt="Download" className="w-[60px]" />
              <p className="">Download App</p>
            </a>
          </div>
        </div>

        <div className="h-[40px]" />
        <div className="md:w-full md:h-auto rounded-[20px] lg:max-h-[930px] h-[480px] border flex justify-center items-center overflow-hidden">
          <img
            src={hero}
            alt=""
            className="h-full max-w-none md:h-auto md:w-full"
          />
        </div>
      </div>
    </section>
  );
};

export default ResidentsHero;
