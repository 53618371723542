import React from "react";

import { RiArrowRightUpLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import bg from "../../assets/svg/joinTheBesstBg.svg";

const ReferrerCTA = () => {
  return (
    <section className=" w-full bg-grey-50 p-1 relative overflow-hidden">
      <div className="container mx-auto px-4 my-10 xl:my-[170px] relative z-[1]">
        <p className="font-sora text-green-500 font-semibold pt-2.5 md:pt-5  text-[26px] leading-[32px] md:text-4xl lg:text-5xl lg:leading-[66px] text-center 2xl:max-w-[977px] mx-auto">
          Ready to Join as a Referrer?
        </p>

        <div className="h-5 md:h-6 lg:h-10"></div>
        <div className="flex justify-center gap-4 flex-col md:flex-row">
          <Link
            to="/signup-referral"
            className="primaryButton w-full md:w-fit md:px-14"
          >
            Get Started
          </Link>
          <button className="secondaryButton w-full  md:w-fit md:px-14 center gap-2.5 text-green-500">
            Referral Terms
            <RiArrowRightUpLine className="h-4" />
          </button>
        </div>
      </div>
      <div className="absolute top-1/2 -translate-y-1/2 left-0  hidden lg:block z-[0] w-full">
        <img src={bg} alt="" srcset="" />
      </div>
      {/* <div className="absolute top-2/3 -translate-y-1/2 left-0 hidden lg:block z-[0]">
        <svg
          width="409"
          height="1038"
          viewBox="0 0 409 1038"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M152.312 15.0209V226.953C152.312 230.936 150.73 234.757 147.914 237.574C145.098 240.391 141.279 241.974 137.296 241.974H14.9995C11.024 241.974 7.21126 243.553 4.40015 246.365C1.58903 249.177 0.00976563 252.991 0.00976562 256.968V469.006C0.00976563 472.982 1.58903 476.796 4.40015 479.608C7.21126 482.42 11.024 484 14.9995 484H240.717C244.693 484 248.505 482.42 251.316 479.608C254.128 476.796 255.707 472.982 255.707 469.006V257.047C255.707 255.076 256.095 253.124 256.85 251.303C257.605 249.482 258.712 247.828 260.107 246.435C261.501 245.043 263.157 243.939 264.979 243.187C266.8 242.435 268.752 242.049 270.723 242.053H393.02C394.997 242.053 396.955 241.662 398.781 240.901C400.606 240.141 402.263 239.027 403.657 237.624C405.05 236.22 406.152 234.555 406.899 232.723C407.646 230.892 408.023 228.931 408.009 226.953V15.0209C408.013 13.0496 407.628 11.0969 406.876 9.27463C406.124 7.45237 405.021 5.79628 403.628 4.40111C402.236 3.00595 400.583 1.8991 398.762 1.14391C396.942 0.388715 394.99 -3.06591e-06 393.02 0H167.302C165.331 -3.06591e-06 163.38 0.388715 161.56 1.14391C159.739 1.8991 158.086 3.00595 156.693 4.40111C155.301 5.79628 154.198 7.45237 153.446 9.27463C152.694 11.0969 152.309 13.0496 152.312 15.0209Z"
            fill="url(#paint0_linear_467_2944)"
          />
          <path
            d="M152.312 569.021V780.953C152.312 784.936 150.73 788.757 147.914 791.574C145.098 794.391 141.279 795.974 137.296 795.974H14.9995C11.024 795.974 7.21126 797.553 4.40015 800.365C1.58903 803.177 0.00976563 806.991 0.00976562 810.968V1023.01C0.00976563 1026.98 1.58903 1030.8 4.40015 1033.61C7.21126 1036.42 11.024 1038 14.9995 1038H240.717C244.693 1038 248.505 1036.42 251.316 1033.61C254.128 1030.8 255.707 1026.98 255.707 1023.01V811.047C255.707 809.076 256.095 807.124 256.85 805.303C257.605 803.482 258.712 801.828 260.107 800.435C261.501 799.043 263.157 797.939 264.979 797.187C266.8 796.435 268.752 796.049 270.723 796.053H393.02C394.997 796.053 396.955 795.662 398.781 794.901C400.606 794.141 402.263 793.027 403.657 791.624C405.05 790.22 406.152 788.555 406.899 786.723C407.646 784.892 408.023 782.931 408.009 780.953V569.021C408.013 567.05 407.628 565.097 406.876 563.275C406.124 561.452 405.021 559.796 403.628 558.401C402.236 557.006 400.583 555.899 398.762 555.144C396.942 554.389 394.99 554 393.02 554H167.302C165.331 554 163.38 554.389 161.56 555.144C159.739 555.899 158.086 557.006 156.693 558.401C155.301 559.796 154.198 561.452 153.446 563.275C152.694 565.097 152.309 567.05 152.312 569.021Z"
            fill="url(#paint1_linear_467_2944)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_467_2944"
              x1="204.01"
              y1="0"
              x2="204.01"
              y2="484"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#E0F6B2" />
              <stop offset="1" stop-color="#E0F6B2" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_467_2944"
              x1="204.01"
              y1="554"
              x2="204.01"
              y2="1038"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#E0F6B2" />
              <stop offset="1" stop-color="#E0F6B2" stop-opacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="absolute top-2/3 -translate-y-1/2 right-0 hidden lg:block z-[0]">
        <svg
          width="409"
          height="1038"
          viewBox="0 0 409 1038"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M152.312 15.0209V226.953C152.312 230.936 150.73 234.757 147.914 237.574C145.098 240.391 141.279 241.974 137.296 241.974H14.9995C11.024 241.974 7.21126 243.553 4.40015 246.365C1.58903 249.177 0.00976563 252.991 0.00976562 256.968V469.006C0.00976563 472.982 1.58903 476.796 4.40015 479.608C7.21126 482.42 11.024 484 14.9995 484H240.717C244.693 484 248.505 482.42 251.316 479.608C254.128 476.796 255.707 472.982 255.707 469.006V257.047C255.707 255.076 256.095 253.124 256.85 251.303C257.605 249.482 258.712 247.828 260.107 246.435C261.501 245.043 263.157 243.939 264.979 243.187C266.8 242.435 268.752 242.049 270.723 242.053H393.02C394.997 242.053 396.955 241.662 398.781 240.901C400.606 240.141 402.263 239.027 403.657 237.624C405.05 236.22 406.152 234.555 406.899 232.723C407.646 230.892 408.023 228.931 408.009 226.953V15.0209C408.013 13.0496 407.628 11.0969 406.876 9.27463C406.124 7.45237 405.021 5.79628 403.628 4.40111C402.236 3.00595 400.583 1.8991 398.762 1.14391C396.942 0.388715 394.99 -3.06591e-06 393.02 0H167.302C165.331 -3.06591e-06 163.38 0.388715 161.56 1.14391C159.739 1.8991 158.086 3.00595 156.693 4.40111C155.301 5.79628 154.198 7.45237 153.446 9.27463C152.694 11.0969 152.309 13.0496 152.312 15.0209Z"
            fill="url(#paint0_linear_467_2944)"
          />
          <path
            d="M152.312 569.021V780.953C152.312 784.936 150.73 788.757 147.914 791.574C145.098 794.391 141.279 795.974 137.296 795.974H14.9995C11.024 795.974 7.21126 797.553 4.40015 800.365C1.58903 803.177 0.00976563 806.991 0.00976562 810.968V1023.01C0.00976563 1026.98 1.58903 1030.8 4.40015 1033.61C7.21126 1036.42 11.024 1038 14.9995 1038H240.717C244.693 1038 248.505 1036.42 251.316 1033.61C254.128 1030.8 255.707 1026.98 255.707 1023.01V811.047C255.707 809.076 256.095 807.124 256.85 805.303C257.605 803.482 258.712 801.828 260.107 800.435C261.501 799.043 263.157 797.939 264.979 797.187C266.8 796.435 268.752 796.049 270.723 796.053H393.02C394.997 796.053 396.955 795.662 398.781 794.901C400.606 794.141 402.263 793.027 403.657 791.624C405.05 790.22 406.152 788.555 406.899 786.723C407.646 784.892 408.023 782.931 408.009 780.953V569.021C408.013 567.05 407.628 565.097 406.876 563.275C406.124 561.452 405.021 559.796 403.628 558.401C402.236 557.006 400.583 555.899 398.762 555.144C396.942 554.389 394.99 554 393.02 554H167.302C165.331 554 163.38 554.389 161.56 555.144C159.739 555.899 158.086 557.006 156.693 558.401C155.301 559.796 154.198 561.452 153.446 563.275C152.694 565.097 152.309 567.05 152.312 569.021Z"
            fill="url(#paint1_linear_467_2944)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_467_2944"
              x1="204.01"
              y1="0"
              x2="204.01"
              y2="484"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#E0F6B2" />
              <stop offset="1" stop-color="#E0F6B2" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_467_2944"
              x1="204.01"
              y1="554"
              x2="204.01"
              y2="1038"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#E0F6B2" />
              <stop offset="1" stop-color="#E0F6B2" stop-opacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </div> */}
    </section>
  );
};

export default ReferrerCTA;
