import React from "react";
import hero from "../../assets/images/meterHero2.webp";
import SignUpButton from "../BUTTONS/SignUpButton";
import ViewDemoButton2 from "../BUTTONS/ViewDemoButton2";
import { Info } from "phosphor-react";

const SmartMeterHeroVar = () => {
  return (
    <section className=" w-full">
      <div className="container mx-auto  flex flex-col lg:flex-row lg:justify-between pt-[40px] md:pt-[96px]">
        <div className="lg:w-1/2 flex flex-col justify-center px-4">
          <div className="rounded-full bg-light-green-150 w-fit mx-auto lg:mx-0 text-xs py-1.5 px-6 mt-[70px] xl:mt-0">
            <p className="font-medium font-inter text-green-950 lg:text-xl lg:px-8 py-3">
              Venco Smart Meters
            </p>
          </div>
          <p className="font-sora text-green-500 font-semibold  text-[22px] md:text-4xl lg:text-[40px] xl:text-[50px] 2xl:text-6xl text-center lg:text-left mt-5">
            Venco Smart Meters
          </p>

          <div className="h-4 lg:h-6"></div>
          <p className=" w-4/5 md:w-2/3 lg:w-3/4 text-center lg:text-left  text-sm leading-[22px] md:text-base lg:text-lg  md:leading-[28px] mx-auto lg:mx-0 font-inter max-w-[653px] xl:leading-[32px]">
            Our smart meters allow communities to monitor, control, and allocate
            the cost of occupants’ energy, water and gas usage based on their
            consumption.
          </p>

          <div className="h-5 md:h-6 lg:h-10"></div>
          <div className="flex justify-center lg:justify-start gap-4 flex-col md:flex-row">
            <SignUpButton />
            <ViewDemoButton2 />
          </div>
          <p className="text-sm text-green-500 mt-5 text-center lg:text-left ">
            <span>
              <Info className="text-green-500 text-sm inline -mt-[2px]" weight="bold" />
            </span>{" "}
            Free for use when using the visitor booking module only
          </p>
        </div>
        <div className="h-[40px]" />
        <div className="md:w-full md:h-auto rounded-[20px] lg:max-h-[930px] h-[480px] flex justify-center items-center overflow-hidden lg:w-[45%]">
          <img
            src={hero}
            alt=""
            className="h-full max-w-none md:h-auto md:w-full"
          />
        </div>
      </div>
    </section>
  );
};

export default SmartMeterHeroVar;
