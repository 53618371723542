import React from "react";
// import NavBar from "../components/NAVBAR/NavBar";
import NavBarDark from "../components/NAVBAR/NavBarDark";
import Stats from "../components/HOMEPAGE/Stats";
import Testimonial from "../components/HOMEPAGE/Testimonial";
import FAQ from "../components/FAQ/FAQ";
import JoinTheBest from "../components/HOMEPAGE/JoinTheBest";
import Footer from "../components/FOOTER/Footer";
// import SmartMeterHero from "../components/METERS/SmartMeterHero";
import MeterFeatures from "../components/METERS/MeterFeatures";
import SmartMeterHeroVar from "../components/METERS/SmartMeterHeroVar";
import IntelligentUtility from "../components/METERS/IntelligentUtility";
import { Helmet } from "react-helmet";

const SmartMeterPage = () => {
  return (
    <>
      <Helmet>
        <title>
          Smart Meter - Venco | All-in-one Community Management Software
        </title>
        <meta
          property="og:title"
          content="Smart Meter - Venco | All-in-one Community Management Software"
        />
        <meta
          name="twitter:title"
          content="Smart Meter - Venco | All-in-one Community Management Software"
        />
      </Helmet>
      <NavBarDark />
      {/* <NavBar /> */}
      {/* <SmartMeterHero /> */}
      <SmartMeterHeroVar />
      <Stats />
      <MeterFeatures />
      <IntelligentUtility />
      {/* <SecurityFeatures /> */}
      <Testimonial />
      <FAQ />
      <JoinTheBest />
      <Footer />
    </>
  );
};

export default SmartMeterPage;
