import {
  BASEURL,
} from './env'

export const signIn = async (params) => {  
  try {
    const url = `${BASEURL}/community.v1.auth.signin`
    console.log('url', url);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        ...params
      }),
    })

    return response
  } catch (error) {    
    throw error
  }

}

export const request_otp = async (params) => {
  try {
    const url = `${BASEURL}/community.v1.auth.resend_token`
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        ...params
      }),
    })

    return response
  } catch (error) {
    throw error
  }
}

export const request_community_onboarding = async (params) => {
  try {
    const url = `${BASEURL}/community.v1.community.request_community_onboarding`
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        ...params
      }),
    })

    return response
  } catch (error) {
    throw error
  }
}

export const verify_otp = async (params) => {
  try {
    const url = `${BASEURL}/community.v1.auth.challenge`
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        ...params
      }),
    })
    return response
  } catch (error) {
    throw error
  }
  
}

export const verify_request = async (tmp_id) => {
  try {
    const url = `${BASEURL}/community.v1.auth.verify_request?tmp_id=${tmp_id}`
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    return response
  } catch (error) {
    throw error
  }
  
}

export const get_location = async (params) => {
  try {
    const url = `${BASEURL}/community.v1.community.get_geolocation`
    return await fetch (url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        ...params
      }),
    })
  } catch (error) {
    throw error
  }
}

export const get_countries = async () => {
  try {
    const url = `${BASEURL}/community.v1.community.get_country`
    return await fetch (url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
  } catch (error) {
    throw error
  }
}

export const confirm_community_onboarding = async (params) => {
  try {
    const url = `${BASEURL}/community.v1.community.confirm_community_onboarding`
    return await fetch (url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        ...params
      }),
    })
  } catch (error) {
    throw error
  }
}