import React, { useEffect, useState } from "react";
import CustomPhoneInput from "../INPUTS/CustomPhoneInput";
import { defaultCountries, parseCountry } from "react-international-phone";
import { useSelector } from "react-redux";
import Spinner from "../BUTTONS/Spinner";
import { referCommunity } from "./ReferralApi";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { RiCheckFill } from "react-icons/ri";

// eslint-disable-next-line
import OtpTimer from "../util/OtpTimer";
import { get_countries } from "../../api/auth";
import { sleep } from "../OnbaordingForm/OnboardingForm";

const ReferCommunityForm = () => {
  const [selectedCountry, setSelectedCountry] = useState(
    parseCountry(defaultCountries[138])
  );

  function getQueryParam(name, search) {
    const params = new URLSearchParams(search);
    return params.get(name);
  }

  const location = useLocation();
  const referralCode = getQueryParam("referral_url", location.search);

  const [step, setStep] = useState(1);
  const [countries, setCountries] = useState([]);
  const presentCountry = useSelector((state) => state.geoDetails.country);

  useEffect(() => {
    getCountries();
    // eslint-disable-next-line
  }, []);

  function sortByActiveStatus(a, b) {
    if (a.active === b.active) {
      // If both items have the same active status, maintain the original order
      return 0;
    } else if (a.active === 1) {
      // Move items with active: 1 to the top
      return -1;
    } else {
      // Move items with active: 0 to the bottom
      return 1;
    }
  }

  const getCountries = async () => {
    try {
      await sleep(2000);
      const res = await get_countries();
      // console.log("res status", res.status)
      if (res.ok) {
        const { message } = await res.json();
        const data = message.data;
        data.sort(sortByActiveStatus);
        setCountries(data);
      } else {
        setCountries([
          {
            country_name: "Ghana",
            country_code: "gh",
            currency: "GHS",
            active: 1,
            emergency_numbers: [
              {
                emergency_number_type: "Police",
                emergency_number: "191",
              },
              {
                emergency_number_type: "Fire",
                emergency_number: "192",
              },
              {
                emergency_number_type: "Medical",
                emergency_number: "193",
              },
            ],
          },
          {
            country_name: "Kenya",
            country_code: "ke",
            currency: "KES",
            active: 1,
            emergency_numbers: [
              {
                emergency_number_type: "General",
                emergency_number: "112",
              },
            ],
          },
          {
            country_name: "Nigeria",
            country_code: "ng",
            currency: "NGN",
            active: 1,
            emergency_numbers: [
              {
                emergency_number_type: "General",
                emergency_number: "112",
              },
            ],
          },
        ]);
      }
    } catch (error) {}
  };

  const handlePhoneNumberInput = (e) => {
    const target = e.target;
    const name = target.name;
    if (target.value.length > 1 && target.value.charAt(0) === "0") {
      // Remove the leading '0'
      setInputValues((prevState) => ({
        ...prevState,
        [name]: target.value.slice(1),
      }));
      return;
    }
    setInputValues((prevState) => ({
      ...prevState,
      [name]: target.value,
    }));
  };

  useEffect(() => {
    if (presentCountry !== "Global") {
      const name = "country";
      setInputValues((prevState) => ({
        ...prevState,
        [name]: presentCountry,
      }));
    }

    if (referralCode) {
      const name = "referral_code";
      setInputValues((prevState) => ({
        ...prevState,
        [name]: referralCode,
      }));
    }
  }, [presentCountry, referralCode]);

  const [inputValues, setInputValues] = useState({
    referral_code: "",
    community_name: "",
    community_type: "",
    address: "",
    country: "",
    contact_name: "",
    contact_email: "",
    contact_number: "",
    terms_and_condition: true,
  });
  // eslint-disable-next-line
  const [resendOtpVar, setResendOtpVar] = useState(false);

  const handleInputChange = (event) => {
    const target = event.target;
    const value =
      target.type === "checkbox" || target.type === "radio"
        ? target.checked
        : target.value;
    const name = target.name;
    setInputValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [loading, setLoading] = useState(false);

  const handleSubmitRegister = async (event) => {
    const country = selectedCountry;
    const phoneNumber = "+" + country.dialCode + parseInt(inputValues.phoneNo);
    setLoading(true);
    event.preventDefault();
    const userInput = {
      referral_code: inputValues.referral_code,
      community_name: inputValues.community_name,
      community_type: inputValues.community_type,
      address: inputValues.address,
      country: inputValues.country,
      contact_name: inputValues.contact_name,
      contact_email: inputValues.contact_email,
      contact_number: phoneNumber,
    };

    await referCommunity(userInput)
      .then(async (response) => {
        setLoading(false);
        setStep(2);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        toast.error(error?.response?.data.message, {
          position: "top-center",
          autoClose: 15000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          zIndex: 1000,
        });
      });
    setLoading(false);
  };

  return (
    <div className="flex-1">
      {step === 1 && (
        <div className="bg-grey-50 rounded-lg px-4 py-10 lg:px-10 lg:py-16 xl:px-[80px] xl:py-[51px] h-full">
          <p className="font-sora font-semibold text-[24px] md:text-2xl lg:text-[40px] text-center xl:max-w-[927px] xl:mx-auto md:text-left">
            Refer A Community
          </p>
          <div className="h-6"></div>
          <p className="text-sm font-medium text-center md:text-left">
            Fill out this short form to get started
          </p>

          <form
            action=""
            className="pt-10 flex flex-col gap-4 md:gap-5 xl:min-h-[600px]"
            onSubmit={handleSubmitRegister}
          >
            <div>
              <input
                type="text"
                value={inputValues.community_name}
                name="community_name"
                placeholder="Name of Estate or Community you want to refer?Community Name"
                onChange={handleInputChange}
                // autoComplete='name'
                required
                // className={this.props.is_erred ? "outline-input-field-erred" : (this.state.is_focus ? "outline-input-field-active" : "outline-input-field")}
                className="formInput"
              />
            </div>

            <div className="formInput">
              <select
                name="community_type"
                id="community_type"
                className="w-full p-0 bg-transparent focus:outline-none placeholder:text-grey-600 text-sm lg:text-base"
                value={inputValues.community_type}
                placeholder="Select your role"
                onChange={handleInputChange}
                // disabled={!isPersonalInfoEditable}
              >
                <option value="" defaultValue disabled>
                  Type of Estate
                </option>
                <option value="Facilities Manager">Facilities Manager</option>
                <option value="Property Manager">Property Manager</option>
                <option value="Resident">Resident</option>
                <option value="Community Association ExCo">
                  Community Association ExCo
                </option>
                <option value="Property Developer">Property Developer</option>
                <option value="Others">Others</option>
              </select>
            </div>

            <div>
              <input
                type="text"
                value={inputValues.address}
                name="address"
                placeholder="Address"
                onChange={handleInputChange}
                // autoComplete='name'
                required
                // className={this.props.is_erred ? "outline-input-field-erred" : (this.state.is_focus ? "outline-input-field-active" : "outline-input-field")}
                className="formInput"
              />
            </div>

            <div className="flex flex-col md:flex-row gap-4">
              <div className="flex-1">
                <input
                  type="text"
                  value={inputValues.contact_name}
                  name="contact_name"
                  placeholder="Name of Community Contact"
                  onChange={handleInputChange}
                  autoComplete="name"
                  required
                  // className={this.props.is_erred ? "outline-input-field-erred" : (this.state.is_focus ? "outline-input-field-active" : "outline-input-field")}
                  className="formInputUpdate"
                />
              </div>

              <div className="flex-1">
                <input
                  type="email"
                  value={inputValues.contact_email}
                  name="contact_email"
                  id="contact_email"
                  placeholder="Email of Community Contact"
                  onChange={handleInputChange}
                  autoComplete="contact_email"
                  required
                  className="formInputUpdate"
                />
              </div>
            </div>

            <div>
              <div
                className={`flex font-inter items-center relative gap-1 md:gap-2.5`}
              >
                <CustomPhoneInput
                  selectedCountry={selectedCountry}
                  setSelectedCountry={setSelectedCountry}
                  flag={false}
                />

                <input
                  type="text"
                  value={inputValues.phoneNo}
                  name="phoneNo"
                  placeholder="Phone Number"
                  onChange={handlePhoneNumberInput}
                  autoComplete="tel"
                  required
                  // className={this.props.is_erred ? "outline-input-field-erred" : (this.state.is_focus ? "outline-input-field-active" : "outline-input-field")}
                  className="formInputUpdate"
                />
              </div>
            </div>
            <div className="">
              <input
                type="text"
                value={inputValues.referral_code}
                name="referral_code"
                id="referral_code"
                placeholder="Referral Code"
                onChange={handleInputChange}
                autoComplete="referral_code"
                className="formInput"
              />
            </div>
            <div className="">
              <div className="formInput">
                <select
                  name="country"
                  id="country"
                  className="input-field"
                  value={inputValues.country}
                  onChange={handleInputChange}
                  required
                >
                  <option value="" defaultValue disabled>
                    Select a country
                  </option>
                  {countries.map((item, index) => (
                    <option value={item.country_name} key={index}>
                      {item.country_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <label className="flex gap-2.5 items-start">
              <input
                type="checkbox"
                required
                value={inputValues.terms_and_condition}
                onChange={(e) => {
                  setInputValues((prevState) => ({
                    ...prevState,
                    terms_and_condition: e.target.value,
                  }));
                }}
                name="isForExistingGroup"
                className="accent-light-green-500"
                checked={inputValues.terms_and_condition}
              />
              <p className="text-xs font-inter">
                I agree with Venco’s Terms of Service and Privacy Policy.
              </p>
            </label>
            {loading ? (
              <button
                className="primaryButton center"
                type="submit"
                disabled={true}
              >
                <Spinner color="black" />
                Loading...
              </button>
            ) : (
              <button className="primaryButton" type="submit">
                Submit
              </button>
            )}
          </form>
        </div>
      )}

      {step === 2 && (
        <div className="bg-grey-50 rounded-lg px-4 py-10 lg:px-10 lg:py-16 xl:px-[80px] xl:py-[51px] h-full">
          <p
            className="font-sora font-semibold text-[24px] md:text-2xl lg:text-[40px] xl:max-w-[927px] xl:mx-auto md:text-left"
            style={{ lineHeight: "110%" }}
          >
            Your Community has been referred successfully
          </p>
          <div className="h-6"></div>
          <p className="text-sm font-medium xpmd:text-left">
            Your referral link has been shared to the community’s contact person
          </p>

          <div className="h-16 md:h-[120px] lg:h-[200px]" />
          <p className="font-semibold xl:text-2xl mb-6">
            It’s easy to get started
          </p>
          <ul className="space-y-5 text-grey-700 text-sm lg:text-base xl:text-xl">
            <li className="flex gap-2 items-center">
              <div className="h-4 w-4 rounded-full center bg-light-green-500">
                <RiCheckFill className="text-sm" />
              </div>
              <p>Spread the word with your link or on email</p>
            </li>
            <li className="flex gap-2 items-center">
              <div className="h-4 w-4 rounded-full center bg-light-green-500">
                <RiCheckFill className="text-sm" />
              </div>
              <p>They click and onboard their community through your link</p>
            </li>
            <li className="flex gap-2 items-center">
              <div className="h-4 w-4 rounded-full center bg-light-green-500">
                <RiCheckFill className="text-sm" />
              </div>
              <p>When they join, you get 10% as reward</p>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default ReferCommunityForm;
