import {
  Broadcast,
  ChartLine,
  Coins,
  HardDrives,
  Lightbulb,
  Money,
} from "phosphor-react";
import React from "react";
import { RiBardFill } from "react-icons/ri";

const IntelligentUtility = () => {
  return (
    <>
      <section className="container mx-auto px-4 mt-5 flex flex-col items-center justify-center md:w-[75%] xl:mt-[170px]">
        <div className="inline-flex  items-center bg-light-purple-100 gap-2.5 px-5 py-2 rounded-full mx-auto justify-center xl:py-[15px] xl:px-[30px]">
          <RiBardFill className="text-light-purple-600 text-2xl md:block hidden" />
          <p className="font-inter text-xs md:text-lg text-green-950 font-medium">
            Venco Smart Meters
          </p>
        </div>
        <p className="font-sora text-center text-[30px] leading-[40px] font-semibold my-4 xl:my-5 xl:text-5xl xl:leading-[60px]">
          Intelligent Utility Management
        </p>
        <p className="text-sm leading-[22px] text-center font-inter xl:text-xl xl:leading-[30px] max-w-[1024px]">
          Our smart meters help communities to reduce energy and water waste.
          With the Venco app, communities can collect payments upfront and
          reduce errors and time spent by facility managers in allocating and
          analysing utility costs.
        </p>
      </section>
      <section
        className="mt-8 xl:mt-[70px] lg:container lg:mx-auto lg:p-4 flex flex-wrap gap-[30px] justify-center p-4"
        data-aos="fade-up"
        data-aos-delay="20"
      >
        {[
          {
            title: "Remote Communications",
            desc: " Monitor consumption and check energy & water usage from anywherewithout needing physical access to your meter",
            icon: <Broadcast weight="fill" className="text-[40px]" />,
          },
          {
            title: "Zero Vending Server Costs",
            desc: "Save millions by using our free vending server at no additional cost when you subscribe to Venco.",
            icon: <HardDrives weight="fill" className="text-[40px]" />,
          },
          {
            title: "Utility Statistics at Your Fingertips",
            desc: "Our utilities monitoring dashboard provides data on all aspects of your community’s energy (and other utilities) usage.",
            icon: <ChartLine weight="fill" className="text-[40px]" />,
          },
          {
            title: "Revenue Assurance",
            desc: "Guarantee collections by linking ability to vend with being up-to-date with communal fees.",
            icon: <Coins weight="fill" className="text-[40px]" />,
          },
          {
            title: "Better Decision Making",
            desc: "Make informed decisions about utility pricing and supply based on usage statistics captured on our meters and software.",
            icon: <Lightbulb weight="fill" className="text-[40px]" />,
          },
          {
            title: "Improve Cashflow From Collections",
            desc: "Don’t struggle to raise cash to pay communal utility bills. Let occupants pay in advance for utilities with our prepaid utility meters and vending app.",
            icon: <Money weight="fill" className="text-[40px]" />,
          },
        ].map((item, index) => {
          return (
            <div class="bg-light-purple-50 p-7 rounded-xl md:p-10 xl:p-[50px] md:max-w-[340px] 2xl:max-w-[400px]">
              <div class="bg-light-purple-100 text-light-purple-500 rounded-full w-[120px] h-[120px] center innerShadow">
                {item.icon}
              </div>
              <div className="h-[60px]"></div>
              <div className="space-y-7">
                <p className="font-sora text-[22px] font-semibold">
                  {item.title}
                </p>
                <p className="font-inter leading-[150%]">{item.desc}</p>
              </div>
            </div>
          );
        })}
      </section>
    </>
  );
};

export default IntelligentUtility;
