import { MagnifyingGlass } from "phosphor-react";
import React, { useState } from "react";
import { faqQuestions } from "./FAQList";
import { FAQComponent } from "./FAQ";

const FAQHero = () => {
  const [openIndex, setOpenIndex] = useState(-1);
  const handleItemClick = (index) => {
    if (index === openIndex) {
      // Clicked on an already open item, so close it.
      setOpenIndex(-1);
    } else {
      // Clicked on a new item, so open it and close the previously open one.
      setOpenIndex(index);
    }
  };

  const [search, setSearch] = useState("");

  return (
    <>
      <section className="w-full max-h-[605px] h-[40vh] bg-grey-50 center xl:h-[420px] ">
        <div className="container mx-auto px-4">
          <p className="font-sora text-green-500 font-semibold pt-2.5 md:pt-5  text-[26px] leading-[32px] md:text-4xl lg:text-5xl xl:text-6xl text-center">
            Frequently Asked Questions
          </p>

          <div className="h-4 lg:h-6"></div>

          <div className="rounded-full border border-grey-400 py-2 px-5 flex items-center gap-2.5 w-full md:w-[445px] focus-within:border-light-green-600 mx-auto lg:py-5 lg:px-[30px]">
            <MagnifyingGlass className="text-grey-400 h-[14px]" />
            <input
              type="search"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              className="flex-1 placeholder:text-grey-400 focus:outline-none bg-transparent"
              placeholder="Search for Keywords"
            />
          </div>
        </div>
      </section>

      <section className="container mx-auto xl:max-w-[1218px] p-4 my-[70px] xl:mt-[100px] xl:my-[170px]">
        <div className="space-y-4">
          {faqQuestions
            .filter((item) => {
              return item.question.includes(search);
            })
            .map((item, index) => {
              return (
                <FAQComponent
                  key={index}
                  question={item.question}
                  answer={item.answer}
                  isOpen={index === openIndex}
                  onClick={() => handleItemClick(index)}
                />
              );
            })}
        </div>
      </section>
    </>
  );
};

export default FAQHero;
