import { configureStore } from "@reduxjs/toolkit";
import blogReducer from "./features/blogSlice";
import countryReducer from "./features/countrySlice";


const store = configureStore({
    reducer: {
        blogs: blogReducer,
        geoDetails: countryReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }).concat()
})


export default store;