
import React from "react";
import hero from "../../assets/images/aboutHero.webp";

const AboutHero = () => {
  return (
    <section className=" w-full">
      <div className="container mx-auto px-4 mt-[70px] xl:mt-[150px]">
        <p className="font-sora text-green-500 font-semibold pt-2.5 md:pt-5  text-[26px] leading-[32px] md:text-4xl lg:text-5xl xl:text-6xl text-center">
          Welcome to Venco
        </p>

        <div className="h-4 lg:h-6"></div>
        <p className=" w-4/5 md:w-2/3 text-center  text-sm leading-[22px] md:text-base lg:text-xl  md:leading-[28px] mx-auto font-inter max-w-[926px] xl:leading-[32px]">
          Your partner in transforming living experiences in residential and
          commercial communities across Africa. At Venco, we're dedicated to
          simplifying property management through our cutting-edge technology
          platform.
        </p>

       
        <div className="h-[85px]" />
        <div className="md:w-full md:h-auto rounded-[20px] lg:max-h-[930px] h-[480px] flex justify-center items-center overflow-hidden">
          <img
            src={hero}
            alt=""
            className="h-full max-w-none md:h-auto md:w-full"
          />
        </div>
      </div>
    </section>
  );
};

export default AboutHero;
