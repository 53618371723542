import React from "react";

import downloadCTABg from "../../assets/svg/downloadCTABg.svg";
import appIcon from "../../assets/images/appIcon.svg";
import downloadCTA from "../../assets/downloadCTAImage.png";

const DownloadCTA = () => {
  return (
    <div className="bg-black relative my-16 xl:my-[100px]  overflow-hidden ">
      <section className="container mx-auto   relative z-10">
        <div className="flex flex-col md:flex-row ">
          <div className="p-5 py-10 xl:px-[100px] xl:py-[100px] flex flex-col justify-center lg:w-[60%]">
            <p className="font-sora font-semibold text-2xl text-white text-center md:text-left lg:text-[36px] lg:leading-[48px] xl:text-[48px] xl:leading-[70px]">
              Download the Venco App for Residents Now to Join your Community
            </p>
            <div className="h-5 lg:h-10"></div>
            <a
              target="_blank"
              alt="download-app-button"
              rel="noreferrer"
              href="https://vnco.to/mobile"
              className="primaryButton flex center gap-4 w-full md:w-fit px-8 "
            >
              <img src={appIcon} alt="Download" className="w-[60px]" />
              <p className="">Download App</p>
            </a>
          </div>
          <div className="w-full lg:w-[40%] xl:h-full xl:pt-[100px]">
            <img src={downloadCTA} alt="" className="" />
          </div>
        </div>
      </section>
      <div className="absolute left-1/2 -translate-x-1/2 -bottom-40 xl:bottom-auto xl:top-2/3 xl:-translate-y-1/2 xl:-translate-x-0 xl:left-auto xl:-right-1/2 w-full">
        <img
          src={downloadCTABg}
          alt=""
          className="scale-[200%] rotate-45 md:rotate-[30deg] md:scale-100 xl:scale-[80%] "
        />
      </div>
    </div>
  );
};

export default DownloadCTA;
