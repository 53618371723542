import React from "react";
// import NavBar from "../components/NAVBAR/NavBar";
import NavBarDark from "../components/NAVBAR/NavBarDark";
import FAQHero from "../components/FAQ/FAQHero";
import JoinTheBest from "../components/HOMEPAGE/JoinTheBest";
import Footer from "../components/FOOTER/Footer";
import { Helmet } from "react-helmet";

const FAQPage = () => {
  return (
    <>
      <Helmet>
        <title>FAQ - Venco | All-in-one Community Management Software</title>
        <meta
          property="og:title"
          content="FAQ - Venco | All-in-one Community Management Software"
        />
        <meta
          name="twitter:title"
          content="FAQ - Venco | All-in-one Community Management Software"
        />
      </Helmet>
      <NavBarDark />
      {/* <NavBar /> */}
      <FAQHero />
      <JoinTheBest />
      <Footer />
    </>
  );
};

export default FAQPage;
