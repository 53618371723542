import React from "react";
import { RiBardFill } from "react-icons/ri";
import featuresShape from "../../assets/images/features.svg";
import featuresShape2 from "../../assets/images/features2.svg";
import featuresShape3 from "../../assets/images/features3.svg";
import featuresShape4 from "../../assets/images/features4.svg";
import Visitor from "../../assets/images/VistorManagement.svg";
import Community from "../../assets/images/CommunityManagement.svg";
import SmartMeter from "../../assets/images/SmartUtility.svg";
import Payment from "../../assets/images/SimplifiedPayment.svg";
import { ArrowRight } from "phosphor-react";
import { useNavigate } from "react-router-dom";

const KeyFeatures = () => {
  const navigate = useNavigate();
  return (
    <>
      <section className="container mx-auto px-4 mt-10 flex flex-col items-center justify-center md:w-[75%] xl:mt-[170px]">
        <div className="inline-flex  items-center bg-light-green-150 gap-2.5 px-5 py-2 rounded-full mx-auto justify-center xl:py-[15px] xl:px-[30px]">
          <RiBardFill className="text-light-green-700 text-2xl md:block hidden" />
          <p className="font-inter text-xs md:text-xl text-new-black-1000 font-medium">
            Discover our key features
          </p>
        </div>
        <p className="font-sora text-center text-[30px] leading-[40px] font-semibold my-4 xl:my-5 xl:text-4xl xl:leading-[60px]">
          Simplifying Living Experiences Across Africa
        </p>
        <p className="text-sm leading-[22px] text-center font-inter xl:text-xl xl:leading-[30px] max-w-[1024px]">
          We elevate residential community management with our software!
          Simplifying operations and enhancing access to communal services for
          owners and occupants.
        </p>
      </section>

      {/* Visitor Management */}
      <section
        className="mt-8 xl:mt-[70px] lg:container lg:mx-auto lg:p-4"
        data-aos="fade-up"
        data-aos-delay="20"
      >
        <div className=" md:flex-row keyFeatures">
          <div className=" flex items-center justify-center relative p-10 overflow-hidden md:w-1/2 lg:w-[45%] ">
            <div className="absolute bottom-0 lg:top-0 left-0 z-0 w-full ">
              <img
                src={featuresShape}
                alt=""
                className="w-full -my-[1px] relative xl:-left-[1px]"
              />
            </div>
            <img src={Visitor} alt="" className="relative z-1 lg:scale-75" />
          </div>

          <div className="py-20 px-4 center md:w-1/2 lg:w-[55%] lg:p-10">
            <div className="flex flex-col gap-[30px] items-center justify-center md:items-start">
              <p className="text-center text-2xl font-sora font-semibold text-new-black-1000 lg:text-3xl">
                Visitor Management
              </p>
              <p className="font-inter text-sm leading-[22px] text-center text-[#4D4D4D] md:text-left lg:text-base xl:text-[20px] xl:leading-[32px] md:w-[300px] lg:w-[400px] xl:w-[505px]">
                Venco's system uses secure codes and virtual IDs from their apps
                for visitor management, along with integration for popular
                access control devices.
              </p>
              <div
                className="flex items-center gap-2.5 cursor-pointer group"
                onClick={() => {
                  navigate("/products/residents");
                }}
              >
                <p className="transition-all duration-500 group-hover:underline underline-offset-4 text-[#4D4D4D]">
                  Learn More
                </p>
                <div className="rounded-full p-2 bg-light-green-500 center group-hover:animate-[bounceForward_1s_infinite]">
                  <ArrowRight className="h-4" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Community Management */}
      <section
        className="mt-[30px] xl:mt-[30px] lg:container lg:mx-auto lg:p-4"
        data-aos="fade-up"
        data-aos-delay="20"
      >
        <div className="keyFeatures bg-blue-50">
          <div className=" flex items-center justify-center relative p-4 overflow-hidden md:w-1/2 lg:w-[45%] ">
            <div className="absolute bottom-0 lg:top-0 left-0 z-0 w-full ">
              <img
                src={featuresShape2}
                alt=""
                className="w-full -my-[1px] relative xl:-right-[1px] lg:-scale-x-100"
              />
            </div>
            <img src={Community} alt="" className="relative z-1 scale-75" />
          </div>

          <div className="py-20 px-4 center md:w-1/2 lg:w-[55%] lg:p-10">
            <div className="flex flex-col gap-[30px] items-center justify-center md:items-start">
              <p className="text-center text-2xl font-sora font-semibold text-new-black-1000 lg:text-3xl">
                Community Management
              </p>
              <p className="font-inter text-sm leading-[22px] text-center text-[#4D4D4D] md:text-left lg:text-base xl:text-[20px] xl:leading-[32px] md:w-[300px] lg:w-[400px] xl:w-[505px]">
                Integrated property database, issue reporting and management,
                amenities booking, financial reports as well as messaging and
                bulk communication tools are just a few of the tools Venco
                provides to Facilities Managers and Residents’ Associations.
              </p>
              <div className="flex items-center gap-2.5 cursor-pointer group">
                <p
                  className="group-hover:underline underline-offset-4 text-[#4D4D4D]"
                  onClick={() => {
                    navigate("/products/admin");
                  }}
                >
                  Learn More
                </p>
                <div className="rounded-full p-2 bg-light-green-500 center group-hover:animate-[bounceForward_1s_infinite]">
                  <ArrowRight className="h-4" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Smart Utility Vending */}
      <section
        className="mt-8 xl:mt-[70px] lg:container lg:mx-auto lg:p-4"
        data-aos="fade-up"
        data-aos-delay="20"
      >
        <div className="keyFeatures bg-light-green-100 md:flex-row">
          <div className=" flex items-center justify-center relative p-10 overflow-hidden md:w-1/2 lg:w-[45%] lg:py-14 xl:p-10">
            <div className="absolute bottom-0 lg:top-0 left-0 z-0 w-full">
              <img
                src={featuresShape3}
                alt=""
                className="w-full -my-[1px] relative xl:-left-[1px]"
              />
            </div>
            <img src={SmartMeter} alt="" className="relative z-1 " />
          </div>

          <div className="py-20 px-4 center md:w-1/2  lg:w-[55%] lg:p-10">
            <div className="flex flex-col gap-[30px] items-center justify-center md:items-start">
              <p className="text-center text-2xl font-sora font-semibold text-new-black-1000 lg:text-3xl">
                Smart Utility Vending
              </p>
              <p className="font-inter text-sm leading-[22px] text-center text-[#4D4D4D] md:text-left lg:text-base xl:text-[20px] xl:leading-[32px] md:w-[300px] lg:w-[400px] xl:w-[505px]">
                Venco simplifies community management for multi-unit properties,
                providing easy-to-use applications for property owners and
                occupants to access communal services.
              </p>
              <button
                className="flex items-center gap-2.5 cursor-pointer group"
                onClick={() => {
                  navigate("/products/meters");
                }}
              >
                <p className="group-hover:underline underline-offset-4 transition-all duration-500 text-[#4D4D4D]">
                  Learn More
                </p>
                <div className="rounded-full p-2 bg-light-green-500 center group-hover:animate-[bounceForward_1s_infinite]">
                  <ArrowRight className="h-4" />
                </div>
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* Simplified Payment */}
      <section
        className="mt-[30px] xl:mt-[30px] container mx-auto lg:p-4"
        data-aos="fade-up"
        data-aos-delay="20"
      >
        <div className=" keyFeatures bg-orange-50">
          <div className=" flex items-center justify-center relative px-4 py-14 md:p-4 overflow-hidden md:w-1/2 lg:w-[45%] lg:py-14 xl:p-10">
            <div className="absolute bottom-0 lg:top-0  z-0 w-full ">
              <img
                src={featuresShape4}
                alt=""
                className="w-full h-full md:h-auto -my-[1px] xl:-right-[1px] relative lg:-scale-x-100 "
              />
            </div>
            <img
              src={Payment}
              alt=""
              className="relative z-1 lg:scale-75 xl:scale-[85%]"
            />
          </div>

          <div className="py-20 px-4 center md:w-1/2  lg:w-[55%] lg:p-10">
            <div className="flex flex-col gap-[30px] items-center justify-center md:items-start">
              <p className="text-center text-2xl font-sora font-semibold text-new-black-1000 lg:text-3xl">
                Simplified Payments
              </p>
              <p className="font-inter text-sm leading-[22px] text-center text-[#4D4D4D] md:text-left lg:text-base xl:text-[20px] xl:leading-[32px] md:w-[300px] lg:w-[400px] xl:w-[505px]">
                Automated billing, collections and reconciliation functionality
                on our apps means that late or no-payment of communal dues are a
                thing of the past. We’ve built in revenue assurance features
                that reduce defaults in payments.
              </p>
              <button
                className="flex items-center gap-2.5 cursor-pointer group "
                onClick={() => {
                  navigate("/products/residents");
                }}
              >
                <p className="transition-all duration-500 group-hover:underline underline-offset-4 text-[#4D4D4D]">
                  Learn More
                </p>
                <div className="rounded-full p-2 bg-light-green-500 center group-hover:animate-[bounceForward_1s_infinite]">
                  <ArrowRight className="h-4" />
                </div>
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default KeyFeatures;
