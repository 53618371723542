import { CaretRight } from "phosphor-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";

export const BlogIndividual = ({
  featuredImage,
  title,
  description,
  slug,
  date,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className="space-y-8 max-w-[450px] lg:p-6 lg:border lg:border-grey-150 rounded-lg cursor-pointer group"
      onClick={() => {
        navigate(`/blog/${slug}`);
      }}
    >
      <div className="h-[250px] overflow-hidden rounded-[6.5px] relative w-full">
        <img
          src={featuredImage}
          alt=""
          className="object-cover w-full h-full absolute inset-0 "
          //   style={{ height: "100%",  objectFit: "contain" }}
        />
      </div>
      <div className="">
        <div className="flex justify-between mt-5 items-center md:mt-0">
          <p className="px-5 py-2.5 bg-grey-50 rounded-full font-sora text-xs text-new-black-400 font-medium">
            Estate Management
          </p>
          <p className="font-inter text-sm text-new-grey-500">{date}</p>
        </div>
        <div className="space-y-3 md:space-y-2.5 mt-4">
          <p
            className="font-sora font-semibold text-new-black-950 text-2xl"
            dangerouslySetInnerHTML={{ __html: title }}
          ></p>

          <div
            className="text-sm leading-[22px] font-inter text-new-grey-500"
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
          <div className="w-fit flex gap-1.5 items-center text-new-grey-500">
            <p className="underline underline-offset-4  font-medium text-sm">
              Read the article
            </p>
            <CaretRight className="h-[18px] group-hover:translate-x-1 duration-300 transition-all" />
          </div>
        </div>

        <div className="h-[1px] w-full bg-grey-200 my-[50px] md:hidden"></div>
      </div>
    </div>
  );
};

const BlogList = ({ blogList }) => {
  return (
    <>
      <section className="container mx-auto p-4 md:py-10 xl:py-24">
        <div className="flex flex-wrap gap-[42px] min-h-screen ">
          {blogList &&
            blogList.slice(1).map((blog, index) => {
              const blogDate = format(Date.parse(blog.date), "MMM dd, yyyy");

              return (
                <BlogIndividual
                  featuredImage={blog.jetpack_featured_media_url}
                  title={blog.title.rendered}
                  description={blog.excerpt.rendered}
                  date={blogDate}
                  slug={blog.slug}
                  key={index}
                />
              );
            })}
        </div>
      </section>
    </>
  );
};

export default BlogList;
