import React from "react";

import JoinTheBest from "../components/HOMEPAGE/JoinTheBest";
import Footer from "../components/FOOTER/Footer";
import LatestBlog from "../components/BLOG/LatestBlog";
import BlogHero from "../components/BLOG/BlogHero";
// import NavBar from "../components/NAVBAR/NavBar";
import NavBarDark from "../components/NAVBAR/NavBarDark";
import BlogList from "../components/BLOG/BlogList";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

const BLOGPages = () => {
  const blogList = useSelector((state) => state.blogs.data);
  return (
    <>
      <Helmet>
        <title>Blog - Venco | All-in-one Community Management Software</title>
        <meta
          property="og:title"
          content="Blog - Venco | All-in-one Community Management Software"
        />
        <meta
          name="twitter:title"
          content="Blog - Venco | All-in-one Community Management Software"
        />
      </Helmet>
      <NavBarDark />
      {/* <NavBar /> */}
      <BlogHero />
      <LatestBlog blogList={blogList} />
      <BlogList blogList={blogList} />
      <JoinTheBest />
      <Footer />
    </>
  );
};

export default BLOGPages;
