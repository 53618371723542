import React from "react";
import { Link } from "react-router-dom";

const SignUpButton = () => {
  return (
    <Link
      to="/get-started"
      className="py-4 px-10 text-green-500 bg-light-green-500 hover:bg-light-green-600 rounded-lg font-semibold duration-300 transition-all text-sm md:text-base xl:text-lg text-center flex center gap-1.5 "
    >
      Get Started{" "}
    </Link>
  );
};

export default SignUpButton;
