import { AnimatePresence } from "framer-motion";
import { CaretDown, CaretUp } from "phosphor-react";
import React, { useEffect, useRef, useState } from "react";
import {
  AcceptableUseRestrictions,
  // ApplicableLaw,
  ApplicationServices,
  Assignment,
  CancellationOfProfile,
  EntireAgreement,
  ForceMajeure,
  GrantOfLicense,
  Indemnity,
  LimitationOfLiability,
  NoPartnership,
  PaymentTerms,
  ProcessingOfPersonalData,
  ProfessionalService,
  Severance,
  VencoProfile,
  YourConsent,
  YourObligations,
} from "./TermsContents";
import { useSelector } from "react-redux";

let contentList = [
  {
    title: "1. Your Consent",
    component: <YourConsent />,
    id: "YourConsentTerms",
  },
  {
    title: "2. Application Services",
    component: <ApplicationServices />,
    id: "ApplicationServices",
  },
  {
    title: "3. Venco Profile",
    component: <VencoProfile />,
    id: "VencoProfile",
  },
  {
    title: "4. Grant of License",
    component: <GrantOfLicense />,
    id: "GrantOfLicense",
  },
  {
    title: "5. Payment Terms and Taxes",
    component: <PaymentTerms />,
    id: "PaymentTerms",
  },
  {
    title: "6. Acceptable Use Restrictions",
    component: <AcceptableUseRestrictions />,
    id: "AcceptableUseRestrictions",
  },
  {
    title: "7. Your Obligations",
    component: <YourObligations />,
    id: "YourObligations",
  },
  {
    title: "8. Professional Service",
    component: <ProfessionalService />,
    id: "ProfessionalService",
  },
  {
    title: "9. Processing Of Personal Data",
    component: <ProcessingOfPersonalData />,
    id: "ProcessingOfPersonalData",
  },
  {
    title: "10. Closing, Restriction & Cancellation of Profile",
    component: <CancellationOfProfile />,
    id: "CancellationOfProfile",
  },
  {
    title: "11. Limitation of Liability",
    component: <LimitationOfLiability />,
    id: "LimitationOfLiability",
  },
  {
    title: "12. Indemnity",
    component: <Indemnity />,
    id: "Indemnity",
  },
  {
    title: "13. Entire Agreement",
    component: <EntireAgreement />,
    id: "EntireAgreement",
  },
  {
    title: "14. Force Majeure",
    component: <ForceMajeure />,
    id: "ForceMajeure",
  },
  {
    title: "15. Assignment",
    component: <Assignment />,
    id: "Assignment",
  },
  {
    title: "16. Severance",
    component: <Severance />,
    id: "Severance",
  },
  {
    title: "17. No Partnership or Agency",
    component: <NoPartnership />,
    id: "NoPartnership",
  },
  // {
  //   title: "18. Applicable Law and Dispute Resolution",
  //   component: <ApplicableLaw />,
  //   id: "ApplicableLaw",
  // },
];

const TermsBody = () => {
  const [contentDropdown, setContentDropdown] = useState(false);
  const [currentSection, setCurrentSection] = useState({ ...contentList[0] });

  let sectionRefs = useRef([]);
  sectionRefs.current = contentList.map(
    (ref, index) => (sectionRefs.current[index] = React.createRef())
  );

  // Intersection Observer callback
  const handleIntersection = (entries) => {
    entries.forEach((entry, index) => {
      if (entry.isIntersecting) {
        let currentSection = contentList.find(
          (item) => item.id === entry.target.id
        );
        setCurrentSection(currentSection);
        // Do something with the in-view section, e.g., highlight it
      }
    });
  };

  // Initialize Intersection Observer
  useEffect(() => {
    const options = {
      root: null, // viewport
      rootMargin: "0px",
      threshold: 0.2, // Trigger when at least 50% of the section is in the viewport
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    sectionRefs.current.forEach((ref, index) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    // Cleanup
    return () => {
      sectionRefs.current.forEach((ref, index) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, []);



  const presentCountry = useSelector((state) => state.geoDetails.country);
  let phone;
  switch (presentCountry) {
    case "Nigeria":
      phone = (
        <>
          Phone:
          <a
            href="tel:+2349131055072"
            target="_blank"
            rel="noopener noreferrer"
            className="underline underline-offset-4"
          >
            {" "}
            +234 913 105 5071.
          </a>
        </>
      );
      break;
    case "Ghana":
      phone = (
        <>
          Phone:
          <a
            href="tel:+233257115559"
            target="_blank"
            rel="noopener noreferrer"
            className="underline underline-offset-4"
          >
            {" "}
            +233 25 711 5559
          </a>
        </>
      );
      break;
    case "Kenya":
      phone = (
        <>
          Phone:
          <a
            href="tel:+254757436731"
            target="_blank"
            rel="noopener noreferrer"
            className="underline underline-offset-4"
          >
            {" "}
            +254 757 436 731.
          </a>
        </>
      );
      break;
    default:
      phone = "";
      break;
  }


  return (
    <>
      <div className="container mx-auto xl:py-[96px]">
        <div className="bg-[#F8F8F7] sticky lg:hidden top-[10vh] p-4 text-green-500  ">
          <div
            className="flex items-center justify-between gap-4 cursor-pointer"
            onClick={() => {
              setContentDropdown(!contentDropdown);
            }}
          >
            <p className="font-medium flex-1 text-sm">{currentSection.title}</p>
            {contentDropdown ? (
              <CaretUp weight="bold" />
            ) : (
              <CaretDown weight="bold" />
            )}
          </div>
          <AnimatePresence initial="false">
            {contentDropdown ? (
              <div className="space-y-2  max-h-[250px] overflow-y-scroll absolute top-full bg-white left-0 p-4 rounded-b-xl lift">
                {contentList.map((item, index) => {
                  return (
                    <p
                      href={`#${item.id}`}
                      className={`py-2 px-4 rounded-full text-sm font-inter block ${
                        item.title === currentSection.title
                          ? "bg-light-green-500 font-semibold"
                          : ""
                      }`}
                      onClick={(e) => {
                        setContentDropdown(!contentDropdown);
                        setCurrentSection(item);
                        const targetId = e.target
                          .getAttribute("href")
                          .substring(1);
                        const targetElement = document.getElementById(targetId);

                        if (targetElement) {
                          const offset =
                            targetElement.getBoundingClientRect().top +
                            window.scrollY -
                            window.innerHeight / 5;
                          window.scrollTo({
                            top: offset,
                            behavior: "smooth",
                          });
                        }
                      }}
                    >
                      {item.title}
                    </p>
                  );
                })}
              </div>
            ) : null}
          </AnimatePresence>
        </div>

        <div className="flex gap-10">
          <div className="space-y-2.5  w-[30%] max-w-[422px] self-start sticky top-[12vh] overflow-y-scroll  bg-white left-0 p-4 rounded-b-xl h-[85vh] hidden lg:block">
            {contentList.map((item, index) => {
              return (
                <p
                  href={`#${item.id}`}
                  className={`py-4 px-[30px] rounded-full text-sm font-inter block ${
                    item.title === currentSection.title
                      ? "bg-light-green-500 font-semibold"
                      : "bg-grey-50"
                  } cursor-pointer font-medium text-green-500`}
                  onClick={(e) => {
                    setContentDropdown(!contentDropdown);
                    setCurrentSection(item);
                    const targetId = e.target.getAttribute("href").substring(1);
                    const targetElement = document.getElementById(targetId);

                    if (targetElement) {
                      const offset =
                        targetElement.getBoundingClientRect().top +
                        window.scrollY -
                        window.innerHeight / 5;
                      window.scrollTo({
                        top: offset,
                        behavior: "smooth",
                      });
                    }
                  }}
                >
                  {item.title}
                </p>
              );
            })}
          </div>

          <div className="space-y-6 lg:space-y-9 p-4 scroll-smooth flex-1 ">
            {contentList.map((item, index) => {
              return (
                <div
                  ref={sectionRefs.current[index]}
                  className=""
                  id={item.id}
                  key={index}
                >
                  {item.component}
                </div>
              );
            })}
            <p className="text-black text-sm leading-[26px] md:text-base lg:text-lg font-normal font-inter lg:leading-[30px]">
              For general enquiries regarding this Privacy Statement, or to make
              a request or complain about how We process Personal Data, You may
              contact Us at: 
              <br />
              Email:
              <a
                href="mailto:info@venco.africa"
                target="_blank"
                rel="noopener noreferrer"
                className="underline underline-offset-4"
              >
                {" "}
                info@venco.africa
              </a>{" "}
              <br />
              {phone}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsBody;
