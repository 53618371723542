import React, { useEffect } from "react";
import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
const HowItWorksMobile = ({
  data,
  scrollContainerProp,
  scrollIndicatorProp,
}) => {
  const setupScrollTrigger = () => {
    // Get the scroll container and scroll indicator elements
    const scrollContainer = document.getElementById(`${scrollContainerProp}`);
    const scrollIndicator = document.getElementById(`${scrollIndicatorProp}`);

    // Set up the ScrollTrigger animation
    gsap
      .timeline({
        scrollTrigger: {
          trigger: scrollContainer,
          start: "top center",
          end: "bottom top",
          // scrub: true,
        },
      })
      .to(scrollIndicator, { height: "100%", ease: "circ.out", delay: 3 });
  };

  useEffect(() => {
    setupScrollTrigger();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="md:hidden">
      <div className="flex gap-6">
        <div
          className="bg-[#EAECF0] w-[3px] overflow-y-hidden"
          id={scrollContainerProp}
        >
          <div
            className="w-[2px] bg-light-green-500"
            id={scrollIndicatorProp}
          />
        </div>
        <div>
          {data.map((e, i) => {
            return (
              <>
                <div>
                  <div className="rounded-full bg-light-green-500 flex p-2 pr-6 gap-3 center w-fit">
                    <div className="w-8 h-8 center rounded-full bg-white">
                      <p className="text-xs">{i + 1}</p>
                    </div>
                    <p className="text-sm font-sora text-green-500 font-medium">
                      {e.label}
                    </p>
                  </div>
                  <div className="h-6"></div>
                  <ol type="a" className="list-[lower-alpha] ml-8">
                    {e.bodyContent.map((content, index) => {
                      return <li key={index}>{content}</li>;
                    })}
                  </ol>
                  <div className="h-6"></div>
                  <div className="bg-green-500 rounded-[12px] overflow-clip lg:rounded-[20px]">
                    <img
                      src={data[i]?.image}
                      alt=""
                      srcset=""
                      className="w-full"
                    />
                  </div>
                </div>

                {i < data.length - 1 && <div className="h-10"></div>}
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default HowItWorksMobile;
