
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';


// Define the API endpoint URL

// Create the asynchronous thunk for fetching the wallet data
export const fetchBlogs = createAsyncThunk(
    'blogs/fetchBlog',
    async (_, { rejectWithValue }) => {
        try {
            const response = await await axios.get(
                "https://01101960.xyz/index.php/wp-json/wp/v2/posts"
            );
            return response.data;
        } catch (error) {
            // Handle any errors and return with rejectWithValue
            console.log(error)
            return rejectWithValue(error.response.data);
        }
    }
);

// Create the Bank List slice
const blogs = createSlice({
    name: 'Blog List',
    initialState: {
        data: null,
        loading: false,
        error: null,
        status: 'idle', // 'idle', 'pending', 'fulfilled', 'failed'
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchBlogs.pending, (state) => {
                state.loading = true;
                state.status = 'pending';
                state.error = null;
            })
            .addCase(fetchBlogs.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
                state.status = 'fulfilled';
                state.error = null;
            })
            .addCase(fetchBlogs.rejected, (state, action) => {
                state.loading = false;
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

// Export the wallet slice actions and reducer

export default blogs.reducer;
