import { AnimatePresence } from "framer-motion";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import HOME from "./pages/HOME";
import ResidentsPage from "./pages/RESIDENTSPage";

import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";

import ADMIN from "./pages/ADMIN";
import SECURITYPage from "./pages/SECURITYPage";
import SmartMeterPage from "./pages/SmartMeterPage";
import BLOGPages from "./pages/BLOGPages";
import FAQPage from "./pages/FAQPage";
import ABOUTPage from "./pages/ABOUTPage";
import PrivacyPage from "./pages/PrivacyPage";
import TermsPage from "./pages/TermsPage";
import Cookies from "./components/Cookies";
import BlogIndividualPage from "./pages/BlogIndividualPage";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchBlogs } from "./app/features/blogSlice";
import ContactPage from "./pages/ContactPage";
import { fetchCountry } from "./app/features/countrySlice";
import PageNotFound from "./pages/PageNotFound";
// import FloatingDownloadButton from "./components/BUTTONS/FloatingDownloadButton";
import GetStartedPage from "./pages/GetStartedPage";
import SwitchPage from "./pages/SwitchPage";
import ResidentsHowItWorks from "./pages/HowItWorksPages/ResidentsHowItWorks";
import Referrer from "./pages/Referrer";
import ReferralFormPage from "./pages/ReferralFormPage";
import OnboardingPage from "./pages/OnboardingPage";
import ReferCommunityPage from "./pages/ReferCommunityPage";
import AdminHowItWorks from "./pages/HowItWorksPages/AdminHowItWorks";
import SecurityHowItWorks from "./pages/HowItWorksPages/SecurityHowItWorks";
import MobileUpdatePage from "./pages/mobile-updates/VisitorsBooking.jsx";
import BillsAndUtilityPage from "./pages/mobile-updates/BillsandUtility.jsx";
import IssuesReportingPage from "./pages/mobile-updates/IssuesReporting.jsx";
import AmenitiesBookingPage from "./pages/mobile-updates/AmenitiesBooking.jsx";
import EmergencyReportingPage from "./pages/mobile-updates/EmergencyReporting.jsx";
import DashboardPage from "./pages/mobile-updates/DashboardPage.jsx";
import VerifyEmailPage from "./pages/VerifyEmailPage.jsx";
import VirtualIDPage from "./pages/mobile-updates/VirtualIDPage.jsx";
import NFCPage from "./pages/mobile-updates/NFCPage.jsx";
import NoticesPage from "./pages/mobile-updates/NoticesPage.jsx";
// import SwitchRedirect from "./pages/SwitchRedirect.jsx";
const RemoveTrailingSlash = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Remove trailing slash if it exists and there are path segments
    if (location.pathname !== '/' && location.pathname.endsWith('/')) {
      // Remove the trailing slash
      const newPath = location.pathname.slice(0, -1);
      // Redirect to the new path without a trailing slash
      navigate(newPath, { replace: true });
    }
  }, [location, navigate]);

  return null;
};
function App() {
  const location = useLocation();
  const navigate = useNavigate();

  AOS.init({
    // Global settings:
    disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
    initClassName: "aos-init", // class applied after initialization
    animatedClassName: "aos-animate", // class applied on animation
    useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
    disableMutationObserver: false, // disables automatic mutations' detections (advanced)
    debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
    throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    offset: 120, // offset (in px) from the original trigger point
    delay: 0, // values from 0 to 3000, with step 50ms
    duration: 700, // values from 0 to 3000, with step 50ms
    easing: "ease", // default easing for AOS animations
    once: false, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
    anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
  });

  // Parse the query parameters
  const searchParams = new URLSearchParams(location.search);
  // Get the value of the 'track_user' parameter
  const trackUser = searchParams.get('track_user');

  const cookieAccepted = localStorage.getItem("cookieAccepted");
  const dispatch = useDispatch();
  const blogStatus = useSelector((state) => state.blogs.status);
  const geoDetailsStatus = useSelector((state) => state.geoDetails.status);

  // getBlogs
  useEffect(() => {
    if (blogStatus === "idle") {
      dispatch(fetchBlogs());
    }
    // eslint-disable-next-line
  }, [blogStatus]);

  // getCountry
  useEffect(() => {
    if (geoDetailsStatus === "idle") {
      dispatch(fetchCountry());
    }
    // eslint-disable-next-line
  }, [geoDetailsStatus]);

  // useEffect(() => {
  //   if (location.pathname.endsWith('/') && location.pathname !== '/') {
  //     window.history.replaceState(null, '', location.pathname.slice(0, -1) + location.search);
  //   }
  // }, [location]);

  // useEffect(() => {
  //   // Remove trailing slash if it exists and there are path segments
  //   if (location.pathname !== '/' && location.pathname.endsWith('/')) {
  //     // Remove the trailing slash
  //     const newPath = location.pathname.slice(0, -1);
  //     // Redirect to the new path without a trailing slash
  //     navigate(newPath, { replace: true });
  //   }
  // }, [location, navigate]);
  

  // useEffect(() => {
  //   // Split the pathname by the slash and check each segment
  //   const pathSegments = location.pathname.split('/').filter(Boolean);
    
  //   // If any segment ends with a slash, remove the trailing slash
  //   if (pathSegments.length > 1 && location.pathname.endsWith('/')) {
  //     // Create the new path by removing the trailing slash
  //     const newPath = location.pathname.slice(0, -1);
      
  //     // Replace the current URL with the new path without the trailing slash
  //     navigate(newPath, { replace: true });
  //   }
  // }, [location, navigate]);



  return (
    <>
      {/* {!cookieAccepted && <Cookies />} */}
      {(trackUser != '0' && !cookieAccepted)  && <Cookies />}
      <div className="fixed top-[12vh] z-[1000]">
        <ToastContainer />
        {/* <FloatingDownloadButton /> */}
      </div>
      <RemoveTrailingSlash />
      {
        <AnimatePresence mode="wait">
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<HOME />} />
            <Route
              path="/visitors-booking-is-disabled"
              element={<MobileUpdatePage />}
            />
            <Route
              path="/emergency-reporting-is-disabled"
              element={<EmergencyReportingPage />}
            />
            <Route
              path="/amenities-booking-is-disabled"
              element={<AmenitiesBookingPage />}
            />
            <Route
              path="/issues-reporting-is-disabled"
              element={<IssuesReportingPage />}
            />
            <Route path="/dashboard-is-disabled" element={<DashboardPage />} />
            <Route path="/virtual-id-is-disabled" element={<VirtualIDPage />} />
            <Route path="/nfc-is-disabled" element={<NFCPage />} />
            <Route path="/notices-is-disabled" element={<NoticesPage />} />
            <Route
              path="/bills-and-utility-is-disabled"
              element={<BillsAndUtilityPage />}
            />
            <Route path="/residents" element={<ResidentsPage />} />
            <Route path="/admin" element={<ADMIN />} />
            <Route path="/security" element={<SECURITYPage />} />
            <Route path="/meters" element={<SmartMeterPage />} />
            <Route path="/blog" element={<BLOGPages />} />
            <Route path="/faq" element={<FAQPage />} />
            <Route path="/about-us" element={<ABOUTPage />} />
            <Route path="/contact-us" element={<ContactPage />} />
            <Route path="/privacy-policy" element={<PrivacyPage />} />
            <Route path="/terms-and-condition" element={<TermsPage />} />
            <Route path="/get-started" element={<GetStartedPage />} />
            <Route path="/switch" element={<SwitchPage />} />
            {/* <Route path="/switch" element={<Navigate to="/get-started" replace />} />  */}
            <Route
              path="/how-it-works-for-residents"
              element={<ResidentsHowItWorks />}
            />
            <Route
              path="/how-it-works-for-admins"
              element={<AdminHowItWorks />}
            />
            <Route
              path="/how-it-works-for-security"
              element={<SecurityHowItWorks />}
            />
            <Route
              path="/verify-email/:tmp_id" 
              element={<VerifyEmailPage />}
            />
            <Route path="/referrer" element={<Referrer />} />
            <Route path="/refer-community" element={<ReferCommunityPage />} />
            <Route path="/signup-referral" element={<ReferralFormPage />} />

            <Route path="/onboard" element={<OnboardingPage />} />
            <Route path="/blog/:slug" element={<BlogIndividualPage />} />

            {/* 404 Page */}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </AnimatePresence>
      }
    </>
  );
}

export default App;
