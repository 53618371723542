import { CaretLeft, CaretRight } from "phosphor-react";
import React, { useState } from "react";
import { RiBardFill } from "react-icons/ri";
import earls from "../../assets/images/testimonial/earls_main.webp";
import taf from "../../assets/images/testimonial/taf.webp";
import beachway from "../../assets/images/testimonial/beachway_logo.webp";
import { AnimatePresence } from "framer-motion";

const TestimonialList = [
  {
    testimonial:
      "I no longer need to deal with management as much because I use the Venco app to book visitors, pay my power and service charge, and report any difficulties. Yetunde discusses her experience as a resident with Venco and some of her favourite App features",
    name: "- Yetunde",
    location: "Resident, Prime Water Gardens 2",
    type: "video",
    image: "",
    video: (
      <iframe
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/4qRmkKkG0Hc"
        title="Yetunde Shares Her Venco Experience - #RealPeopleRealStories"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    ),
  },
  {
    testimonial:
      "The Venco Resident App was introduced in my estate recently and I have enjoyed using the App for instant energy purchases, booking visitors, getting instant information on my estate, and so much more!",
    name: "- Elizabeth",
    location: "EXCO Member, Earls Court",
    type: "image",
    image: earls,
    video: "",
  },

  {
    testimonial:
      "The Venco Admin App has simplified my work process by allowing me to manage my facility from my mobile phone wherever I am, whenever I want.",
    name: "- Joel",
    location: "Facility Manager, Rivtaf Golf Estate",
    type: "image",
    image: taf,
    video: "",
  },
  {
    testimonial:
      "Still manually reconciling bills and payments in your estate? Be like Loveth and Olatunji and switch to the easy side of life with Venco!",
    name: "- Facility Managers",
    location: "New Horizon One Estate",
    type: "video",
    image: "",
    video: (
      <iframe
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/cwUt168MMh0"
        title="#VENCOStories: How We Use Venco at Horizon Estate"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    ),
  },
  {
    testimonial:
      "The Venco software is presently deployed across all our estates. It has been a true game changer as tasks are coordinated seamlessly, making the job of managing multiple estates quite easy and straightforward. They are reasonably priced and I confidently endorse their services.",
    name: "- Yinka",
    location: "Managing Director, Beachway Homes",
    type: "image",
    image: beachway,
    video: "",
  },
];

const IndividualTestimony = ({
  showOverlay,
  testimonial,
  name,
  location,
  type,
  image,
  video,
  current,
}) => {
  return (
    <div className="rounded-[20px] bg-light-green-100 border border-light-green-600  md:flex md:flex-row-reverse  xl:justify-start xl:gap-[155px] xl:max-w-[1528px] xl:p-14 mx-auto p-4 ">
      <div
        className={`rounded-[10px] overflow-hidden aspect-square  center md:w-1/2 xl:w-[575px] ${
          type === "image" ? "bg-white" : "bg-green-500"
        }`}
        onClick={() => {
          type === "video" && showOverlay(current);
        }}
      >
        {type === "image" && <img src={image} alt="" className="w-4/5" />}
        {type === "video" && (
          <div className="pointer-events-none w-full h-full">{video}</div>
        )}
      </div>
      <div className="mt-10 md:mt-0 md:w-1/2  md:p-10 xl:w-fit center flex-col items-start lg:items-center">
        <div className="max-w-[548px]">
          <p className="text-center md:text-left font-sora text-green-500 text-sm leading-[27px] lg:text-base lg:leading-[32px] xl:text-[24px] xl:leading-[42px]  ">
            {testimonial}
          </p>
          <div className="h-[1px] w-full bg-light-green-700 my-[30px]"></div>

          <div className="text-center md:text-left pb-6">
            <p className="text-teal-950 text-sm xl:text-lg font-bold leading-normal font-sora">
              {name}
            </p>
            <span className="text-teal-950 text-sm xl:text-lg font-medium leading-normal font-italic font-inter">
              {location}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const Testimonial = () => {
  const [toggle, setToggle] = useState(false);
  const [overlay, setOverlay] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const showOverlay = () => {
    // setCurrentImage(image);
    setToggle(!toggle);
    setOverlay(!overlay);
  };

  const closeOverlay = () => {
    setToggle(!toggle);
    setOverlay(!overlay);
  };

  const add = () => {
    if (currentImage === TestimonialList.length - 1) {
      setCurrentImage(0);
      return;
    }
    setCurrentImage(currentImage + 1);
  };

  const subtract = () => {
    if (currentImage === 0) {
      setCurrentImage(TestimonialList.length - 1);
      return;
    }
    setCurrentImage(currentImage - 1);
  };

  return (
    <section className="bg-white w-full py-10 lg:py-16 xl:py-[170px] p-4">
      <div className="p-4 lg:container lg:mx-auto  ">
        <div className="md:w-[80%] items-center justify-center flex flex-col mx-auto">
          <div className="inline-flex items-center bg-light-green-150 gap-2.5 px-5 py-2 rounded-full mx-auto justify-center xl:py-[15px] xl:px-[30px]">
            <RiBardFill className="text-light-green-700 text-2xl md:block hidden" />
            <p className="font-inter text-xs md:text-xl text-new-black-1000 font-medium">
              Customer Testimonials
            </p>
          </div>
          <p className="font-sora text-center text-[30px] leading-[40px] font-semibold my-4 xl:my-5 xl:text-5xl xl:leading-[60px]">
            Hear what our clients have to say about Venco.
          </p>
        </div>
      </div>
      <div className="relative container mx-auto p-2 md:p-8 w-full">
        <div
          className="w-10 h-10 md:w-16 md:h-16 rounded-full center bg-light-green-500 opacity-90 absolute top-1/2 -translate-y-1/2 -left-1 cursor-pointer"
          onClick={() => {
            subtract();
          }}
        >
          <CaretLeft className="text-lg md:text-2xl" />
        </div>
        <div
          className="w-10 h-10 md:w-16 md:h-16 rounded-full center bg-light-green-500 opacity-90  absolute top-1/2 -translate-y-1/2  -right-1 cursor-pointer"
          onClick={() => {
            add();
          }}
        >
          <CaretRight className="text-lg md:text-2xl" />
        </div>
        <AnimatePresence>
          {
            <IndividualTestimony
              showOverlay={showOverlay}
              testimonial={TestimonialList[currentImage].testimonial}
              name={TestimonialList[currentImage].name}
              location={TestimonialList[currentImage].location}
              type={TestimonialList[currentImage].type}
              image={TestimonialList[currentImage].image}
              video={TestimonialList[currentImage].video}
              current={TestimonialList[currentImage]}
            />
          }
        </AnimatePresence>
      </div>
      {toggle && (
        <div
          className="fixed top-0 bottom-0 left-0 z-[1000] w-full h-full bg-[#0d2d0e8e]"
          id="overlay1"
          onClick={(e) => {
            if (e.target.id === "overlay1") {
              closeOverlay();
            }
          }}
        >
          <div
            className="container mx-auto h-full"
            id="overlay2"
            onClick={(e) => {
              if (e.target.id === "overlay2") {
                closeOverlay();
              }
            }}
          >
            <div
              className="flex flex-col items-center justify-center w-full h-full"
              id="overlay3"
              onClick={(e) => {
                if (e.target.id === "overlay3") {
                  closeOverlay();
                }
              }}
            >
              <div
                className="w-full flex flex-col lg:flex-row justify-center items-center h-[65vh] lg:h-[80vh]  gap-12 lg:gap-20"
                id="overlay4"
                onClick={(e) => {
                  if (e.target.id === "overlay4") {
                    closeOverlay();
                  }
                }}
              >
                <div
                  className="
                  md:h-[60%] lg:h-[70%] lg:w-[60%] overflow-hidden flex items-center justify-center aspect-video
                "
                >
                  {TestimonialList[currentImage].video}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Testimonial;
