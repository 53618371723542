import React from "react";
import techcabal from "../../assets/images/logos/techcabal.png";
import businessday from "../../assets/images/logos/businessday.png";
import disrupt from "../../assets/images/logos/disrupt.png";
import techpoint from "../../assets/images/logos/techpoint.png";

const Logos = () => {
  return (
    <section className="container mx-auto mb-10 xl:y-[170px]">
      <p className="font-sora text-[#4D4D4D] text-center xl:text-xl">Also featured in </p>
      <div className=" center mx-auto xl:gap-24 max-w-[1124]  lg:h-[60px] mt-10  md:flex-row md:flex-wrap md:gap-10 gap-8 flex-col">
        <a
          href="https://techcabal.com/2022/12/05/venco-raises-a-pre-seed-round-to-help-manage-african-properties/?utm_source=dlvr.it&utm_medium=linkedin"
          target="_blank"
          rel="noopener noreferrer"
          className="h-10 lg:h-full block"
        >
          <img
            src={techcabal}
            alt=""
            className="h-10 lg:h-full"
            data-aos="fade-up"
            data-aos-delay="50"
          />
        </a>
        <a
          href="https://businessday.ng/technology/article/venco-secures-670000-to-scale-embedded-finance-for-multi-property-developers/"
          target="_blank"
          rel="noopener noreferrer"
          className="h-10 lg:h-full block"
        >
          <img
            src={businessday}
            alt=""
            className="h-10 lg:h-full"
            data-aos="fade-up"
            data-aos-delay="100"
          />
        </a>
        <a
          href="https://disrupt-africa.com/2023/02/01/how-nigerias-venco-is-simplifying-estate-management-for-managers/"
          target="_blank"
          rel="noopener noreferrer"
          className="h-10 lg:h-full block"
        >
          <img
            src={disrupt}
            alt=""
            className="h-10 lg:h-full"
            data-aos="fade-up"
            data-aos-delay="150"
          />
        </a>

        <a
          href="https://techpoint.africa/2022/12/05/venco-raises-670k-pre-seed/"
          target="_blank"
          rel="noopener noreferrer"
          className="h-10 lg:h-full block"
        >
          <img
            src={techpoint}
            alt=""
            className="h-full"
            data-aos="fade-up"
            data-aos-delay="200"
          />
        </a>
      </div>
    </section>
  );
};

export default Logos;
