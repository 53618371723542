import React from "react";

import { Helmet } from "react-helmet";
import EmergencyReportingImage from "../../assets/svg/EmergencyReporting.svg";
import Footer from "../../components/FOOTER/Footer";
import JoinTheBest from "../../components/HOMEPAGE/JoinTheBest";

const EmergencyReportingPage = () => {
  return (
    <>
      <Helmet>
        <title>
          Updates - Venco | All-in-one Community Management Software
        </title>
        <meta
          property="og:title"
          content="Updates - Venco | All-in-one Community Management Software"
        />
        <meta
          name="twitter:title"
          content="Updates - Venco | All-in-one Community Management Software"
        />
      </Helmet>

      <section className=" w-full bg-white p-1 relative overflow-hidden">
        <div className="container mx-auto px-4 md:px-8 my-10 xl:my-[20px] lg:pb-11 relative z-[1] ">
          <div className="space-y-8 sm:max-w-[300px] md:max-w-full lg:max-w-full lg:p-5  cursor-pointer group">
            <div className="space-y-3 md:space-y-2.5 mt-4">
              <p className="font-sor font-semibold text-new-black-950 capitalize text-2xl">
                Emergency reporting is not enabled in your community{" "}
              </p>

              <div className="flex  mt-5  md:mt-0">
                <div className="px-2 py-2.5 flex items-center space-x-3  font-sora text-xs text-new-black-400 font-medium">
                  <div className="w-2 h-2 bg-green-200 rounded-full"> </div>
                  <div>2 min read</div>
                </div>
              </div>

              <div className="h-[1px] w-full md:hidden bg-grey-200 my-[50px] "></div>

              <div className="md:min-h-[150px] overflow-hidden w-full rounded-[6.5px] relative">
                <img
                  src={EmergencyReportingImage}
                  alt=""
                  className="object-contain w-full h-auto max-h-[200px] md:max-h-[500px]"
                />
              </div>
            </div>
            <div className="">
              <div className="space-y-1 md:space-y-4 mt-0 md:mt-4">
                <div className="text-sm  space-y-5 leading-[22px] font-inter text-new-grey-500">
                  <p className=" md:text-[16px] text-[14px] font-inter">
                    Is there an emergency happening within your community? You
                    can alert the right authority from your Venco app and also
                    alert your community administrators of the emergency so they
                    can also be aware of it too.
                  </p>
                  <p className=" md:text-[16px] text-[14px] font-inter">
                    Contact your Facility Manager or Join a community to enable
                    Emergency reporting for your community today!
                  </p>

                  <p className="font-bold text-[14px] text-black capitalize">
                    How to join a community
                  </p>
                  <ul className="list-disc">
                    <li className="ml-4">Click on the “Join Now” button.</li>
                    <li className="ml-4">
                      Search and select your community by typing the name of
                      your estate and house number.
                    </li>
                    <li className="ml-4">Click the "Join Now" button.</li>
                  </ul>
                  <p className="font-bold text-[14px] capitalize text-black">
                    How to report emergencies:{" "}
                  </p>
                  <ul className="list-disc">
                    <li className="ml-4">
                      Slide the help button from left to right to get help and a
                      countdown from 5 begins.
                    </li>
                    <li className="ml-4">
                      Once it’s done a message is sent to the listed emergency
                      contact(s) and the App displays a message. ”Your emergency
                      will be attended to ASAP”
                    </li>
                  </ul>
                  <p className="font-bold text-[14px] capitalize text-black">
                    Benefits of Emergency Reporting
                  </p>
                  <ul className="list-disc">
                    <li className="ml-4">
                      You can get a direct response to emergencies within your
                      estate.
                    </li>
                    <li className="ml-4">
                      Get the help of local authorities in the area.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <JoinTheBest />
      <Footer />
    </>
  );
};

export default EmergencyReportingPage;
