import React from "react";
import newHero from "../../assets/images/newHero.webp";
import mobHero from "../../assets/images/adminHero.webp";
import SignUpButton from "../BUTTONS/SignUpButton";
import ViewDemoButton from "../BUTTONS/ViewDemoButton";


const HeroVariation = () => {
  const headerTexts = [
    "Residential Community",
    "Gated Community",
    "Gated Street",
    "Neighborhood",
    "Apartment Blocks",
    "Office Building",
    "Shopping Complex",
    "Commercial Center",
  ];

  return (
    <section className=" w-full bg-green-500 xl:min-h-[90vh] ">
      <div className="container mx-auto px-4 pb-14 md:px-10 lg:px-4 flex flex-col lg:flex-row lg:justify-between pt-[70px] md:py-[96px] md:pb-[120px]">
        <div className="lg:w-1/2 flex flex-col justify-center">
          <p className="font-sora text-white font-semibold  text-[22px] md:text-4xl lg:text-[40px] xl:text-[50px] 2xl:text-6xl text-center lg:text-left">
            Manage your
          </p>
          <div className="h-[2px] md:h-2 lg:h-4"></div>
          <div className="flex gap-6 justify-center lg:justify-start">
            <div className="h-[28px] md:h-[39px] lg:h-[48px] xl:h-[55px] 2xl:h-[68px] overflow-hidden">
              <div className="animateText md:space-y-1 xl:space-y-[14px]">
                {headerTexts.map((item, i) => {
                  return (
                    <p className="font-sora text-light-green-500 font-semibold text-[22px] md:text-4xl lg:text-[40px] xl:text-[50px] 2xl:text-6xl text-center scrollText pb-[4px] lg:text-left">
                      {item}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="h-[2px] md:h-2 lg:h-4"></div>
          <p className="font-sora text-white font-semibold  text-[22px] md:text-4xl lg:text-[40px] xl:text-[50px] 2xl:text-6xl text-center lg:text-left">
            Easily and Efficiently
          </p>

          <div className="h-4 md:h-4 lg:h-6 xl:h-8"></div>
          <p className=" w-4/5 md:w-2/3 lg:w-3/4 text-center lg:text-left  text-sm leading-[22px] md:text-base lg:text-xl  md:leading-[28px] mx-auto lg:mx-0 font-inter max-w-[653px] xl:leading-[32px] text-white">
            We simplify living experiences across Africa with our all-in-one
            community management solution.
          </p>

          <div className="h-5 md:h-6 lg:h-10"></div>
          <div className="flex justify-center lg:justify-start gap-4 flex-col md:flex-row">
            <SignUpButton />
            <ViewDemoButton />
          </div>
        </div>

        <div className="h-[40px]" />
        <div className="md:w-full md:h-auto rounded-[20px] lg:max-h-[930px]  flex justify-center items-center overflow-hidden lg:w-[45%]">
          <img
            src={newHero}
            alt=""
            className="hidden md:block h-full max-w-none md:h-auto md:w-full"
          />
          <img
            src={mobHero}
            alt=""
            className="md:hidden w-full max-w-none md:h-auto md:w-full"
          />
        </div>
      </div>
    </section>
  );
};

export default HeroVariation;
