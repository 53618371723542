import React from "react";
// import NavBar from "../components/NAVBAR/NavBar";
import NavBarDark from "../components/NAVBAR/NavBarDark";
import Footer from "../components/FOOTER/Footer";
import JoinTheBest from "../components/HOMEPAGE/JoinTheBest";
import TermsHero from "../components/TERMS/TermsHero";
import TermsBody from "../components/TERMS/TermsBody";
import { Helmet } from "react-helmet";

const TermsPage = () => {
  return (
    <>
      <Helmet>
        <title>
          Terms and Conditions - Venco | All-in-one Community Management
          Software
        </title>
        <meta
          property="og:title"
          content="Terms and Conditions - Venco | All-in-one Community Management Software"
        />
        <meta
          name="twitter:title"
          content="Terms and Conditions - Venco | All-in-one Community Management Software"
        />
      </Helmet>
      <NavBarDark />
      {/* <NavBar /> */}
      <TermsHero />
      <TermsBody />
      <JoinTheBest />
      <Footer />
    </>
  );
};

export default TermsPage;
