import React from "react";
import { Helmet } from "react-helmet";
import NavBarDark from "../components/NAVBAR/NavBarDark";
import Footer from "../components/FOOTER/Footer";
import referralHero from "../assets/images/referralHero.webp";
import { RiGiftFill, RiMegaphoneFill } from "react-icons/ri";
import referral from "../assets/images/referral.webp";
import ReferrerCTA from "../components/REFERRER/ReferrerCTA";
import { Link } from "react-router-dom";

const Referrer = () => {
  return (
    <>
      <Helmet>
        <title>
          Get Started - Venco | All-in-one Community Management Software
        </title>
        <meta
          property="og:title"
          content="Get Started - Venco | All-in-one Community Management Software"
        />
        <meta
          name="twitter:title"
          content="Get Started - Venco | All-in-one Community Management Software"
        />
      </Helmet>
      <NavBarDark />
      <section className="container mx-auto my-10 xl:py-[100px] p-4">
        <p className="font-sora font-semibold text-[26px] md:text-3xl lg:text-5xl text-center leading-[39px] md:leading-10 lg:leading-[72px] xl:max-w-[927px] xl:mx-auto">
          Introduce Your Community to the Future of Estate Management!
        </p>
        <div className="h-[30px]" />
        <div className="center">
          <Link
            to="/signup-referral"
            className="primaryButton w-full md:px-14 md:w-fit "
          >
            Sign Up as a Referrer
          </Link>
        </div>
        <div className="h-10"></div>
        <div className="overflow-hidden center rounded-[7px] h-[310px] md:h-fit ">
          <img src={referralHero} alt="" className="h-full object-cover " />
        </div>
        <div className="h-10"></div>
        <div className="h-10"></div>
        <div className="hidden lg:block h-10"></div>
        <div className="hidden lg:block h-10"></div>
        <div className="hidden lg:block h-10"></div>

        <div className="flex flex-col md:flex-row gap-6 lg:gap-10 max-w-[1350px] xl:mx-auto">
          <div className="md:w-1/2 lg:w-[45%] xl:w-1/2">
            <p className="font-sora font-semibold text-[26px] md:text-3xl lg:text-4xl xl:text-5xl leading-[39px] md:leading-10 lg:leading-[56px] xl:leading-[72px]">
              How the Venco Referral Program Works
            </p>
            <div className="h-6 xl:h-10"></div>
            <div className="flex gap-6">
              <div className="w-[3px] bg-light-green-500 md:hidden" />
              <div>
                <div>
                  <div class="flex gap-5 xl:gap-10 items-center">
                    <RiMegaphoneFill className="text-light-purple-500 text-2xl xl:text-3xl" />
                    <p className="text-sm font-bold font-sora lg:text-xl xl:text-2xl">
                      Promote Venco App
                    </p>
                  </div>
                  <div className="h-2.5 xl:h-4"></div>
                  <p className="text-sm font-inter leading-[24px] text-[#4D4D4D] lg:text-base lg:leading-[28px] xl:text-xl xl:leading-[34px] max-w-[600px]">
                    Sign up as a referrer and receive a unique referral link.
                    Share the link with your community or estate and invite them
                    to try us. You can also refer a community using the “Get
                    Started” Button below
                  </p>
                </div>
                <div className="h-6 xl:h-10"></div>
                <div>
                  <div class="flex gap-5 xl:gap-10 items-center">
                    <RiGiftFill className="text-light-purple-500 text-2xl xl:text-3xl" />
                    <p className="text-sm font-bold font-sora lg:text-xl xl:text-2xl">
                      Get your rewards!
                    </p>
                  </div>
                  <div className="h-2.5 xl:h-4"></div>
                  <p className="text-sm font-inter leading-[24px] text-[#4D4D4D] lg:text-base lg:leading-[28px] xl:text-xl xl:leading-[34px] max-w-[600px]">
                    When your referrals sign up and onboard themselves to a paid
                    plan
                  </p>
                </div>
                <div className="h-6"></div>
                <div className="overflow-hidden  rounded-[7px] h-[350px] md:h-fit w-full md:hidden">
                  <img src={referral} alt="" className="w-full object-cover " />
                </div>
              </div>
            </div>
          </div>
          <div className="rounded-[7px] flex-1 hidden md:inline-block">
            <img src={referral} alt="" className="w-full object-cover " />
          </div>
        </div>
      </section>
      <ReferrerCTA />

      <Footer />
    </>
  );
};

export default Referrer;
