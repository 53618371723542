export const Introduction = () => {
  return (
    <section className="">
      <div className="textHeader ">1. Introduction</div>
      <br />
      <div>
        <p className="textBody">
          Venco, Inc. and it's subsidiaries Venco Platforms Ltd collectively known as Venco are dedicated to protecting the
          confidentiality and privacy of personally identifiable information
          entrusted to Us (“Personal Data”). As part of that fundamental
          obligation, We are committed to protecting the use of the Personal
          Data which We collect on{" "}
          <a
            href="/"
            target="_blank"
            rel="noopener noreferrer"
            className="underline underline-offset-4"
          >
            {" "}
            https://venco.africa
          </a>{" "}
          and Venco application software (the “App”).
          <br />
          <br />
          <span>
            The App allows users access a variety of application services and
            provide services which include collection of utilities,
            communications management, visitor management and check-ins
            (“Services”) to residents of residential estates. This privacy
            policy (“Privacy Statement”) sets out the basis upon which We will
            process, store, and disclose any Personal Data that is provided
            voluntarily by users of the App (“Data Subject” or “you” or “your”)
            in connection with the Services that We offer.
            <br />
            <br />
            1.1. We note that the relevant data privacy regulations
            distinguishes between entities that are “controllers” of Personal
            Data and entities that “process” Personal Data. We note that We are
            a “Data Controller” where We determine the purposes for and the
            manner in which Personal Data is processed or is to be processed and
            We are a “Processor” for those Services where We only use Personal
            Data which are requested by other Data Controllers.
            <br />
            <br />
            1.2. Where We make decisions on how Personal Data is used in
            connection with Our Services (where We are acting as a controller),
            We are responsible for the obligations of a “Controller” under the
            relevant data protection regulations in connection with the
            processing of Personal Data – for example, We use this Privacy
            Statement and other notices to provide you with information about
            Our use of personal information, as required by the relevant data
            protection regulations. Where We only use Personal Data requested by
            other Data Controllers (where We are acting as a processor), those
            other Data Controllers are similarly responsible for the obligations
            of a “Controller” under applicable laws in connection with the
            processing of personal information, and, if you are using Our
            Services through those other Controllers, you should contact them if
            you have questions or concerns about the processing of your personal
            information or compliance with the relevant data protection
            regulations and other applicable law
            <br />
            <br />
            1.3. This Privacy Statement may change from time to time. Unless
            otherwise stated, any updates to this Privacy Statement become
            effective when We post the updates on Our website and App. Your
            continued use of the App for Our Services following an update to the
            Privacy Statement means that you accept the updated Privacy
            Statement.
          </span>
        </p>
      </div>
    </section>
  );
};

export const YourConsent = () => {
  return (
    <section className="">
      <div className="textHeader ">2. Your Consent</div>
      <br />
      <div>
        <p className="textBody">
          2.1. Please read this Privacy Statement very carefully. If you do not
          wish to be bound by the provisions of this Privacy Statement, you
          should not access the App or sign up for Our Services. By using the
          App, and by signing up for Our Services, you hereby confirm that you
          have read, understand, and agree to be bound by the Privacy Statement.{" "}
          <br />
          <br />
          2.2. Additionally, please note that the option to sign up on the App
          is intended solely for persons who are 18 (Eighteen) years of age and
          above, and any registration by, use of or access to the Services by
          any person under 18 (Eighteen) is unauthorized and in violation of
          this Privacy Statement. Venco does not knowingly collect Personal Data
          from children under the age of 18 (Eighteen). We therefore also ask
          you, if you are under the age of 18 (Eighteen), please do not send us
          or submit your Personal Data.
          <br />
          <br />
          2.3. By Clicking “Accept” at the bottom of the page, you hereby
          confirm that:
          <ul className="list-disc list-outside pl-4">
            <li>
              you have read, understand, and agree to be bound by the Privacy
              Statement and you hereby expressly consent to the processing of
              Personal Data for the purpose of accessing the Services;
            </li>
            <li>
              you hereby give Us consent to the collection and processing of
              your Personal Data for the purpose of providing the Services;
            </li>
            <li>
              you hereby certify that all the information provided by you are
              true and correct and that any misrepresentations or falsity,
              whether intentional or not, therein will be considered as an act
              to defraud Us and Our partners;
            </li>
            <li>
              you authorize Us to verify and investigate the above
              statements/information as may be required, from the references
              provided and other reasonable sources; and
            </li>
            <li>
              For this purpose, you hereby waive your rights on the
              confidentiality of client information and expressly consent to the
              processing of any Personal Data and records relating to you that
              might be obtained from third parties, including government
              agencies, your employer, business associates, and other entities.
            </li>
          </ul>
        </p>
      </div>
    </section>
  );
};

export const YourPrivacyRight = () => {
  return (
    <section className="">
      <div className="textHeader ">3. Your Privacy Rights</div>
      <br />
      <div>
        <p className="textBody">
          3.1. This Privacy Statement describes your privacy rights regarding
          Our collection, use, storage, sharing and protection of your Personal
          Data. It applies to Our website, App and all other related
          applications, services, tools and physical contact with Us regardless
          of how you access or use them.
          <br />
          <br />
          3.2. You have the following rights:
          <ul className="list-disc list-outside pl-4">
            <li>
              right to request from Us access to and rectification or erasure of
              your Personal Data;
            </li>
            <li>
              right to restrict processing or object to processing of your
              Personal Data;
            </li>
            <li>right to data portability;</li>
            <li>
              right to withdraw consent at any time, without affecting the
              lawfulness of processing based on consent before its withdrawal;
            </li>
            <li>right to lodge a complaint with the relevant authority.</li>
          </ul>
          <br />
          <br />
          3.3. In the event that you wish to: (i) access your Personal Data
          processed by Us in accordance with this Privacy Statement; (ii) ask
          any questions at all regarding Our use of your Personal Data; (iii)
          rectify your Personal Data processed by Us in accordance with this
          Privacy Statement; (iv) restrict or object to processing of your
          Personal Data; (v) withdraw your consent to Our processing of your
          Personal Data; or (vi) request for data portability, please reach out
          to Us at
          <br />
          <br />
          3.4. We carefully analyze what types of Personal Data We need to
          provide Our Services, and We try to limit the Personal Data We collect
          to only what We really need.
          <br />
          <br />
          3.5. Where possible, We delete or anonymize Personal Data when We no
          longer need it. We will retain your Personal Data for the duration of
          Our Services to you and afterwards for as long as is necessary and
          relevant for purposes permitted by applicable laws and regulations.
          Where We no longer need your Personal Data, We will dispose of it in a
          secure manner.
          <br />
          <br />
          3.6. If you have created a username, identification code, password or
          any other piece of information as part of Our access security
          measures, you must treat such information as confidential, and you
          must not disclose it to any third party. We reserve the right to
          disable any user identification code or password, whether chosen by
          you or allocated by Us, at any time, if in Our opinion you have failed
          to comply with any of the provisions of these conditions. If you know
          or suspect that anyone other than you know or has access to your
          security information, you must promptly notify Us at{" "}
          <a
            href="mailto:info@venco.africa"
            target="_blank"
            rel="noopener noreferrer"
            className="underline underline-offset-4"
          >
            {" "}
            info@venco.africa
          </a>{" "}
        </p>
      </div>
    </section>
  );
};

export const KindOfPersonalData = () => {
  return (
    <section className="">
      <div className="textHeader">
        4. What Kind Of Personal Data Do We Collect?
      </div>
      <br />
      <div>
        <p className="textBody">
          4.1. This section details the Personal Data We may collect about you.
          We explain why, and how, We use it in later sections. If you decide to
          create an account or enquire about one of Our products or Services,
          We’ll ask You to provide Us with some specific information, for
          example: Basic personal details – such as name, surnames, passport
          photograph, age, gender and date of birth; Personal contact details –
          such as home address, home phone number, mobile phone number, and
          email address; Work details – such as job title, department, company
          name, company address; Log in details – such as username and password;
          Usage Information and Browsing History – such as usage metrics
          (including usage rates, occurrences of technical errors, diagnostic
          reports, settings preferences, content and advertising interactions;
          Banking details –account number. We may also collect additional
          information involving Your opinion of Our products and Services and
          Your preferences regarding other services such as newsletter
          subscription, and any other information required to provide the
          Services to you.
          <br />
          <br />
          4.2. Information about your computer and mobile device hardware and
          software may be automatically collected by Us. This information can
          include: your IP address and location, browser and device name, type
          and version, operating system type and version, language preferences,
          domain names, the information you search for on the Services, access
          times and dates, referring website addresses and other statistics.
          This information is used for the operation of the Service, to maintain
          quality of the Service, and to provide general statistics regarding
          use of the App.
          <br />
          <br />
          4.3. Please keep in mind that if you directly disclose Personal Data
          through Venco public message boards, this information may be collected
          and used by third parties.
          <br />
          <br />
          4.4. Our website and App may contain links to other resources not
          owned or controlled by Us and as such, We encourage you to review the
          privacy statements of websites you choose to link from Our website and
          App so that you can understand how those websites collect, use and
          share your information. Please bear in mind that Venco is not
          responsible and will not be liable for the privacy practices or other
          content of such other resources or third parties.
        </p>
      </div>
    </section>
  );
};
export const SourceOfPersonalData = () => {
  return (
    <section className="">
      <div className="textHeader ">
        5. What is the Source of Personal Data We Collect?
      </div>
      <br />
      <div>
        <p className="textBody">
          5.1. We may collect Personal Data about you either directly from You,
          from certain third parties (such as your employer or the subscriber
          providing access to Our Services), or from other sources (such as
          publicly available sources) when you:
          <ul className="list-disc list-outside pl-4">
            <li>Search for, subscribe to Our Services.</li>
            <li>Use Our Services or otherwise interact with Us.</li>
            <li>Create or maintain a profile or account with Us.</li>
            <li>
              Purchase, use, or otherwise interact with content, products, or
              services from third party providers who have a relationship with
              Us.
            </li>
            <li>Create, post, or submit user content on Our Services.</li>
            <li>Register for or attend one of Our events or locations.</li>
            <li>Request or sign up for information.</li>
            <li>
              Communicate with Us by phone, email, chat, in person, or
              otherwise.
            </li>
            <li>
              Complete a questionnaire, survey, support ticket, or other
              information request form.
            </li>
            <li>
              When you express an interest in working with Us or apply for a job
              or position with Us.
            </li>
          </ul>
          <br />
          <br />
          5.2. In some circumstances, We may collect Personal Data about you
          from Our partners or from publicly available websites to help Us
          better understand Our audience and enhance the relevance of Our
          content. We collect Personal Data about you from third parties, such
          as:
          <ul className="list-disc list-outside pl-4">
            <li>
              Your organization and others with whom you have a relationship
              that provide or publish Personal Data related to you, such as from
              Our customers when they arrange access to Our Services for you or
              from others when they create, post, or submit user content on Our
              Services that may include your Personal Data.
            </li>
            <li>
              Professional or industry organizations and certification/licensure
              agencies that provide or publish Personal Data related to you.
            </li>
            <li>
              Third parties and affiliates who resell or integrate with Our
              Services.
            </li>
            <li>
              Service providers and business partners who work with Us in
              relation to Our Services and that We may utilize to deliver
              certain content, products, or services or to enhance your
              experience.
            </li>
            <li>
              Marketing, sales generation, and recruiting business partners.
            </li>
            <li>
              Government agencies and others who release or publish public
              records.
            </li>
            <li>
              Other publicly or generally available sources, such as social
              media sites, public and online websites, open databases, the
              corporate affairs commission, and data in the public domain.
            </li>
          </ul>
          <br />
          <br />
          5.3. We use cookies (and similar technologies) and analytics tools to
          collect information about You. This information can be used to improve
          the performance of the site, make advertising more relevant and
          enhance your user experience, such as Your location – We use cookies
          to display information that is most relevant to your location. Your
          usage – We use cookies to understand how Our customers use Our App and
          interact with Our communications. For example, We use technology on
          Our App, which records user movements, including page scrolling,
          clicks and text entered. (It does not record payment details). This
          helps Us to identify usability issues and improve the assistance We
          can provide to users and is also used for aggregated and statistical
          reporting purposes. Your device – We use cookies to understand what
          type of device you are using to show you the best version of the site.
          For instance, if you visit Our website on a mobile device, Our
          technology will ensure that you see a version that is optimized for
          mobile viewing and interaction. Your engagement with advertisements –
          We use cookies to understand what advertisements you have been shown,
          or clicked on, to bill Our advertising partners, and to present you
          with advertisements that are more relevant to you.
          <br />
          <br />
          5.4. Cookies are small files placed on your computer’s hard drive that
          enables the website and App to identify your computer as you view
          different pages. Cookies allow websites and applications to store your
          preferences in order to present contents, options or functions that
          are specific to you. Like most interactive websites, Our Website uses
          cookies to enable the tracking of your activity for the duration of a
          session.
        </p>
      </div>
    </section>
  );
};

export const LawfulBasis = () => {
  return (
    <section className="">
      <div className="textHeader ">6. Lawful Basis for Processing</div>
      <br />
      <div>
        <p className="textBody">
          6.1. We only process Personal Data where We have a lawful basis for
          doing so, such as the following:
          <br />
          <br />
          6.1.1. User consent – This is where you have given Us explicit
          permission to process Personal Data for a given purpose. For example,
          if you create a profile on Our App, We may additionally ask you to
          consent to Us processing your Personal Data.
          <br />
          <br />
          6.1.2. Contractual necessity – This is where We have to process
          Personal Data to meet Our contractual obligations.
          <br />
          <br />
          6.1.3. Legal obligation – This is where We have to process Personal
          Data in order to comply with the law.
          <br />
          <br />
          6.1.4. Protection of vital interests – This is where We are
          constrained to process your Personal Data in order to protect your
          interests or those of another person, such as to prevent identity
          theft.
          <br />
          <br />
          6.1.5. Public interest – This is where there is an overriding public
          interest or We have been vested with an official public mandate to
          take certain action in the interest of the public.
          <br />
          <br />
          6.1.6. Legitimate/Commercial interest. This is where We process
          Personal Data in order to protect Our legitimate/commercial interests.
        </p>
      </div>
    </section>
  );
};

export const UseYourPersonalData = () => {
  return (
    <section className="">
      <div className="textHeader">7. How Do We Use Your Personal Data?</div>
      <br />
      <div>
        <p className="textBody">
          7.1. Venco acts as a Data Controller when using your Personal Data in
          providing you with Services. We use Personal Data about you in the
          following ways:
          <ul className="list-disc list-outside pl-4">
            <li>to provide you access to Our Services;</li>
            <li>
              to improve and maintain performance of the App, by ensuring that
              the content on the App is presented in the most effective manner
              for you;
            </li>
            <li>
              to provide you with data about services that you request from Us
              or which We feel may interest you, where you have consented to be
              contacted for such purposes;
            </li>
            <li>for statistical analysis or market research;</li>
            <li>to develop and improve Our Services;</li>
            <li>to update Our records;</li>
            <li>
              to detect, investigate, prevent, or tackle illegal activities,
              fraud, or situations involving potential threats to the rights,
              property, or personal safety of Data Subject;
            </li>
            <li>
              to ensure Our compliance with Our policies, terms, and other
              applicable laws and regulations;
            </li>
            <li>to respond to inquiries and offer support to you;</li>
            <li>to request your feedback and improve your user experience;</li>
            <li>
              to carry out Our obligations arising from and exercise Our rights
              under any agreements between you and Us;
            </li>
            <li>
              to allow you to participate in interactive features of our
              Services, when you choose to do so;
            </li>
            <li>
              to notify you about changes to Our Services or potential new
              services that We may offer;
            </li>
            <li>to provide you with customer service;</li>
            <li>for any legitimate business Interest;</li>
            <li>
              recruitment – If you apply for a role via one of Our sites, We
              will process your information in order to facilitate the
              application process;
            </li>
            <li>
              to process and assess your application for employment with Us; and
            </li>
            <li>in any other way that you have specifically consented to.</li>
          </ul>
          <br />
          <br />
          7.2. Venco does not sell, rent or lease its customer lists to third
          parties.
          <br />
          <br />
          7.3. Venco may, from time to time, contact you on behalf of external
          business partners about a particular offer or update that you may be
          interested in. In these cases, your Personal Data will not be not
          transferred to such third party. Venco may share your Personal Data
          with trusted partners (we rely upon to assist in the operation of the
          Services available to you) to help perform statistical analysis, send
          you email or postal mail, provide customer support, or arrange for
          deliveries. All such third parties are prohibited from using and
          exploiting your Personal Data except to enable Venco provide the
          Services to you, and they are also required to maintain the
          confidentiality of your Personal Data.
          <br />
          <br />
          7.4. Venco may keep track of the websites and pages its users visit
          within Venco , in order to determine what VENCO’s ’ services are the
          most popular and appealing to users. This data is used to deliver
          customised content and advertising within Venco to users whose
          behavior indicates that they are interested in a particular subject
          area.
          <br />
          <br />
          7.5. Mobile App Permissions 
          <br /> 
          Your privacy and the transparency of your data are of paramount importance to us. When we approach the matter of permissions and user data, our objective is always to uphold a clear and straightforward approach. Before requesting any permissions, we aim to provide comprehensive explanations for our users, detailing the reasons why a specific permission is essential and how it enriches their experience within the app.
          In line with this commitment, we'd like to inform you about an optional permission our app may request:
          <br />          
          <br />          
          <strong>Permission to Access Contacts</strong> 
          <br />   
          Before proceeding with creating a visitor booking, we request permission to access your contacts. Here's why and how we use this information:
          <br />
          <br />    
          <ul className="list-disc list-outside pl-4">
            <li>
              <strong>Purpose:</strong> We access your contacts to streamline the process of creating a visitor booking by allowing you to select a contact from your phonebook directly. This ensures that the contact information is accurate and saves you time.
            </li>
            <li>
              <strong>Data Collection:</strong> When you select a contact from your phonebook for visitor booking, we collect the following information: Name, Phone Number, and any other information you choose to provide about that contact.
            </li>
            <li>
              <strong>
                Data Storage:
              </strong>The contact information collected is stored securely on our backend to process the visitor booking. It will be retained as part of the visitor booking data and will be used solely for this purpose.
            </li>
            <li>
              <strong>
                Control:
              </strong>          
              You have the option to manually enter the contact information if you prefer not to grant this permission. You can also view, update, or delete the stored contact information at any time through your account settings.
            </li>
          </ul>
          <br />          
          <br />          
          <strong>Permission to Access Media Library</strong> 
          <br />   
          To enhance your ability to report and describe issues effectively, we provide you with the option to attach images and videos related to these issues when reporting them to your community managers. To make this possible, we kindly request access to your media library. Your privacy and the security of your media are of utmost importance to us, and here's how we handle this data:
          <br />
          <br />    
          <ul className="list-disc list-outside pl-4">
            <li>
              <strong>Purpose:</strong> We request access to your media library to empower you to better describe and report issues to your community managers. By allowing you to attach images and videos, you can provide a visual context that greatly aids in problem-solving and issue resolution.
            </li>
            <li>
              <strong>Data Collection:</strong> When you choose to attach media to your issue reports, we collect the following data: 
              <br />    
                -  <strong>Images and Videos:</strong> These visual materials are meant to complement your descriptions and provide valuable context to your community managers.
            </li>
            <li>
              <strong>
                Data Storage:
              </strong> Your collected images and videos are stored securely on our backend servers. They are accessible exclusively to your assigned community managers who need access to these materials for issue resolution. We are committed to maintaining the privacy and confidentiality of your media.
            </li>
            <li>
              <strong>
                Control:
              </strong>          
              You have full control over your media. You can choose to attach or not attach media to your issue reports as per your preference. You can also rest assured that the collected media will only be visible to the community managers assigned to the reported issues.

              Your trust in us is important, and we want to assure you that we will never use your media for any other purpose than facilitating issue reporting and resolution.

              Please grant access to your media library to enhance the effectiveness of issue reporting.


            </li>
          </ul>


          {/* Our mobile app offers a visitor access code booking feature that optional requires 
          you to grant the app access to retrieve and display you phone's contact list. If permitted, when you use any of the displayed contacts to book a visitor access code, the name and phone number of the contact the access code of was booked for is stored on the Venco's database for future references.  This is designed to streamline the process of booking a visitor access code for any contact you might want to invite over to your community. By having this direct access, you can quickly and seamlessly grant access to your contacts without manually inputting their details every time. Remember, this permission is entirely optional, and your experience with our app will not be compromised if you choose to deny it. We believe in informed consent, so we provide this information upfront to ensure you understand and are comfortable with the permissions you grant.
          Thank you for trusting us with your data, and we continuously work towards ensuring its security and your peace of mind. */}
        </p>
      </div>
    </section>
  );
};

export const ShareYourPersonalData = () => {
  return (
    <section className="">
      <div className="textHeader ">
        8. Who We Share Your Personal Data with?
      </div>
      <br />
      <div>
        <p className="textBody">
          8.1. We do not share Personal Data with unaffiliated third parties,
          except as necessary for Our legitimate business interest, to provide
          you with Our Services, and/or as required or permitted by law or
          professional standards and generally to facilitate the running of Our
          business or to provide specific services you have requested. We would
          only transfer Personal Data to the following categories or situations,
          when they meet Our strict standards on the processing of data and
          security. We only share Personal Data that is necessary for them to
          provide their services. Venco will not transfer your Personal Data to
          any third parties for their own direct marketing use except as stated
          herein. Commonly, We may share your Personal Data with:
          <br />
          <br />
          8.1.1. Service providers – We engage service providers who help to
          support Our business and improve Our products. These service providers
          may include payment processors, customer service agencies, hosts,
          organizers and sponsors of Our events; organizations that host Our
          website, App or databases and providers of online surveys. We have
          contracts in place with these data processors and they can only use
          your Personal Data under Our instruction.
          <br />
          <br />
          8.1.2. Advertisers – We, and Our advertising partners, use cookies to
          collect Personal Data and serve you with advertisements that We
          believe are relevant to you and your interests.
          <br />
          <br />
          8.1.3. –Audits – disclosures of Personal Data may also be needed for
          data privacy, financial or security audits and/or to investigate or
          respond to a complaint or security threat. We, and Our advertising
          partners, use cookies to collect Personal Data and serve you with
          advertisements that We believe are relevant to you and your interests.
          <br />
          <br />
          8.1.4. Legal processes and successors in title – We, and Our
          suppliers, may also disclose your Personal Data to comply with
          applicable laws, court orders or other valid legal processes, and to
          enforce or apply Our terms of use, any subscription agreement, or any
          of Our other rights. We may transfer or disclose your Personal Data to
          any entity which takes over or acquires the relevant Venco company. We
          also share aggregated, non-identifiable information with third
          parties. Before sharing this information, We ensure that it cannot
          identify you as an individual. For example, We may share information
          about the size and growth of Our customer base with investors.
        </p>
      </div>
    </section>
  );
};

export const RetainYourPersonalData = () => {
  return (
    <section className="">
      <div className="textHeader ">
        9. How Long We Retain Your Personal Data?
      </div>
      <br />
      <div>
        <p className="textBody">
          9.1. We retain your Personal Data for as long as your account is
          active and therefore open; this allows you to continue accessing Our
          content, products and Services.
          <br /> <br />
          9.2. If you become inactive and are not a subscriber, We retain your
          Personal Data only for the period within which it is reasonably needed
          and may delete elements of your Personal Data associated with your
          account. We will normally warn you if your access is going to be
          withdrawn and give you the opportunity to retain your account.
          <br />
          <br />
          9.3. Where We don’t need to keep all of your Personal Data in full, We
          may obfuscate or aggregate it, for example, web activity logs and
          survey responses. This is to ensure that We do not retain your
          Personal Data for any longer than is necessary.
          <br />
          <br />
          9.4. It is sometimes necessary for Us to keep your Personal Data for
          longer periods of time, for example:
          <br />
          <br />
          9.4.1. If there is a statutory requirement to retain it;
          <br />
          <br />
          9.4.2. If We require the Personal Data for legal reasons or there is a
          legitimate business need for Us to retain same;
          <br />
          <br />
          9.4.3. To ensure We do not contact you if you have asked Us not to;
          and
          <br />
          <br />
          9.4.4. To use aggregated data derived from incorporating your Personal
          Data after your user account becomes inactive, but not in a manner
          that would identify you personally.
        </p>
      </div>
    </section>
  );
};
export const StorePersonalData = () => {
  return (
    <section className="">
      <div className="textHeader ">
        10. How Do We Secure and Store Personal Data?
      </div>
      <br />
      <div>
        <p className="textBody">
          10.1. We employ all reasonable efforts to keep your Personal Data
          secure by taking appropriate technical and organizational measures
          against its unauthorized or unlawful processing and against its
          accidental loss, destruction or damage. We store and process your
          Personal Data on computers outside your jurisdiction. Where We need to
          transfer your Personal Data to another country, We would take
          reasonable steps to ensure that such country has an adequate data
          protection law. You hereby consent to the transfer of your Personal
          Data to countries which may not be on the white list specified by the
          relevant data protection regulations where such transfers become
          expedient for us. We protect your Personal Data using physical,
          technical, and administrative security measures to reduce the risks of
          loss, misuse, unauthorized access, disclosure and alteration. Some of
          the safeguards We use are firewalls and data encryption, physical
          access controls to Our data centers, and information access
          authorization controls.
          <br />
          <br />
          10.2. However, no data transmission over the internet or wireless
          network can be 100% guaranteed. Therefore, whilst We strive to protect
          your Personal Data, you acknowledge that:
          <br />
          <br />
          10.2.1. there are security and privacy limitations of the internet
          which are beyond Our control;
          <br />
          <br />
          10.2.2. the security, integrity, and privacy of any and all
          information and data exchanged between you and Us cannot be 100%
          guaranteed; and
          <br />
          <br />
          10.2.3. any such information and data may be viewed or tampered with
          in transit by a third party, despite Our best efforts.
        </p>
      </div>
    </section>
  );
};
export const DataBreach = () => {
  return (
    <section className="">
      <div className="textHeader ">11. Data Breach</div>
      <br />
      <div>
        <p className="textBody">
          11.1. In the event We become aware that the security of Our Services
          have been compromised or users’ Personal Information have been
          disclosed to unrelated third parties as a result of an external
          activity, including, but not limited to, security attacks or fraud, We
          reserve the right to take reasonably appropriate measures, including,
          but not limited to, investigation and reporting, as well as
          notification to and cooperation with law enforcement authorities. In
          the event of a data breach, We will make reasonable efforts to notify
          affected individuals if We believe that there is a reasonable risk of
          harm to the user as a result of the breach.
        </p>
      </div>
    </section>
  );
};
export const Unsubscribe = () => {
  return (
    <section className="pt-6 lg:pt-8">
      <div className="textHeader">12. Opt-Out & Unsubscribe</div>
      <br />
      <div>
        <p className="textBody">
          12.1. We respect your privacy and give you an opportunity to opt-out
          of receiving announcements of certain information. You may opt-out of
          receiving any or all communications from Venco by contacting Us at{" "}
          <a
            href="mailto:info@venco.africa"
            target="_blank"
            rel="noopener noreferrer"
            className="underline underline-offset-4"
          >
            {" "}
            info@venco.africa.
          </a>{" "}
        </p>
      </div>
    </section>
  );
};
export const InformationAboutOthers = () => {
  return (
    <section className="pt-6 lg:pt-8">
      <div className="textHeader">
        13. What Happens When You Provide Us With Information About Others?
      </div>
      <br />
      <div>
        <p className="textBody">
          13.1. If you provide Us with Personal Data about another individual,
          you are responsible for ensuring that you comply with any obligation
          and consent obligations under applicable data protection laws in
          relation to such disclosure. Insofar as required by applicable data
          protection laws, you must ensure that you have provided the required
          notices and have obtained the individual’s explicit consent to provide
          Us with the information and that you explain to them how We collect,
          use, disclose and retain their Personal Data or direct them to read
          Our Privacy Statement.
        </p>
      </div>
    </section>
  );
};
