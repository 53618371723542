import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
// import Fab from '@mui/material/Fab';
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';
// import { Auth } from 'aws-amplify';
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { defaultCountries, parseCountry } from "react-international-phone";
import { useLocation } from 'react-router-dom';
import "./OnboardingForm.css";

// import SuccessIcon from "../../assets/svg/success_icon.svg";
// import VencoLogo from "../../assets/svg/venco_logo.svg";
import VencoIcon from "../../assets/svg/venco_icon.svg";
// import Pin from "../../assets/svg/pin.svg";
import CloseIcon from "../../assets/svg/close-round.svg";
import {
  confirm_community_onboarding,
  get_countries,
  get_location,
  request_community_onboarding,
  request_otp,
  signIn,
  verify_otp,
} from "../../api/auth";
import { MapPin, X } from "phosphor-react";
import CustomPhoneInput from "../INPUTS/CustomPhoneInput";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Spinner from "../BUTTONS/Spinner";

export const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export default function OnboardingForm(props) {
  const navigate = useNavigate();

  const [activeSection, setActiveSection] = useState("form"); // message
  // const [country_code, setCountryCode] = useState("ng");

  const [selectedCountry, setSelectedCountry] = useState(
    parseCountry(defaultCountries[138])
  );

  const presentCountry = useSelector((state) => state.geoDetails.country);

  function getQueryParam(name, search) {
    const params = new URLSearchParams(search);
    return params.get(name);
  }

  const location = useLocation();
  const referralCode = getQueryParam('referral_url', location.search);

  useEffect(() => {
    if (presentCountry !== "Global") {
      const name = "country";
      setInputValues((prevState) => ({
        ...prevState,
        [name]: presentCountry,
      }));
    }

    if (referralCode) {
      const name = "referral_code";
      setInputValues((prevState) => ({
        ...prevState,
        [name]: referralCode,
      }));
    }
  }, [presentCountry, referralCode]);

  const [modalSection, setModalSection] = useState(0);
  const [inputValues, setInputValues] = useState({
    // community info
    communityName: "",
    communityID: "",
    noOfProperties: "",
    country: "",
    state: "",
    streetAddress: "",
    communityDomainName: "",
    isForExistingGroup: false,

    // personal info
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    customRole: "",
    userRole: "",
    userId: "",

    // meters
    electricMeters: false,
    waterMeters: false,
    gasMeters: false,
    noMeters: false,

    communityGroup: null,
    otp: "",
    referral_code: "",
  });
  const [errMsg, setErrMsg] = useState({
    // community info
    communityName: "",
    communityID: "",
    noOfProperties: "",
    country: "",
    state: "",
    streetAddress: "",
    communityDomainName: "",

    // personal info
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    customRole: "",
    userRole: "",
    userId: "",

    // meters
    electricMeters: "",
    waterMeters: "",
    gasMeters: "",
    noMeters: "",

    otp: "",
    communityGroup: "",
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [otpModalIsOpen, setOtpModalIsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalSubTitle, setModalSubTitle] = useState("");
  const [tempId, setTempId] = useState("");
  // eslint-disable-next-line
  const [successMsg, setSuccessMsg] = useState(
    "Your request has be received, but Venco is currently available in United States, you’d be the first to know as soon as we launch there."
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [isPersonalInfoEditable, setIsPersonalInfoEditable] = useState(true);
  // eslint-disable-next-line
  const [useExistingSite, setUseExistingSite] = useState(false);
  // eslint-disable-next-line
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [placeSuggestions, setPlaceSuggestions] = useState([]);
  const [showplaceSuggestions, setShowplaceSuggestions] = useState(false);
  const [countries, setCountries] = useState([]);

  const [isRecaptchaVerified, setRecaptchaVerified] = useState(false);


  const recaptchaRef = React.createRef();

  useEffect(() => {
    getCountries();
    // eslint-disable-next-line
  }, []);

  const handleInputChange = (event) => {
    const target = event.target;
    const value =
      target.type === "checkbox" || target.type === "radio"
        ? target.checked
        : target.value;
    const name = target.name;
    setInputValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "isForExistingGroup" && value === true) {
      // console.log('userInfo?.community_groups?', userInfo?.community_groups[3].name);
      setModalSection(0);
      setModalIsOpen(true);
    }
  };

  const handlePhoneNumberInput = (e) => {
    const target = e.target;
    const name = target.name;
    if (target.value.length > 1 && target.value.charAt(0) === "0") {
      // Remove the leading '0'
      setInputValues((prevState) => ({
        ...prevState,
        [name]: target.value.slice(1),
      }));
      return;
    }
    setInputValues((prevState) => ({
      ...prevState,
      [name]: target.value,
    }));
  };

  const handleStreetChange = async (event) => {
    const target = event.target;
    const value = target.value;

    setInputValues((prevState) => ({
      ...prevState,
      streetAddress: value,
    }));
    if (value.length > 3 && (value.length === 3 || value.length % 2 === 0)) {
      await getLocation(value);
    }
  };

  // eslint-disable-next-line
  const onRecaptchaChange = (value) => {
    setRecaptchaVerified(value);
  };

  const getLocation = async (query) => {
    try {
      var params = {
        use_default: false,
        input_text: query,
        language: "EN",
        country: inputValues.country,
      };

      // console.log('getLocation params', params);

      const response = await get_location(params);

      if (response.ok) {
        const res = await response.json();

        console.log("res", res);
        if (Boolean(res)) {
          setPlaceSuggestions(res);
          setShowplaceSuggestions(true);
        }
      } else {
        setPlaceSuggestions([]);
        setShowplaceSuggestions(false);
      }
    } catch (error) {
      setPlaceSuggestions([]);
      setShowplaceSuggestions(false);
    }
  };

  // eslint-disable-next-line
  const statesInNigeria = [
    "Abia",
    "Adamawa",
    "Akwa Ibom",
    "Anambra",
    "Bauchi",
    "Bayelsa",
    "Benue",
    "Borno",
    "Cross River",
    "Delta",
    "Ebonyi",
    "Edo",
    "Ekiti",
    "Enugu",
    "FCT - Abuja",
    "Gombe",
    "Imo",
    "Jigawa",
    "Kaduna",
    "Kano",
    "Katsina",
    "Kebbi",
    "Kogi",
    "Kwara",
    "Lagos",
    "Nasarawa",
    "Niger",
    "Ogun",
    "Ondo",
    "Osun",
    "Oyo",
    "Plateau",
    "Rivers",
    "Sokoto",
    "Taraba",
    "Yobe",
    "Zamfara",
  ];

  // eslint-disable-next-line
  const statesInKenya = [
    "Baringo",
    "Bomet",
    "Bungoma",
    "Busia",
    "Elgeyo-Marakwet",
    "Embu",
    "Garissa",
    "Homa Bay",
    "Isiolo",
    "Kajiado",
    "Kakamega",
    "Kericho",
    "Kiambu",
    "Kilifi",
    "Kirinyaga",
    "Kisii",
    "Kisumu",
    "Kitui",
    "Kwale",
    "Laikipia",
    "Lamu",
    "Machakos",
    "Makueni",
    "Mandera",
    "Marsabit",
    "Meru",
    "Migori",
    "Mombasa",
    "Murang'a",
    "Nairobi",
    "Nakuru",
    "Nandi",
    "Narok",
    "Nyamira",
    "Nyandarua",
    "Nyeri",
    "Samburu",
    "Siaya",
    "Taita-Taveta",
    "Tana River",
    "Tharaka-Nithi",
    "Trans-Nzoia",
    "Turkana",
    "Uasin Gishu",
    "Vihiga",
    "Wajir",
    "West Pokot",
  ];

  function isValidEmail(value) {
    // Use a regular expression to validate the email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  }

  function isValidPhoneNumber(value) {
    // Use a regular expression to validate the phone number format
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(value);
  }

  function sortByActiveStatus(a, b) {
    if (a.active === b.active) {
      // If both items have the same active status, maintain the original order
      return 0;
    } else if (a.active === 1) {
      // Move items with active: 1 to the top
      return -1;
    } else {
      // Move items with active: 0 to the bottom
      return 1;
    }
  }

  const getCountries = async () => {
    try {
      await sleep(2000);
      const res = await get_countries();
      // console.log("res status", res.status)
      if (res.ok) {
        const { message } = await res.json();
        const data = message.data;
        data.sort(sortByActiveStatus);
        setCountries(data);
      } else {
        setCountries([
          {
            country_name: "Ghana",
            country_code: "gh",
            currency: "GHS",
            active: 1,
            emergency_numbers: [
              {
                emergency_number_type: "Police",
                emergency_number: "191",
              },
              {
                emergency_number_type: "Fire",
                emergency_number: "192",
              },
              {
                emergency_number_type: "Medical",
                emergency_number: "193",
              },
            ],
          },
          {
            country_name: "Kenya",
            country_code: "ke",
            currency: "KES",
            active: 1,
            emergency_numbers: [
              {
                emergency_number_type: "General",
                emergency_number: "112",
              },
            ],
          },
          {
            country_name: "Nigeria",
            country_code: "ng",
            currency: "NGN",
            active: 1,
            emergency_numbers: [
              {
                emergency_number_type: "General",
                emergency_number: "112",
              },
            ],
          },
        ]);
      }
    } catch (error) { }
  };

  const verifyUser = async () => {
    try {
      setIsSubmitting(true);
      var isEmail = false;
      var params = {};
      if (isValidEmail(inputValues.userId)) {
        // Handle email input
        params["email"] = inputValues.userId;
        setErrMsg((prevState) => ({
          ...prevState,
          userId: "",
        }));
        isEmail = true;
      } else if (isValidPhoneNumber(inputValues.userId)) {
        // Handle phone number input
        params["phoneNumber"] = inputValues.userId;
        setErrMsg((prevState) => ({
          ...prevState,
          userId: "",
        }));
        isEmail = false;
      } else {
        // Handle invalid input
        setErrMsg((prevState) => ({
          ...prevState,
          userId: "Kindly enter a valid email or phone number",
        }));
        setIsSubmitting(false);
        // console.log('verifyUser failed');
      }

      const response = await signIn(params);
      // console.log('status', response.status)
      if (response.ok) {
        var res = await response.json();
        if (res.enrolled) {
          setTempId(res.id);
          var requestOtpRes = await requestOtp(res.id);

          if (requestOtpRes.status === "success") {
            setModalTitle("Please verify OTP");
            setModalSubTitle("Please enter the OTP code that was sent to ");
            setModalSection(2);
            setIsSubmitting(false);
            setErrMsg((prevState) => ({
              ...prevState,
              userId: "",
            }));
          } else {
            setErrMsg((prevState) => ({
              ...prevState,
              userId: requestOtpRes?.message,
            }));
          }
        } else {
          setErrMsg((prevState) => ({
            ...prevState,
            userId: isEmail
              ? "Email is not registered to any account on the Venco platform"
              : "Phone number is not registered to any account on the Venco platform",
          }));
          setIsSubmitting(false);
        }
      } else {
        if (response.status === 500) {
          setErrMsg((prevState) => ({
            ...prevState,
            userId: "Internal server error",
          }));
          setIsSubmitting(false);
        } else {
          var { message, code } = await response.json();
          setErrMsg((prevState) => ({
            ...prevState,
            userId: message
              ? `${message} code - ${code}`
              : "Oops... Something went wrong. code " + response.status,
          }));
          setIsSubmitting(false);
        }
      }
    } catch (error) {
      setErrMsg((prevState) => ({
        ...prevState,
        userId: "Oops... Something went wrong. \nError: " + error.message,
      }));
      setIsSubmitting(false);
    }
  };

  const requestOtp = async (id) => {
    try {
      setIsSubmitting(true);
      var params = {
        id,
      };

      var response = await request_otp(params);
      var res = await response.json();

      if (response.ok) {
        setInputValues((prevState) => ({
          ...prevState,
          otp: "",
        }));
        return {
          status: "success",
        };
      } else {
        if (response.status === 500) {
          return {
            status: "error",
            message: "Internal server error",
          };
        } else {
          return {
            status: "error",
            message: res.message,
          };
        }
      }
    } catch (error) {
      return {
        status: "error",
        message: "Something went wrong. Error: " + error.message,
      };
    }
  };

  const requestCommunityOnboarding = async (event) => {
    event.preventDefault(); // Prevent page refresh

    const country = selectedCountry;
    const phoneNumber = "+" + country.dialCode + parseInt(inputValues.phoneNo);

    try {
      if (!isRecaptchaVerified) {
        toast.error("Error submitting, please verify reCAPTCHA and try again", {
          position: "top-center",
          autoClose: 15000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return false;
      }
      setIsSubmitting(true);

      var params = {
        email: inputValues.email,
        phone_number: phoneNumber,
        community_name: inputValues.communityName,
        country: inputValues.country,
        address: inputValues.streetAddress,
        place_id: inputValues.place_id,
        role: inputValues.userRole,
      };

      // console.log('params', params);

      if (inputValues.communityGroup) {
        params["host_url"] = inputValues.communityGroup;
      }

      var response = await request_community_onboarding(params);
      var res = await response.json();

      // console.log('response status', response.status);
      // console.log('res', res);

      if (response.ok) {
        setTempId(res.tmp_id);
        // console.log("res.tmp_id", res.tmp_id);
        setInputValues((prevState) => ({
          ...prevState,
          otp: "",
        }));
        setErrMsg((prevState) => ({
          ...prevState,
          otp: "",
        }));
        setOtpModalIsOpen(true);

        // return {
        //   status: 'success'
        // }
      } else {
        if (response.status === 500) {
          toast.error(res.message || "Internal server error", {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            zIndex: 1000,
          });
        } else {
          toast.error(res.message || "Something went wrong", {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            zIndex: 1000,
          });
        }
        setIsSubmitting(false);
      }
    } catch (error) {
      toast.error("Something went wrong. Error: " + error.message, {
        position: "top-center",
        autoClose: 15000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        zIndex: 1000,
      });
      setIsSubmitting(false);
    }
    return false;
  };

  const submitCommunityOnboardingRequest = async () => {
    const country = selectedCountry;
    const phoneNumber = "+" + country.dialCode + parseInt(inputValues.phoneNo);
    try {
      var params = {
        tmp_id: tempId,
        otp: inputValues.otp,
        first_name: inputValues.firstName,
        last_name: inputValues.lastName,
        email: inputValues.email,
        phone_number: phoneNumber,
        community_name: inputValues.communityName,
        country: inputValues.country,
        address: inputValues.streetAddress,
        place_id: inputValues.place_id,
        role: inputValues.userRole,
        referral_code: inputValues.referral_code,
        // test: 1
      };

      if (inputValues.communityGroup) {
        params["host_url"] = inputValues.communityGroup;
      }

      // console.log(' confirm_community_onboarding params', params);

      const res = await confirm_community_onboarding(params);
      const response = await res.json();

      // console.log('res status', res.status)
      // console.log('response', response)
      if (res.ok) {
        setSuccessMsg(response?.message || "Success");
        closeVerifyUserModal();
        setActiveSection("message");
      } else {
        toast.error(response.message, {
          position: "top-right",
          autoClose: 15000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setIsSubmitting(false);
      }
    } catch (error) {
      toast.error("Something went wrong. Error: " + error.message, {
        position: "top-center",
        autoClose: 15000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setIsSubmitting(false);
    }
  };

  const verifyOtp = async () => {
    try {
      setIsSubmitting(true);
      var isEmail = false;
      var params = {
        id: tempId,
        otp: inputValues.otp,
      };
      if (isValidEmail(inputValues.userId)) {
        // Handle email input
        params["email"] = inputValues.userId;
        setErrMsg((prevState) => ({
          ...prevState,
          otp: "",
        }));
        isEmail = true;
      } else if (isValidPhoneNumber(inputValues.userId)) {
        // Handle phone number input
        params["phoneNumber"] = inputValues.userId;
        setErrMsg((prevState) => ({
          ...prevState,
          otp: "",
        }));
        isEmail = false;
      }

      var response = await verify_otp(params);

      if (response.ok) {
        const res = await response.json();
        const { apiKey, secretKey, ...userInfo } = res.data;

        // console.log('userInfo', userInfo);
        if (!Boolean(userInfo?.community_groups?.length)) {
          setModalTitle("No existing site");
          setModalSubTitle(
            "The " +
            (isEmail ? " email " : " phone number ") +
            "you provided isn't associated to any community"
          );
          setModalSection(4);
        } else {
          setUserInfo(userInfo);
          setModalTitle("Select existing site");
          setModalSubTitle(
            "Now please select the community group or site you would like to add this new community to."
          );
          setModalSection(3);
        }

        setIsSubmitting(false);
        setErrMsg((prevState) => ({
          ...prevState,
          otp: "",
        }));
      } else {
        const res = await response.json();
        setErrMsg((prevState) => ({
          ...prevState,
          otp: res.message,
        }));
        setIsSubmitting(false);
      }
    } catch (error) {
      setErrMsg((prevState) => ({
        ...prevState,
        userId: "Oops... Something went wrong. \nError: " + error.message,
      }));
      setIsSubmitting(false);
    }
  };

  const selectExistingSite = () => {
    // // console.log('inputValues.communityGroup', inputValues.communityGroup.name);
    if (inputValues.communityGroup) {
      setModalIsOpen(false);
      setUseExistingSite(true);
      setIsPersonalInfoEditable(false);
      setInputValues((prevState) => ({
        ...prevState,
        firstName: userInfo?.firstName,
        lastName: userInfo?.lastName,
        email: userInfo?.id,
        phoneNo: userInfo?.phone,
        // communityDomainName: prevState.communityGroup.estates[0].host,
      }));
    } else {
      setErrMsg((prevState) => ({
        ...prevState,
        communityGroup:
          "Please select the community group/site you would like to add the new site to",
      }));
    }
  };

  const toggleModal = (value) => {
    if (value) {
      setModalTitle("Please Confirm");
      setModalSubTitle(
        "Do you or your organization have an existing Venco site and would like for this new community to be hosted on the same site as the previous one?"
      );
    } else {
      setInputValues((prevState) => ({
        ...prevState,
        isForExistingGroup: false,
      }));
    }

    setModalIsOpen(value);
  };

  const selectPlace = (item) => {
    console.log(item.terms[item.terms.length - 1]);
    setInputValues((prevState) => ({
      ...prevState,
      streetAddress: item.description,
      place_id: item.place_id,
    }));
    setPlaceSuggestions([]);
    setShowplaceSuggestions(false);
  };

  const closeVerifyUserModal = () => {
    setIsSubmitting(false);
    setOtpModalIsOpen(false);
  };

  const customStyles = {
    content: {
      // top: '50%',
      // left: '50%',
      // right: 'auto',
      // bottom: 'auto',
      // marginRight: '-50%',
      zIndex: 99999,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      backdropFilter: "blur(2px)",
    },
  };

  return (
    <div className=" bg-grey-50 rounded-lg px-4 py-10 lg:px-10 lg:py-16 xl:px-[80px] xl:py-[51px] w-full md:w-3/5 lg:w-auto">
      <div className="">
        {activeSection === "form" && (
          <>
            <div className="">
              {/* <a href="/">
                <img
                  src={VencoLogo}
                  alt="venco logo"
                  className="mx-auto w-[150px] md:w-[200px] lg:w-[400px] "
                />
              </a> */}
              <div>
                <p className="font-sora font-semibold text-[24px] md:text-2xl lg:text-[40px] text-center xl:max-w-[927px] xl:mx-auto md:text-left">
                  Onboard your Community
                </p>
                <div className="h-6"></div>
                <p className="text-sm font-medium text-center md:text-left">
                  Please fill out the form below to provide basic information about your community
                </p>

              </div>
            </div>

            <form
              // className="rounded-t-xl md:rounded-xl md:max-w-[500px] lg:max-w-[798px] mx-auto px-5 py-10 mt-[60px] xl:p-20 flex flex-col gap-4 md:gap-8"
              className="pt-10 flex flex-col gap-4 md:gap-5 xl:min-h-[600px]"
              onSubmit={requestCommunityOnboarding}
            >
              <div>
                <input
                  type="text"
                  value={inputValues.communityName}
                  name="communityName"
                  placeholder="Community Name"
                  onChange={handleInputChange}
                  // autoComplete='name'
                  required
                  // className={this.props.is_erred ? "outline-input-field-erred" : (this.state.is_focus ? "outline-input-field-active" : "outline-input-field")}
                  className="formInput"
                />

                <label className="flex gap-2.5 items-start mt-4">
                  <input
                    type="checkbox"
                    value={inputValues.isForExistingGroup}
                    onChange={handleInputChange}
                    name="isForExistingGroup"
                    checked={inputValues.isForExistingGroup}
                  />
                  <p className="text-xs font-inter">
                    Is this new community managed as part of an existing group
                    of communities on the Venco platform?
                  </p>
                </label>
              </div>

              <div className="">

                <div className="formInput">
                  <select
                    name="userRole"
                    id="userRole"
                    className="w-full p-0 bg-transparent focus:outline-none placeholder:text-grey-600 text-sm lg:text-base"
                    value={inputValues.userRole}
                    placeholder="Select your role"
                    onChange={handleInputChange}
                  // disabled={!isPersonalInfoEditable}
                  >
                    <option value="" defaultValue disabled>
                      Select your role
                    </option>
                    <option value="Facilities Manager">
                      Facilities Manager
                    </option>
                    <option value="Property Manager">Property Manager</option>
                    <option value="Resident">Resident</option>
                    <option value="Community Association ExCo">
                      Community Association ExCo
                    </option>
                    <option value="Property Developer">
                      Property Developer
                    </option>
                    <option value="Others">Others</option>
                  </select>
                </div>
              </div>

              <div className="flex flex-col md:flex-row gap-4">
                <div className="flex-1">
                  <input
                    type="text"
                    value={inputValues.firstName}
                    name="firstName"
                    placeholder="First Name"
                    onChange={handleInputChange}
                    autoComplete="name"
                    required
                    disabled={!isPersonalInfoEditable}
                    // className={this.props.is_erred ? "outline-input-field-erred" : (this.state.is_focus ? "outline-input-field-active" : "outline-input-field")}
                    className="formInput"
                  />
                </div>
                <div className="flex-1">
                  <input
                    type="text"
                    value={inputValues.lastName}
                    name="lastName"
                    id="lastName"
                    placeholder="Last Name"
                    onChange={handleInputChange}
                    autoComplete="name"
                    required
                    disabled={!isPersonalInfoEditable}
                    // className={this.props.is_erred ? "outline-input-field-erred" : (this.state.is_focus ? "outline-input-field-active" : "outline-input-field")}
                    className="formInput"
                  />
                </div>
              </div>

              <div className="">
                <input
                  type="text"
                  value={inputValues.email}
                  name="email"
                  id="email"
                  placeholder="Email Address"
                  onChange={handleInputChange}
                  autoComplete="email"
                  required
                  disabled={!isPersonalInfoEditable}
                  // className={this.props.is_erred ? "outline-input-field-erred" : (this.state.is_focus ? "outline-input-field-active" : "outline-input-field")}
                  className="formInput"
                />
              </div>
              <div className="">
                <div className="formInput">
                  <select
                    name="country"
                    id="country"
                    className="input-field"
                    value={inputValues.country}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="" defaultValue disabled>
                      Select a country
                    </option>
                    {countries.map((item, index) => (
                      <option value={item.country_name} key={index}>
                        {item.country_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div>
                <div
                  className={`flex font-inter items-center relative  gap-2.5`}
                >
                  <CustomPhoneInput
                    selectedCountry={selectedCountry}
                    setSelectedCountry={setSelectedCountry}
                  />

                  <input
                    type="text"
                    value={inputValues.phoneNo}
                    name="phoneNo"
                    placeholder="Phone Number"
                    onChange={handlePhoneNumberInput}
                    autoComplete="tel"
                    required
                    disabled={!isPersonalInfoEditable}
                    // className={this.props.is_erred ? "outline-input-field-erred" : (this.state.is_focus ? "outline-input-field-active" : "outline-input-field")}
                    className="formInput"
                  />
                </div>
              </div>


              <div className="">
                <input
                  type="text"
                  value={inputValues.referral_code}
                  name="referral_code"
                  id="referral_code"
                  placeholder="Referral Code"
                  onChange={handleInputChange}
                  autoComplete="referral_code"
                  disabled={!isPersonalInfoEditable}
                  className="formInput"
                />
              </div>

              <div className="relative">
                <input
                  type="text"
                  value={inputValues.streetAddress}
                  name="streetAddress"
                  placeholder="Street Address"
                  onChange={handleStreetChange}
                  autoComplete="off"
                  // autoComplete='name'
                  required
                  // className={this.props.is_erred ? "outline-input-field-erred" : (this.state.is_focus ? "outline-input-field-active" : "outline-input-field")}
                  className="formInput"
                />

                {showplaceSuggestions ? (
                  <div className="bg-white absolute overflow-scroll  top-full w-full rounded-lg p-4 lift">
                    <div className="max-h-[200px] space-y-4">
                      {placeSuggestions.map((item, index) => {
                        return (
                          <div
                            className="flex gap-2 items-center px-3 py-4 hover:bg-light-green-500 rounded-lg"
                            onClick={() => selectPlace(item)}
                          >
                            <MapPin className="h-4" />

                            <p className="flex-1 font-inter text-sm">
                              {item.description}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
              </div>

              {/* <span className='input-span'>
                    <p className='input-label'>State</p>     
                    <div className="input-field-box">
                      <select 
                        name="state" 
                        id="state"
                        className="input-field"
                        value={inputValues.state}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="" defaultValue disabled>Select a state</option>
                        {
                          (inputValues.country === 'Nigeria' ? (
                            statesInNigeria.map((item, index) => {
                              return (  
                                <option key={index} value={item}>{item}</option>
                              )
                            })
                          ) : null)
                        }
                        {
                          (inputValues.country === 'Kenya' ? (
                            statesInKenya.map((item, index) => {
                              return (  
                                <option key={index} value={item}>{item}</option>
                              )
                            })
                          ) : null)
                        }
                        
                      </select>
                    </div> 
                  </span> */}

              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 25,
                }}
              >
                <ReCAPTCHA
                  ref={recaptchaRef}
                  tabindex={1}
                  sitekey={"6LcstiIoAAAAAAdRrvboVRDpAcjT5DXizvjxqAth"}
                  onChange={onRecaptchaChange}
                />
              </span>
              <button
                disabled={isSubmitting}
                className="w-full font-sora lg:text-lg font-semibold text-green-500 text-sm md:text-base  bg-light-green-500 py-4 rounded-lg disabled:cursor-not-allowed"
                type="submit"
              >
                {isSubmitting ? <Spinner color="text-green-500" /> : "Submit"}
                {/* <img 
                      src={RightArrow} 
                      alt="Right arrow" 
                    /> */}
              </button>

              <p className="text-center leading-[18px] text-xs lg:text-sm">
                By clicking the <span className="font-semibold">“Submit”</span>
                button, you agree to Venco's{" "}
                <span
                  className="text-light-purple-700  underline underline-offset-4 leading-[18px] cursor-pointer"
                  onClick={() => {
                    navigate("/terms-and-condition");
                  }}
                >
                  {" "}
                  terms of service{" "}
                </span>
                and{" "}
                <span
                  className="text-slate-500  underline underline-offset-4 leading-[18px] cursor-pointer"
                  onClick={() => {
                    navigate("/privacy-policy");
                  }}
                >
                  privacy policy{" "}
                </span>
              </p>
            </form>
          </>
        )}
        {activeSection === "message" && (
          <>
            <div
              className="
            p-4 space-y-4"
            >
              <div className="p-4">
                {/* <a href="/">
                  <img
                    src={VencoLogo}
                    alt="venco logo"
                    className="mx-auto w-[150px] md:w-[200px] lg:w-[400px] "
                  />
                </a> */}
                <div>
                  <p className="font-sora text-white text-[32px] xl:text-[36px] font-semibold leading-[42px] text-center mt-10">
                    Welcome aboard!
                  </p>
                </div>
              </div>
              <div className="bg-white rounded-xl md:max-w-[500px] lg:max-w-[650px] mx-auto px-5 py-10 mt-[60px] xl:p-20 flex flex-col gap-4 md:gap-8">
                <div className="font-inter text-sm text-left  mt-4 space-y-4 md:text-base xl:text-lg leading-[150%]">
                  <p>
                    Thank you for choosing us as your community management
                    provider. Your community,{" "}
                    <b>{inputValues.community_name}</b>, has been successfully
                    registered!
                  </p>

                  <p>
                    Your site will be active shortly. You'll receive login
                    credentials and next steps soon.
                  </p>

                  <p>
                    While you wait, watch our onboarding videos to guide you:
                  </p>

                  <a
                    href="https://www.youtube.com/watch?v=U7c1tpweYyo&list=PLaZPoGRDWGpatgHEgnKhGa_mKFR3_IKMr"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="primaryButton text-center cursor-pointer block"
                  >
                    Watch Videos
                  </a>

                  <p>
                    Need assistance? Contact our support team at
                    operations@venco.co or via WhatsApp{" "}
                    <a href="https://wa.me/+2349131055071">here</a>.
                  </p>

                  <p>
                    - The <b>Venco</b> Team
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
        {/* {activeSection === "message" && (
          <>
            <div className="header">
              <a href="https://venco.africa/">
                <img src={VencoLogo} className="logo" alt="venco-logo" />
              </a>
            </div>
            <div className="section-1">
              <img
                src={SuccessIcon}
                className="success-icon"
                alt="venco-logo"
              />
              <p>{successMsg}</p>
              <a href="https://venco.africa/">Back To Home</a>
            </div>
          </>
        )} */}

        <FloatingWhatsApp
          phoneNumber="2349131055071"
          accountName="Venco Support"
          statusMessage="Online"
          avatar={VencoIcon}
        />
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => toggleModal(false)}
        style={customStyles}
        // contentLabel="Vessel Modal"
        // overlayClassName="modal-overlay"
        className="vessel-modal"
      >
        <div className="mt-[250px] bg-white rounded-xl p-5 m-4 max-w-[460px] lg:p-[30px]">
          {modalSection === 0 ? (
            <>
              <div className="flex items-center justify-end pb-2">
                <div className="" onClick={() => toggleModal(false)}>
                  <X weight="bold" className="text-base font-semibold" />
                </div>
              </div>
              <p className="modalTitle">Please Confirm</p>
              <p className=" modalSubtitle">
                Do you or your organisation have an existing Venco site and
                would like for this new community to be hosted on the same site
                as the previous one?
              </p>
              <div className="buttonGroup">
                <button
                  onClick={() => toggleModal(false)}
                  className="secondaryButton flex-1"
                >
                  No, Take me back
                </button>
                <button
                  className="primaryButton flex-1"
                  onClick={() => {
                    setModalTitle("Okay, Let’s verify who you are");
                    setModalSubTitle(
                      "Please enter your email or phone number current being used on the VENC0 platform "
                    );
                    setModalSection(1);
                  }}
                >
                  Yes, Let's proceed
                </button>
              </div>
            </>
          ) : null}
          {modalSection === 1 ? (
            <>
              <div className="flex items-start justify-end pb-2">
                <div className="" onClick={() => toggleModal(false)}>
                  <X weight="bold" className="text-base font-semibold" />
                </div>
              </div>
              <p className="modalTitle">{modalTitle}</p>
              <p className="modalSubtitle">{modalSubTitle}</p>

              <input
                type="text"
                value={inputValues.userId}
                name="userId"
                placeholder="Email or Tel eg. +234 706 123 4567 890"
                onChange={handleInputChange}
                autoComplete="text"
                required
                // className={this.props.is_erred ? "outline-input-field-erred" : (this.state.is_focus ? "outline-input-field-active" : "outline-input-field")}
                className={`formInput mt-5 ${Boolean(errMsg.userId.length) ? "border-red-500" : ""
                  }`}
              />

              {Boolean(errMsg.userId.length) ? (
                <p className=" text-red-500 text-sm font-inter font-medium mt-1">
                  {errMsg.userId}
                </p>
              ) : null}

              <button
                disabled={isSubmitting}
                className="primaryButton w-full mt-[60px] center disabled:cursor-not-allowed"
                onClick={verifyUser}
              >
                {isSubmitting ? <Spinner color="text-green-500" /> : "Submit"}
              </button>
            </>
          ) : null}
          {modalSection === 2 ? (
            <>
              <div className="flex items-start justify-end pb-2">
                <div className="" onClick={() => toggleModal(false)}>
                  <X weight="bold" className="text-base font-semibold" />
                </div>
              </div>
              <p className="modalTitle">{modalTitle}</p>
              <p className="modalSubtitle">
                {modalSubTitle}{" "}
                <span style={{ color: "blue" }}>{inputValues.userId}</span>
              </p>

              <input
                type="number"
                value={inputValues.otp}
                name="otp"
                placeholder=" Type your OTP code"
                onChange={handleInputChange}
                autoComplete="text"
                required
                // className={this.props.is_erred ? "outline-input-field-erred" : (this.state.is_focus ? "outline-input-field-active" : "outline-input-field")}
                className={`formInput mt-5 ${Boolean(errMsg.userId.length) ? "border-red-500" : ""
                  }`}
              />

              {Boolean(errMsg.userId.length) ? (
                <p className=" text-red-500 text-sm font-inter font-medium mt-1">
                  {errMsg.otp}
                </p>
              ) : null}
              <div className="buttonGroup">
                <button
                  className="secondaryButton flex-1"
                  onClick={() => {
                    setModalTitle("Okay, Let’s verify who you are");
                    setModalSubTitle(
                      "Please enter your email or phone number current being used on the VENC0 platform "
                    );
                    setModalSection(1);
                  }}
                >
                  Back
                </button>
                <button className="primaryButton flex-1" onClick={verifyOtp}>
                  Submit
                </button>
              </div>
            </>
          ) : null}
          {modalSection === 3 ? (
            <>
              <div className="flex items-start justify-end pb-2">
                <div className="" onClick={() => toggleModal(false)}>
                  <X weight="bold" className="text-base font-semibold" />
                </div>
              </div>
              <p className="modalTitle">{modalTitle}</p>

              <p className="modalSubtitle">{modalSubTitle}</p>

              <select
                name="communityGroup"
                id="communityGroup"
                className={`formInput mt-5 ${Boolean(errMsg.userId.length) ? "border-red-500" : ""
                  }`}
                value={inputValues.communityGroup}
                onChange={handleInputChange}
                required
              >
                <option value="" defaultValue>
                  -- Select a Community Group/Site --
                </option>
                {Boolean(userInfo?.community_groups?.length)
                  ? userInfo?.community_groups.map((item, index) => {
                    return (
                      <option key={index} value={item.estates[0].host}>
                        {item.estates[0].host}
                      </option>
                    );
                  })
                  : null}
              </select>

              {Boolean(errMsg.communityGroup.length) ? (
                <p className="input-field-err-msg">{errMsg.communityGroup}</p>
              ) : null}

              <button
                className="primaryButton w-full mt-[50px]"
                onClick={selectExistingSite}
              >
                Submit
              </button>
            </>
          ) : null}
          {modalSection === 4 ? (
            <>
              <div className="close-btn-group">
                <img
                  src={CloseIcon}
                  alt="Close Button"
                  onClick={() => toggleModal(false)}
                />
              </div>
              <p className="modalTitle mt-5">{modalTitle}</p>
              <p className="modalSubtitle">{modalSubTitle}</p>
              {Boolean(errMsg.communityGroup.length) ? (
                <p className="input-field-err-msg">{errMsg.communityGroup}</p>
              ) : null}

              <button
                className="primaryButton w-full mt-[50px]"
                onClick={() => toggleModal(false)}
              >
                Close
              </button>
            </>
          ) : null}
        </div>
      </Modal>
      <Modal
        isOpen={otpModalIsOpen}
        onRequestClose={() => closeVerifyUserModal()}
        style={customStyles}
        // contentLabel="Vessel Modal"
        // overlayClassName="modal-overlay"
        className="vessel-modal"
      >
        <div className="mt-[250px] bg-white rounded-xl p-5 m-4 max-w-[460px] lg:p-[30px]">
          <div className="flex justify-end pb-2">
            <div className="" onClick={() => toggleModal(false)}>
              <X weight="bold" className="text-base font-semibold" />
            </div>
          </div>
          <p className="modalTitle">Please verify OTP</p>
          <p className="modalSubtitle">
            Please enter the OTP code that was sent to your email and/or phone
            number
          </p>

          <input
            type="number"
            value={inputValues.otp}
            name="otp"
            placeholder=" Type your OTP code"
            onChange={handleInputChange}
            autoComplete="text"
            required
            // className={this.props.is_erred ? "outline-input-field-erred" : (this.state.is_focus ? "outline-input-field-active" : "outline-input-field")}
            // className="input-field"
            className={`formInput mt-5 ${Boolean(errMsg.userId.length) ? "border-red-500" : ""
              }`}
          />

          {Boolean(errMsg.otp.length) ? (
            <p className="input-field-err-msg">{errMsg.otp}</p>
          ) : null}

          <button
            className="primaryButton w-full mt-[50px]"
            onClick={submitCommunityOnboardingRequest}
          >
            Submit
          </button>
        </div>
      </Modal>
    </div>
  );
}
