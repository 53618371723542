import React, { useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { debounce } from "lodash";

const HowItWorksDesktop = ({ data, accordion, setAccordion, compId }) => {
  // const [accordion, setAccordion] = useState(1);

  useEffect(() => {
    function calculateVisiblePortion() {
      const targetDiv = document.getElementById(`${compId}`);
      // const viewportHeight = window.innerHeight;
      if (!targetDiv) {
        return;
      }
      const targetRect = targetDiv.getBoundingClientRect();
      console.log(targetRect);
      const scrollPosition = targetRect.top + window.scrollY;

      const targetDivTop = targetRect.top;
      const targetDivBottom = targetRect.bottom;

      // Calculate the portion of the div visible
      // let visiblePortion = 0;
      if (scrollPosition < targetDivTop) {
        // Div is below the viewport
        // visiblePortion = 0;
      } else if (scrollPosition > targetDivBottom) {
        // Div is above the viewport
        // visiblePortion = 0;
        return;
      } else if (
        scrollPosition >= targetDivTop &&
        scrollPosition <= targetDivBottom
      ) {
        // Div is partially or fully visible
        const visibleHeight = scrollPosition - targetDivTop;
        console.log(targetDiv.offsetHeight);

        if (data.length === 2) {
          if (
            visibleHeight <=
            (3 / (data.length + 2)) * targetDiv.offsetHeight
          ) {
            console.log("Default value");
            setAccordion(1);
          } else if (
            visibleHeight >= (3 / (data.length + 2)) * targetDiv.offsetHeight &&
            visibleHeight < (4 / (data.length + 2)) * targetDiv.offsetHeight
          ) {
            console.log("Tab 2");
            setAccordion(2);
          }
        }

        if (data.length === 3) {
          if (
            visibleHeight <=
            (3 / (data.length + 2)) * targetDiv.offsetHeight
          ) {
            console.log("Default value");
            setAccordion(1);
          } else if (
            visibleHeight >= (3 / (data.length + 2)) * targetDiv.offsetHeight &&
            visibleHeight < (4 / (data.length + 2)) * targetDiv.offsetHeight
          ) {
            console.log("Tab 2");
            setAccordion(2);
          } else if (
            visibleHeight >= (4 / (data.length + 2)) * targetDiv.offsetHeight &&
            visibleHeight < (5 / (data.length + 2)) * targetDiv.offsetHeight
          ) {
            console.log("Tab 3");
            setAccordion(3);
          }
        }
        if (data.length === 4) {
          if (
            visibleHeight <=
            (3 / (data.length + 2)) * targetDiv.offsetHeight
          ) {
            console.log("Default value");
            setAccordion(1);
          } else if (
            visibleHeight >= (3 / (data.length + 2)) * targetDiv.offsetHeight &&
            visibleHeight < (4 / (data.length + 2)) * targetDiv.offsetHeight
          ) {
            console.log("Tab 2");
            setAccordion(2);
          } else if (
            visibleHeight >= (4 / (data.length + 2)) * targetDiv.offsetHeight &&
            visibleHeight < (5 / (data.length + 2)) * targetDiv.offsetHeight
          ) {
            console.log("Tab 3");
            setAccordion(3);
          } else if (
            visibleHeight >= (5 / (data.length + 2)) * targetDiv.offsetHeight &&
            visibleHeight < (6 / (data.length + 2)) * targetDiv.offsetHeight
          ) {
            console.log("Tab 3");
            setAccordion(4);
          }
        }
      }
    }

    const debouncedCalculateVisiblePortion = debounce(
      calculateVisiblePortion,
      10
    );

    // Listen for scroll events and update accordion
    window.addEventListener("scroll", debouncedCalculateVisiblePortion);

    return () => {
      window.removeEventListener("scroll", debouncedCalculateVisiblePortion);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="relative">
      <div
        className="hidden md:block w-full max-w-[1267px] mx-auto sticky top-[11vh] z-[998] min-h-[89vh]"
        id="sticky-container"
      >
        <div className="flex w-full justify-between gap-10">
          <div className="w-[2px] h-[full] bg-[#EAECF0] "></div>
          <div className="space-y-[50px]">
            {data.map((e, i) => {
              return (
                <div className="relative h-fit accordionTab" key={i}>
                  {accordion === i + 1 && (
                    <div className="w-[2px] absolute -left-[42px] h-full bg-light-green-500"></div>
                  )}
                  <div className="max-w-[448px]">
                    <button
                      onClick={() => {
                        setAccordion(i + 1);
                      }}
                      className={`rounded-full ${
                        accordion === i + 1
                          ? "bg-light-green-500"
                          : "bg-grey-50"
                      } flex p-2 pr-10 gap-3 center w-fit`}
                    >
                      <div
                        className={`w-[50px] h-[50px] center rounded-full ${
                          accordion === i + 1
                            ? "bg-white"
                            : "bg-light-green-500"
                        }`}
                      >
                        <p className="text-base">0{i + 1}</p>
                      </div>
                      <p className="text-lg font-sora text-green-500 font-medium">
                        {e.label}
                      </p>
                    </button>

                    <AnimatePresence initial={false}>
                      {accordion === i + 1 && (
                        <motion.div
                          className="font-inter overflow-hidden text-sm md:text-base"
                          key="content"
                          initial="collapsed"
                          animate="open"
                          exit="collapsed"
                          variants={{
                            open: { opacity: 1, height: "auto" },
                            collapsed: { opacity: 0, height: 0 },
                          }}
                          transition={{
                            duration: 0.8,
                            ease: [0.04, 0.62, 0.23, 0.98],
                          }}
                        >
                          <div className="h-6"></div>
                         <ol type="a" className="list-[lower-alpha] ml-10 text-xl space-y-6">
                            {e.bodyContent.map((content, index) => {
                              return <li key={index}>{content}</li>;
                            })}
                          </ol>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="bg-green-500 rounded-[12px] overflow-clip lg:rounded-[20px] w-3/5">
            <img
              src={data[accordion - 1]?.image}
              alt=""
              srcset=""
              className="w-full"
            />
          </div>
        </div>
      </div>
      <div className="hidden md:block h-[100vh] w-full" id={compId}></div>
    </div>
  );
};

export default HowItWorksDesktop;
