import React from "react";

import { Helmet } from "react-helmet";
import VirtualIDImage from "../../assets/svg/VirtualID.svg";
import Footer from "../../components/FOOTER/Footer";
import JoinTheBest from "../../components/HOMEPAGE/JoinTheBest";

const VirtualIDPage = () => {
  return (
    <>
      <Helmet>
        <title>
          Updates - Venco | All-in-one Community Management Software
        </title>
        <meta
          property="og:title"
          content="Updates - Venco | All-in-one Community Management Software"
        />
        <meta
          name="twitter:title"
          content="Updates - Venco | All-in-one Community Management Software"
        />
      </Helmet>

      <section className=" w-full bg-white p-1 relative overflow-hidden">
        <div className="container mx-auto px-4 md:px-8 my-10 xl:my-[20px] lg:pb-11 relative z-[1] ">
          <div className="space-y-8 sm:max-w-[300px] md:max-w-full lg:max-w-full lg:p-5  cursor-pointer group">
            <div className="space-y-3 md:space-y-2.5 mt-4">
              <p className="font-sor font-semibold text-new-black-950 capitalize text-2xl">
                Virtual ID is not enabled in your community{" "}
              </p>

              <div className="flex  mt-5  md:mt-0">
                <div className="px-2 py-2.5 flex items-center space-x-3  font-sora text-xs text-new-black-400 font-medium">
                  <div className="w-2 h-2 bg-green-200 rounded-full"> </div>
                  <div>1 min read</div>
                </div>
              </div>

              <div className="h-[1px] w-full md:hidden bg-grey-200 my-[50px] "></div>

              <div className="md:min-h-[150px] overflow-hidden w-full rounded-[6.5px] relative">
                <img
                  src={VirtualIDImage}
                  alt=""
                  className="object-contain w-full h-auto max-h-[200px] md:max-h-[500px]"
                />
              </div>
            </div>
            <div className="">
              <div className="space-y-1 md:space-y-4 mt-0 md:mt-4">
                <div className="text-sm  space-y-5 leading-[22px] font-inter text-new-grey-500">
                  <p className=" md:text-[16px] text-[14px] font-inter">
                  Virtual ID is your personalized identification within your community. It gives you unfettered access to everywhere within your community. If you encounter a checkpoint or road block withing your community, all you need to do is show your Virtual ID to the security personnel and you will be cleared to move on.
                  </p> 
                  <p className=" md:text-[16px] text-[14px] font-inter">
                  It is also useful to keep track of movement of people within your community and aids your security personnel in performing their duties.
                  </p> 
                  <p className=" md:text-[16px] text-[14px] font-inter">
                  Contact your Facility Manager or Join a community to enable Virtual IDs for your community today!
                  </p>             

                  <p className="font-bold text-[14px] text-black capitalize">
                    How To Use Virtual ID
                  </p>
                  <ul className="list-disc">
                    <li className="ml-4">Click on “Profile & IDs” button.</li>
                    <li className="ml-4">
                    Click on “My Virtual ID”.
                    </li>
                    <li className="ml-4">
                      Then present the displayed QR Code to the securities at checkpoints within your community.
                    </li>
                  </ul>

                  <p className="font-bold text-[14px] text-black capitalize">
                  Benefits Of Virtual ID
                  </p>
                  <ul className="list-disc">
                    <li className="ml-4">
                    Virtual ID is a great way to verify the identity of community members at any point in time. It makes it easier to point out strangers who are not cleared to be within the community which in turn creates a more secure community.
                    </li>
                    <li className="ml-4">
                    Virtual ID also grants you unrestricted access to all your community’s amenities.
                    </li>
                  </ul>

                  <p className="font-bold text-[14px] text-black capitalize">
                    How to join a community
                  </p>
                  <ul className="list-disc">
                    <li className="ml-4">Click on the “Join Now” button.</li>
                    <li className="ml-4">
                      Search and select your community by typing the name of
                      your estate.
                    </li>
                    <li className="ml-4">
                      Search and select your property unit by typing the name of
                      your property unit name or number.
                    </li>
                    <li className="ml-4">Click the "Join Now" button.</li>
                  </ul>

                  <p className="font-bold text-[14px] text-black capitalize">
                    Your community didn't show up on the search? What do you do?
                  </p>
                  <p className=" md:text-[16px] text-[14px] font-inter">
                    It's quiet easy actually, contact your Facility Manager to sign up your community on Venco today!
                  </p>

                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <JoinTheBest />
      <Footer />
    </>
  );
};

export default VirtualIDPage;
