import React, { useEffect, useState } from "react";
import CustomPhoneInput from "../INPUTS/CustomPhoneInput";
import { defaultCountries, parseCountry } from "react-international-phone";
import { useSelector } from "react-redux";
import Spinner from "../BUTTONS/Spinner";
import { ReferralSignUp, ResendToken, verifyPhoneNumber } from "./ReferralApi";
import { toast } from "react-toastify";
import { RiCheckFill } from "react-icons/ri";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";
// eslint-disable-next-line
import OtpTimer from "../util/OtpTimer";

const ReferralSignUpForm = () => {
  const [selectedCountry, setSelectedCountry] = useState(
    parseCountry(defaultCountries[138])
  );

  const [step, setStep] = useState(1);
  const presentCountry = useSelector((state) => state.geoDetails.country);

  const handlePhoneNumberInput = (e) => {
    const target = e.target;
    const name = target.name;
    if (target.value.length > 1 && target.value.charAt(0) === "0") {
      // Remove the leading '0'
      setInputValues((prevState) => ({
        ...prevState,
        [name]: target.value.slice(1),
      }));
      return;
    }
    setInputValues((prevState) => ({
      ...prevState,
      [name]: target.value,
    }));
  };

  useEffect(() => {
    if (presentCountry !== "Global") {
      const name = "country";
      setInputValues((prevState) => ({
        ...prevState,
        [name]: presentCountry,
      }));
    }
  }, [presentCountry]);

  const [inputValues, setInputValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    terms_and_condition: true,
  });
  // eslint-disable-next-line
  const [resendOtpVar, setResendOtpVar] = useState(false);

  const handleInputChange = (event) => {
    const target = event.target;
    const value =
      target.type === "checkbox" || target.type === "radio"
        ? target.checked
        : target.value;
    const name = target.name;
    setInputValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [loading, setLoading] = useState(false);

  const handleSubmitRegister = async (event) => {
    const country = selectedCountry;
    const phoneNumber = "+" + country.dialCode + parseInt(inputValues.phoneNo);
    setLoading(true);
    event.preventDefault();
    const userInput = {
      email: inputValues.email,
      firstName: inputValues.firstName,
      lastName: inputValues.lastName,
      phoneNumber: phoneNumber,
      // is_referrer: true,
    };

    await ReferralSignUp(userInput)
      .then(async (response) => {
        sessionStorage.setItem("currentUser", JSON.stringify(response));
        await ResendToken({ id: response.id })
          .then((response) => {
            setLoading(false);
            setStep(2);
          })
          .catch((error) => {
            throw error;
          });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        toast.error(error?.response?.data.message, {
          position: "top-center",
          autoClose: 15000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          zIndex: 1000,
        });
      });
    setLoading(false);
  };

  const handleSubmitOtp = async (event) => {
    const country = selectedCountry;
    const phoneNumber = "+" + country.dialCode + parseInt(inputValues.phoneNo);
    setLoading(true);
    event.preventDefault();

    const userInput = {
      id: JSON.parse(sessionStorage.getItem("currentUser")).id,
      otp: inputValues.otp,
      phone: phoneNumber,
    };

    console.log(userInput);

    await verifyPhoneNumber(userInput)
      .then((response) => {
        setStep(3);
        console.log(response);
        sessionStorage.setItem("referral_link", response.data.referral_link);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.response);
        // console.log(error.response.data);
        toast.error(
          `Error:  ${
            error.response.data.message
              ? error.response.data.message
              : "undefined"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            zIndex: 1000,
          }
        );
        return;
      });
    setLoading(false);
  };

  // eslint-disable-next-line
  const resendOTP = async (event) => {
    event.preventDefault();
    await ResendToken({
      id: JSON.parse(sessionStorage.getItem("currentUser")).id,
    })
      .then((response) => {
        setLoading(false);
        setStep(2);
      })
      .catch((error) => {
        throw error;
      });
  };

  let refferalLink = sessionStorage.getItem("referral_link") ? sessionStorage.getItem("referral_link") :"";

  return (
    <div className="flex-1">
      {step === 1 && (
        <div className="bg-grey-50 rounded-lg px-4 py-10 lg:px-10 lg:py-16 xl:px-[80px] xl:py-[51px] h-full">
          <p className="font-sora font-semibold text-[24px] md:text-2xl lg:text-[40px] text-center xl:max-w-[927px] xl:mx-auto md:text-left">
            Sign Up as A Referrer
          </p>
          <div className="h-6"></div>
          <p className="text-sm font-medium text-center md:text-left">
            Fill out this short form to get started
          </p>

          <form
            action=""
            className="pt-10 flex flex-col gap-4 md:gap-8 xl:min-h-[600px]"
            onSubmit={handleSubmitRegister}
          >
            <div className="flex flex-col md:flex-row gap-4">
              <div className="flex-1">
                <input
                  type="text"
                  value={inputValues.firstName}
                  name="firstName"
                  placeholder="First Name"
                  onChange={handleInputChange}
                  autoComplete="name"
                  required
                  // className={this.props.is_erred ? "outline-input-field-erred" : (this.state.is_focus ? "outline-input-field-active" : "outline-input-field")}
                  className="formInputUpdate"
                />
              </div>
              <div className="flex-1">
                <input
                  type="text"
                  value={inputValues.lastName}
                  name="lastName"
                  id="lastName"
                  placeholder="Last Name"
                  onChange={handleInputChange}
                  autoComplete="name"
                  required
                  className="formInputUpdate"
                />
              </div>
            </div>
            <div className="">
              <input
                type="text"
                value={inputValues.email}
                name="email"
                id="email"
                placeholder="Email Address"
                onChange={handleInputChange}
                autoComplete="email"
                required
                // className={this.props.is_erred ? "outline-input-field-erred" : (this.state.is_focus ? "outline-input-field-active" : "outline-input-field")}
                className="formInputUpdate"
              />
            </div>
            <div>
              <div
                className={`flex font-inter items-center relative gap-1 md:gap-2.5`}
              >
                <CustomPhoneInput
                  selectedCountry={selectedCountry}
                  setSelectedCountry={setSelectedCountry}
                  flag={false}
                />

                <input
                  type="text"
                  value={inputValues.phoneNo}
                  name="phoneNo"
                  placeholder="Phone Number"
                  onChange={handlePhoneNumberInput}
                  autoComplete="tel"
                  required
                  // className={this.props.is_erred ? "outline-input-field-erred" : (this.state.is_focus ? "outline-input-field-active" : "outline-input-field")}
                  className="formInputUpdate"
                />
              </div>
            </div>
            <label className="flex gap-2.5 items-start">
              <input
                type="checkbox"
                required
                value={inputValues.terms_and_condition}
                onChange={(e) => {
                  setInputValues((prevState) => ({
                    ...prevState,
                    terms_and_condition: e.target.value,
                  }));
                }}
                name="isForExistingGroup"
                className="accent-light-green-500"
                checked={inputValues.terms_and_condition}
              />
              <p className="text-xs font-inter">
                I agree with Venco’s Terms of Service and Privacy Policy.
              </p>
            </label>
            {loading ? (
              <button
                className="primaryButton center"
                type="submit"
                disabled={true}
              >
                <Spinner color="black" />
                Loading...
              </button>
            ) : (
              <button className="primaryButton" type="submit">
                Sign Up
              </button>
            )}
          </form>
        </div>
      )}

      {step === 2 && (
        <div className="bg-grey-50 rounded-lg px-4 py-10 lg:px-10 lg:py-16 xl:px-[80px] xl:py-[51px] h-full">
          <p className="font-sora font-semibold text-[24px] md:text-2xl lg:text-[40px] text-center xl:max-w-[927px] xl:mx-auto md:text-left">
            Verify your details
          </p>
          <div className="h-6"></div>
          <p className="text-sm font-medium text-center md:text-left">
            Enter OTP sent to your Phone Number
          </p>

          <form
            action=""
            className="pt-10 flex flex-col gap-4 md:gap-8 xl:min-h-[600px]"
            onSubmit={handleSubmitOtp}
          >
            <div className="">
              <input
                type="number"
                value={inputValues.otp}
                name="otp"
                placeholder=" Type your OTP code"
                onChange={handleInputChange}
                autoComplete="text"
                required
                // className={this.props.is_erred ? "outline-input-field-erred" : (this.state.is_focus ? "outline-input-field-active" : "outline-input-field")}
                className="formInputUpdate"
              />
            </div>
            {/* <p className="text-left text-gray-400 text-lg">
              Didn’t receive code?{" "}
              {resendOtpVar ? (
                <button
                  className="font-medium text-green-500"
                  onClick={resendOTP}
                >
                  Resend
                </button>
              ) : (
                <OtpTimer
                  duration={300}
                  onTimerComplete={() => {
                    console.log("Timer Complete");
                    setResendOtpVar(true);
                  }}
                />
              )}
            </p> */}

            {loading ? (
              <button
                className="primaryButton center"
                type="submit"
                disabled={true}
              >
                <Spinner color="black" />
                Loading...
              </button>
            ) : (
              <button className="primaryButton" type="submit">
                Verify Phone Number
              </button>
            )}
          </form>
        </div>
      )}

      {step === 3 && (
        <div className="bg-grey-50 rounded-lg px-4 py-10 lg:px-10 lg:py-16 xl:px-[80px] xl:py-[51px]">
          <p className="font-sora font-semibold text-[24px] md:text-2xl lg:text-[40px] text-center xl:max-w-[927px] xl:mx-auto md:text-left">
            Successfully Verified
          </p>
          <div className="h-6"></div>
          <p className="text-sm font-medium text-center md:text-left">
            Your referral link has been sent to your email
          </p>

          <div className="h-10" />
          <p className="font-semibold xl:text-xl mb-6">
            Your Unique Referral Link
          </p>

          <div className="flex flex-col md:flex-row gap-5">
            <p className="flex-1 bg-grey-150 px-3 py-[18px] text-sm rounded-md md:rounded-lg">
              {refferalLink}
            </p>
            <button className="primaryButton md:px-[20px] lg:px-[30px]">
              Copy Link
            </button>
          </div>

          <div className="h-10" />
          <p className="font-semibold xl:text-xl mb-6">
            Share your Unique Referral Link
          </p>
          <div className="flex gap-3">
            <TwitterShareButton
              url={refferalLink}
              title={"Use My referral link"}
              className="Demo__some-network__share-button"
            >
              <XIcon size={32} />
            </TwitterShareButton>
            <WhatsappShareButton
              url={refferalLink}
              title={"Use My referral link"}
              separator=":: "
              className="Demo__some-network__share-button"
            >
              <WhatsappIcon size={32} />
            </WhatsappShareButton>
            <LinkedinShareButton
              url={refferalLink}
              className="Demo__some-network__share-button"
            >
              <LinkedinIcon size={32} />
            </LinkedinShareButton>

            <FacebookShareButton
              url={refferalLink}
              className="Demo__some-network__share-button"
            >
              <FacebookIcon size={32} />
            </FacebookShareButton>
          </div>

          <div className="h-16" />
          <button className="primaryButton w-full">Refer your Community</button>
          <div className="h-16" />
          <p className="font-semibold xl:text-2xl mb-6">
            It’s easy to get started
          </p>
          <ul className="space-y-5 text-grey-700 text-sm lg:text-base xl:text-xl">
            <li className="flex gap-2 items-center">
              <div className="h-4 w-4 rounded-full center bg-light-green-500">
                <RiCheckFill className="text-sm" />
              </div>
              <p>Spread the word with your link or on email</p>
            </li>
            <li className="flex gap-2 items-center">
              <div className="h-4 w-4 rounded-full center bg-light-green-500">
                <RiCheckFill className="text-sm" />
              </div>
              <p>They click and onboard their community through your link</p>
            </li>
            <li className="flex gap-2 items-center">
              <div className="h-4 w-4 rounded-full center bg-light-green-500">
                <RiCheckFill className="text-sm" />
              </div>
              <p>When they join, you get 10% as reward</p>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default ReferralSignUpForm;
