export const YourConsent = () => {
  return (
    <section className="">
      <div className="textHeader ">1. Your Consent</div>
      <br />
      <div>
        <p className="textBody">
          1.1. If You do not agree to these terms of this Agreement, do not use
          the application services. By accepting the terms of this Agreement
          below or by signing up to create a Venco profile (“V Profile”) or by
          using the application services in any manner, You acknowledge that You
          have read, understood, and agree to be bound by this Terms of Service,
          and the Related Agreements where applicable. You also agree to comply
          with all other agreements, applicable laws, and regulations
          incorporated into this Terms of Service. The provisions of this Terms
          of Service and the Related Agreements govern Your use of the App, and
          the business relationship between You and Venco, Inc. and it's subsidiaries Venco Platforms Ltd collectively known as Venco.
          <br />
          <br />
          1.2. By clicking <span className="font-bold">“Accept”</span> at the bottom
          of the page, You:
          <ul className="list-disc list-outside pl-4">
            <li>
              Confirm that You are literate and that You have read and fully
              understand the terms of this Terms of Service. You acknowledge and
              agree that Your use of the application services including
              information transmitted to or stored by Venco, is also governed by
              Our Privacy Policy.
            </li>
            <li>
              Consent to us collecting and using technical information about the
              App and related software, hardware, and peripherals to improve our
              products and to provide any other services to You.
            </li>
            <li>
              Consent to us and our affiliates and licensees’ to process the
              transmission, collection, retention, maintenance, and use of Your
              personal data to improve the application services and/or Your
              experience while using the App.
            </li>
            <li>
              Authorize us to provide the application services using the App.
            </li>
            <li>
              Authorize us to share Your Information with third parties to the
              extent required to provide the Application Services and for the
              efficient performance of the App.
            </li>
            <li>
              Agree to follow the rules and fulfil all of your obligations as
              outlined in the constitution, by-laws, rule book, document or set
              of conventions that describe how the community that has adopted
              the Venco platform that you are attempting to access is governed.
            </li>
          </ul>
          <br />
          <br />
          <span>
            The App allows users access a variety of application services and
            provide services which include collection of utilities,
            communications management, visitor management and check-ins
            (“Services”) to residents of residential estates. This privacy
            policy (“Privacy Statement”) sets out the basis upon which We will
            process, store, and disclose any Personal Data that is provided
            voluntarily by users of the App (“Data Subject” or “you” or “your”)
            in connection with the Services that We offer.
            <br />
            <br />
            1.1.  We note that the relevant data privacy regulation distinguishes between entities that are “controllers” of Personal Data and entities that “process” Personal Data. We note that We are a “Data Controller” where We determine the purposes for and the manner in which Personal Data is processed or is to be processed and We are a “Processor” for those Services where We only use Personal Data which are requested by other Data Controllers.
            <br />
            <br />
            1.2. Where We make decisions on how Personal Data is used in connection with Our Services (where We are acting as a controller), We are responsible for the obligations of a “Controller” under the relevant data protection regulations in connection with the processing of Personal Data – for example, We use this Privacy Statement and other notices to provide you with information about Our use of personal information, as required by the relevant data protection regulations. Where We only use Personal Data requested by other Data Controllers (where We are acting as a processor), those other Data Controllers are similarly responsible for the obligations of a “Controller” under applicable laws in connection with the processing of personal information, and, if you are using Our Services through those other Controllers, you should contact them if you have questions or concerns about the processing of your personal information or compliance with the relevant data protection regulations and other applicable law
            <br />
            <br />
            1.3. This Privacy Statement may change from time to time. Unless
            otherwise stated, any updates to this Privacy Statement become
            effective when We post the updates on Our website and App. Your
            continued use of the App for Our Services following an update to the
            Privacy Statement means that you accept the updated Privacy
            Statement.
          </span>
        </p>
      </div>
    </section>
  );
};

export const ApplicationServices = () => {
  return (
    <section className="">
      <div className="textHeader ">2. Application Services</div>
      <br />
      <div>
        <p className="textBody">
          2.1. The App allows You access to a variety of application services
          and provides services which include collection of utilities,
          communications management, visitor management and check-ins{" "}
          <span className="font-bold">(“Application Services”)</span>
          to residents living within residential estates.
          <br />
          <br />
          2.2. Kindly note that Venco may terminate, suspend, change, or
          restrict access to all or any part of the Application Services without
          notice to You And without liability.
        </p>
      </div>
    </section>
  );
};

export const VencoProfile = () => {
  return (
    <section className="">
      <div className="textHeader">3. Venco Profile</div>
      <br />
      <div>
        <p className="textBody">
          3.1. You hereby declare that You are a human being over 18 years of
          age and of a sound mind, or at least the age of majority in the
          jurisdiction where You reside and from which You use the App. Your V
          Profile may be deleted without warning, if we believe, in our sole
          discretion, that You are less than 18 years of age.
          <br />
          <br />
          3.2. If You are executing/accepting these Terms of Service on behalf
          of a third party, any registered company or association, You confirm
          that You are competent to accept/execute the Terms of Service.
          <br />
          <br />
          3.3. To create a Profile and access the Application Services, You
          would be required to download the App and follow through with the
          sign-up / login process. You would be required to provide information
          which may include Your first name, last name, phone number, company
          details, email address, password, photo, date of birth, and any other
          information indicated as required or that we may ask from You from
          time to time <span className="font-bold">(“Information”)</span> . We may
          reject Your application to create a V Profile, or cancel an existing V
          Profile, in Our sole discretion.
          <br />
          <br />
          3.4. You acknowledge that Venco will use the email address and the
          phone number <span className="font-bold">(“Contact Details”)</span> You
          provided to us when You created Your V Profile or as updated by You
          from time to time as the primary method for communicating with You.
          You must monitor the Contact Details You provided to Venco. Your
          Contact Details must be capable of sending/receiving emails or making
          calls and receiving calls. Your communications with Venco can only be
          authenticated if they come from Your Contact Details.
          <br />
          <br />
          3.5. As part of VENCO’s security procedures You determine Your sign-up
          details or any piece of information You input. By signing up for a V
          Profile, You agree to treat Your sign-in details as confidential; do
          not disclose it to any third party. Venco does not have access to Your
          sign-in details, if You forget Your sign-in details or know or suspect
          that a third party gained access to Your sign-in-details You must
          promptly change Your sign-in details, by resetting Your password or by
          contacting us to place a restriction on Your V Profile.
          <br />
          <br />
          3.6. You confirm that all Information You provided to us is true,
          correct and not misleading. You agree to provide any additional
          documents and/or information as may be required from time to time. In
          the event that the Information You provide to us changes, You hereby
          agree to notify us of such changes immediately.
          <br />
          <br />
          4.2. Information about your computer and mobile device hardware and
          software may be automatically collected by Us. This information can
          include: your IP address and location, browser and device name, type
          and version, operating system type and version, language preferences,
          domain names, the information you search for on the Services, access
          times and dates, referring website addresses and other statistics.
          This information is used for the operation of the Service, to maintain
          quality of the Service, and to provide general statistics regarding
          use of the App.
          <br />
          <br />
          4.3. Please keep in mind that if you directly disclose Personal Data
          through Venco public message boards, this information may be collected
          and used by third parties.
          <br />
          <br />
          4.4. Our website and App may contain links to other resources not
          owned or controlled by Us and as such, We encourage you to review the
          privacy statements of websites you choose to link from Our website and
          App so that you can understand how those websites collect, use and
          share your information. Please bear in mind that Venco is not
          responsible and will not be liable for the privacy practices or other
          content of such other resources or third parties.
        </p>
      </div>
    </section>
  );
};
export const GrantOfLicense = () => {
  return (
    <section className="">
      <div className="textHeader ">4. Grant of License</div>
      <br />
      <div>
        <p className="textBody">
          4.1. Subject to the terms of this Agreement, Venco grants to You a
          limited, non-exclusive, non-transferable right to access and use the
          Application Services solely for Your business purposes and not for
          resale or further distribution. Except for the rights expressly
          granted to Estate Manager in this Agreement, Venco and its licensors
          retain all right, title and interest in and to the Application
          Services, including all related rights protected by applicable
          intellectual property laws.
        </p>
      </div>
    </section>
  );
};

export const PaymentTerms = () => {
  return (
    <section className="">
      <div className="textHeader ">5. Payment Terms and Taxes</div>
      <br />
      <div>
        <p className="textBody">
          5.1. While accessing the Application Services, It shall be Your
          responsibility to pay all fees, or charges as may be levied from time
          to time. If there is a problem charging Your selected payment method,
          we may charge any other valid payment method associated with Your V
          Profile. We would display any applicable charges, fees and taxes in
          advance on the relevant part of the App. You are also obligated, where
          applicable, to pay all taxes, fees and other charges imposed by any
          governmental authority, including any value added tax, goods and
          services tax or sales tax (“Taxes”) on the Application Services.
        </p>
      </div>
    </section>
  );
};

export const AcceptableUseRestrictions = () => {
  return (
    <section className="">
      <div className="textHeader">6. Acceptable Use Restrictions</div>
      <br />
      <div>
        <p className="textBody">
          6.1. You are granted a non-exclusive, non-transferable, personal,
          limited license to use the App. Your limited right to use the App is
          revocable at our discretion.
          <br />
          <br />
          6.2. You shall not use the App in any unlawful manner, for any
          unlawful purpose, or in any manner inconsistent with this Terms of
          Service and the Related Agreements , or act fraudulently or
          maliciously, for example, by hacking into or inserting malicious code,
          including viruses, or harmful data, into the App.
          <br />
          <br />
          6.3. You shall not infringe Our intellectual property rights or those
          of any third party in relation to Your use of the App, including the
          submission of any material (to the extent that such use is not
          licensed by this Terms of Service and the Related Agreements.
          <br />
          <br />
          6.5. You shall not use the Application Services to transmit malware or
          host phishing pages. You may not perform activities that harm or
          disrupt the operation of the Application Services or any of our other
          infrastructure, or of others, including our third party providers. You
          may not use the Application Services for deceptive commercial
          practices or any other illegal or deceptive activities.
          <br />
          <br />
          6.6. You shall not extract any information or data from the
          Application Services or our systems or attempt to decipher any
          transmissions to or from the servers running the Application Services.
          <br />
          <br />
          6.7. You shall not use the App or the Application Services in a manner
          that contravenes or that facilitate or promote activities that
          contravene applicable laws or this Terms of Service and the Related
          Agreements.
          <br />
          <br />
          6.8. You shall not use the App in a manner that imply or promote
          support or funding of, or membership in, a terrorist organization.
          <br />
          <br />
          6.9. We may, at any time and without notice, terminate Your V Profile
          or Your access to the Application Services if You engage in activities
          that violate clauses 6.1. to 6.8, including activities outside of Your
          use of the Application Services. <br />
          <br />
          6.10. Without prejudice to clauses 12.1.1 to 12.2.3. herein, You
          hereby indemnify Venco (and its directors, officers, affiliates,
          agents, successors and assigns against all claims, liabilities,
          losses, damages, costs and expenses (including attorney fees and
          related charges) for any breach of the provisions specified in clauses
          6.1 to 6.8 of this Terms of Service. <br />
          <br />
          6.11. Venco shall have the right, but not the obligation, to monitor
          or investigate Your use of the Application Services at any time for
          compliance with this Terms of Service. Our determination of whether a
          breach of this Terms of Service has occurred will be final and
          binding, and any action taken with respect to enforcing the provisions
          specified in this Terms of Service and the Related Agreements,
          including taking no action at all, will be at our sole discretion.
        </p>
      </div>
    </section>
  );
};

export const YourObligations = () => {
  return (
    <section className="">
      <div className="textHeader ">7. Your Obligations</div>
      <br />
      <div>
        <p className="textBody">
          7.1. You shall be responsible for ensuring the proper performance of
          any electronic or other device used to connect to the Application
          Services. Venco shall neither be responsible for any errors or
          failures caused by any malfunction of Your device, nor shall Venco be
          responsible for any computer virus or related problems that may be
          associated with the use of the App and the device. You shall be
          responsible for charges due to any service provider providing You with
          internet connection, and Venco shall not be responsible for losses or
          delays caused by lack of internet connection. <br /> <br />
          7.2. You shall follow all instructions, procedures and terms contained
          in this Terms of Service and any document provided by Venco concerning
          the use of the App and the Application Services. <br /> <br />
          7.3. You shall immediately inform Venco in the event that You have
          reason to believe that unauthorized use of the App has or may have
          occurred or could occur and a transaction may have been fraudulently
          initiated or compromised. <br /> <br />
          7.4. You shall at all times follow the security procedures notified to
          You by Venco from time to time or such other procedures as may be
          applicable to the Services from time to time. In particular, You shall
          ensure that no third party logs in to Your V Profile other than You
          and Your authorized agents. <br /> <br />
          7.5. You shall not at any time operate or use the App in any manner
          that may be prejudicial to Venco.
        </p>
      </div>
    </section>
  );
};
export const ProfessionalService = () => {
  return (
    <section className="">
      <div className="textHeader ">8. Professional Service</div>
      <br />
      <div>
        <div className="textBody">
          8.1. In the event that the Estate Manager purchases other Venco
          services through an Order Form (“Support Services”), then in addition
          to this Terms of Service, the terms and condition of a Service
          Agreement executed by the Estate Manager and Venco shall apply.
          <br />
          <br />
          8.2. The Estate Manager and Venco hereby agree to cooperate in good
          faith to achieve satisfactory completion of the Support Services in a
          professional manner. You agree to provide, at no cost to Venco, timely
          and adequate assistance and other resources reasonably requested by
          Venco to enable the performance of the Support Services (collectively,
          “Assistance”). Neither Venco nor its subcontractors will be liable for
          any deficiency in performance of the Support Services to the extent
          resulting from any of Your acts or omissions, including, but not
          limited to, Your failure to provide Assistance as required hereunder.
          <br />
          <br />
          8.3. In performing the Support Services, the You will provide such
          resources, and utilize employees, service providers or subcontractors
          (“Support Services Personnel”) as it deems necessary to perform the
          Professional Services or any portion thereof. Venco may replace
          Support Services Personnel in its normal course of business or
          subcontract the Support Services.
          <br />
          <br />
          8.4. The Support Services may only be changed through a change order
          mutually executed by the parties (“Change Order”), which may entitle
          Venco to an adjustment in fees pursuant to the changes reflected in
          the Change Order. Venco shall not be obligated to perform any
          differing or additional Support Services unless the parties have
          executed a Change Order.
          <br />
          <br />
          8.5. At VENCO’s request, You shall reimburse Venco for any reasonable
          expenses for travel, lodging, communications, shipping charges and
          out-of-pocket expenses, including change fees to travel and
          accommodations resulting from Venco providing theSupport Services
          (“Expenses”). Venco will provide reasonable documentation for all
          Expenses as requested by You. You shall reimburse Venco for Expenses
          in the month after they are incurred in accordance with the payment
          terms in the Order Form.
          <br />
          <br />
          8.6. Excluding Your Confidential Information and Estate Manager
          Content, Venco shall own all rights, title and interest in any
          reports, presentations and other materials or results generated as a
          result of the Professional Services (“Work Product”), including all
          intellectual property rights therein. In the event that any Work
          Product is held to be owned by You, You hereby assign to Venco all
          right, title and interest therein or, to the extent such assignment is
          not permitted or effective, hereby grants to Venco a perpetual,
          irrevocable, exclusive, worldwide, fully-paid, sub-licensable (through
          multiple layers), assignable license to any such Work Product.
        </div>
      </div>
    </section>
  );
};
export const ProcessingOfPersonalData = () => {
  return (
    <section className="">
      <div className="textHeader ">9. Processing Of Personal Data</div>
      <br />
      <div>
        <p className="textBody">
          9.1. Any personal data that comes into Your possession while performing Your obligations under this Terms of Service must be processed in accordance with the relevant data protection regulation.
          <br />
          <br />
          9.2. You will ensure that You have all necessary appropriate consents
          and notices in place to enable, where necessary, lawful transfer of
          personal data to us for the duration and purposes of this Terms of
          Service.
        </p>
      </div>
    </section>
  );
};
export const CancellationOfProfile = () => {
  return (
    <section className="">
      <div className="textHeader ">
        10. Closing, Restriction & Cancellation of Profile
      </div>
      <br />
      <div>
        <div className="textBody">
          10.1. Venco may at its sole discretion restrict or close Your V
          Profile if:
          <ul className="list-disc list-outside pl-4">
            <li>
              You use Your V Profile for unauthorized purposes or where Venco
              detects any abuse/misuse, breach of content, fraud or attempted
              fraud relating to Your use of the Application Services.
            </li>
            <li>
              Venco is required or requested to comply with an order or
              instruction of or a recommendation from the government, court,
              regulator or other competent authority.
            </li>
            <li>
              Venco reasonably suspects or believes that You are in breach of
              this Terms of Service.
            </li>
            <li>
              Closing or restriction is necessary as a consequence of technical
              problems or for reasons of safety; to facilitate update or upgrade
              the contents or functionality of the Application Services from
              time to time; or where Your V Profile becomes inactive or dormant.
            </li>
            <li>
              Venco decides to suspend or cease the provision of the Application
              Services for commercial reasons or for any other reason as it may
              determine in its absolute discretion.
            </li>
          </ul>
          
          <br />
          10.2. Closing or restriction of Your Profile shall however not affect
          any of our accrued rights and Your liabilities to us.
          <br />
          <br />
          10.3. You may cancel Your V Profile and terminate the Terms of Service
          by giving us 30 (Thirty) days notice of intention to terminate via[]
          or by informing the Facility Manager or other officer responsible for
          managing records on the App.
        </div>
      </div>
    </section>
  );
};
export const LimitationOfLiability = () => {
  return (
    <section className="">
      <div className="textHeader ">11. Limitation of Liability</div>
      <br />
      <div>
        <p className="textBody">
          11.1. Venco shall not be responsible for any loss suffered by You
          should the Application Services be interfered with or be unavailable
          by reason of the failure of any of Your device, lack of internet
          connection or any other circumstances whatsoever not within our
          control including, without limitation, system error, interruption,
          delay or non- availability of the Application Services, loss of power,
          adverse weather or atmospheric conditions, and failure of any public
          or private telecommunications system.
          <br />
          <br />
          11.2. You hereby agree that, to the extent permitted by applicable
          laws, Venco shall not be liable for any direct, indirect, incidental,
          special, consequential or exemplary damages, including but not limited
          to, damages for loss of profits, goodwill, use, data or other
          intangible losses resulting from the use of or inability to use the
          Application Services.
          <br />
          <br />
          11.3. Venco shall have no liability to You for any loss of profit,
          loss of business, business interruption, or loss of business
          opportunity as a result of Your unauthorized use of the App.
          <br />
          <br />
          11.4. Venco will not be liable for any losses or damage suffered by
          You as a result of or in connection with:
          <ul className="list-disc list-outside pl-4">
            <li>
              any effect or fault in the App or the Application Services
              resulting from You having altered or modified the App;
            </li>
            <li>
              any defect or fault in the App resulting from You having used the
              App in breach of this Terms of Service ; and
            </li>
            <li>
              Your breach of any of the provisions of this Terms of Service and
              the Related Agreements.
            </li>
          </ul>
          <br />
          <br />
          11.5. Venco does not warrant that the Application Services will be
          uninterrupted, timely, and secure or error-free. All warranties and
          obligations implied by law are hereby excluded to the fullest extent
          permitted by law.
          <br />
          <br />
          11.6. If the Application Services are interfered with or unavailable,
          our sole liability under this Terms of Service in respect thereof
          shall be to re-establish the Application Services as soon as
          reasonably possible.
        </p>
      </div>
    </section>
  );
};
export const Indemnity = () => {
  return (
    <section className="">
      <div className="textHeader ">12. Indemnity</div>
      <br />
      <div>
        <div className="textBody">
          12.1. You hereby agree to indemnify and hold harmless Venco (and its
          directors, officers, affiliates, agents, successors and assigns
          against all claims, liabilities damages, costs and expenses (including
          attorney fees and related charges) which Venco suffers or incurs or
          sustains thereby, and You absolve Venco from all liability for loss or
          damage which You may sustain from Venco acting on Your instructions
          and/or instructions coming from Your V Profile in accordance with
          these Terms of Service.
          <br />
          <br />
          12.2. The indemnity in clause 12.1 above, shall also cover the
          following:
          <br />
          <br />
          12.2.1. Any loss or damage that may arise from Your use, misuse, abuse
          or possession of any third-party software, including without
          limitation, any operating system, browser software or any other
          software packages or programs.
          <br />
          <br />
          12.2.2. Any unauthorized access to Your V Profile.
          <br />
          <br />
          12.2.3. Any loss or damage occasioned by Your failure to adhere to
          this Terms of Service and/or by supplying incorrect information
        </div>
      </div>
    </section>
  );
};
export const EntireAgreement = () => {
  return (
    <section className="">
      <div className="textHeader ">13. Entire Agreement</div>
      <br />
      <div>
        <div className="textBody">
          13.1. This Terms of Service and the Related Agreements constitute the
          entire agreement between You and us and supersede and extinguish all
          previous agreements, promises, assurances, warranties, representations
          and understandings between us, whether written or oral, relating to
          its subject matter.
          <br />
          <br />
          13.2. You acknowledge that You do not rely on any statement,
          representation, assurance or warranty (whether made innocently or
          negligently) that is not set out in these Terms of Service and the
          Related Agreements
        </div>
      </div>
    </section>
  );
};
export const ForceMajeure = () => {
  return (
    <section className="">
      <div className="textHeader ">14. Force Majeure</div>
      <br />
      <div>
        <div className="textBody">
          14.1. Venco shall not be in breach of this Terms of Service and the
          Related Agreements nor liable for delay in performing, or failure to
          perform, any of its obligations if such delay or failure results from
          events, circumstances or causes beyond our control. In such
          circumstances the time for performance shall be extended by a period
          equivalent to the period during which performance of the obligation
          has been delayed or failed to be performed.
        </div>
      </div>
    </section>
  );
};
export const Assignment = () => {
  return (
    <section className="">
      <div className="textHeader ">15. Assignment</div>
      <br />
      <div>
        <div className="textBody">
          15.1. You shall not assign any of Your rights and obligations under
          this Terms of Service and the Related Agreements without the written
          consent of Venco.
        </div>
      </div>
    </section>
  );
};
export const Severance = () => {
  return (
    <section className="">
      <div className="textHeader ">16. Severance</div>
      <br />
      <div>
        <div className="textBody">
          16.1. If any provision of this Terms of Service and the Related
          Agreements is determined by any court of competent jurisdiction or
          arbitrator to be invalid, illegal, or unenforceable to any extent,
          that provision shall, if possible, be construed as though more
          narrowly drawn, if a narrower construction would avoid such
          invalidity, illegality, or unenforceability or, if that is not
          possible, such provision shall, to the extent of such invalidity,
          illegality, or unenforceability, be severed, and the remaining
          provisions of this Terms of Service and the Related Agreements shall
          remain in effect.
        </div>
      </div>
    </section>
  );
};
export const NoPartnership = () => {
  return (
    <section className="">
      <div className="textHeader ">17. No Partnership or Agency</div>
      <br />
      <div>
        <div className="textBody">
          17.1. This Terms of Service and the Related Agreements shall not be
          construed as creating any partnership, joint venture, agency or
          similar relationship between You and Venco other than the relationship
          with respect to carrying out the terms of this Terms of Service and
          the Related Agreements. All rights, duties, obligations, and
          liabilities of Venco and You shall be separate, individual and several
          and not joint or joint and several. Except as set forth in this Terms
          of Service and the Related Agreements, You have no authority to act as
          agent for Venco or bind Venco in any way.
        </div>
      </div>
    </section>
  );
};
export const ApplicableLaw = () => {
  return (
    <section className="">
      <div className="textHeader ">
        18. Applicable Law and Dispute Resolution
      </div>
      <br />
      <div>
        <div className="textBody">
          18.1. This Terms of Service and all matters incidental thereto shall
          be governed and construed in accordance with the laws of Federal
          Republic of Nigeria. Any dispute arising out of or in connection with
          this Agreement or the breach, termination or invalidity thereof
          (“Dispute”) shall, to the extent possible be settled amicably by
          negotiation and discussion between the parties. Where parties are
          unable to resolve the Dispute amicably within 14 (fourteen) days of
          such Dispute arising, the Dispute shall be referred to mediation at
          the Lagos Multi-Door Courthouse, whose rules are deemed to be
          incorporated by reference into this clause. If the Dispute is still
          not resolved after 30 (thirty) days of its being referred to
          mediation, each party irrevocably agrees that the Dispute shall be
          finally resolved by a Nigerian court of competent jurisdiction which
          shall have exclusive jurisdiction to settle such Dispute.
        </div>
      </div>
    </section>
  );
};
