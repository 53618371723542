import { ArrowUpRight, CaretDown, CaretUp } from "phosphor-react";
import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { faqQuestions } from "./FAQList";
import { Link } from "react-router-dom";

export const FAQComponent = ({ question, answer, isOpen, onClick }) => {
  return (
    <motion.div
      className={`p-4 md:p-[30px] bg-light-purple-100 border-new-grey-100 rounded-xl `}
      initial={false}
      animate={{ backgroundColor: isOpen ? "#F0ECFC" : "#F8F8F7" }}
      onClick={onClick}
    >
      <div className={`flex justify-between items-center ${isOpen && "mb-4"}`}>
        <p className="font-semibold text-sm font-inter lg:text-xl">
          {question}
        </p>
        <div className="p-1 md:p-4 border rounded-full border-light-purple-300">
          {isOpen ? <CaretUp /> : <CaretDown />}
        </div>
      </div>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            className="max-w-[824px] font-inter leading-[26px] overflow-hidden text-sm md:text-base"
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            {answer}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(-1);
  const handleItemClick = (index) => {
    if (index === openIndex) {
      // Clicked on an already open item, so close it.
      setOpenIndex(-1);
    } else {
      // Clicked on a new item, so open it and close the previously open one.
      setOpenIndex(index);
    }
  };
  return (
    <div className="xl:my-[170px]">
      <div className="container mx-auto px-4  xl:mt-24 flex flex-col items-center justify-center md:w-[65%] xl:mb-[70px] lg:w-[75%] ">
        <p
          className="font-sora text-center text-[30px] leading-[40px] font-semibold my-4 xl:my-5 xl:text-5xl xl:leading-[60px]"
          style={{ textWrap: "balance" }}
        >
          Have you got Questions?
        </p>
        <p className="font-inter font-medium text-[4D4D4D] text-center">
          We are always available to give response to your questions
        </p>
      </div>
      <section className="container mx-auto xl:max-w-[1218px] p-4 pb-10">
        <div className="space-y-4">
          {faqQuestions.slice(0, 5).map((item, index) => {
            return (
              <FAQComponent
                key={index}
                question={item.question}
                answer={item.answer}
                isOpen={index === openIndex}
                onClick={() => handleItemClick(index)}
              />
            );
          })}
        </div>
        <div className="flex ">
          <Link
            to="/faq"
            className="flex py-4 px-10 border-green-500  text-green-500 rounded-lg border font-semibold items-center justify-center gap-2.5 font-sora hover:bg-light-green-100 duration-300 transition-all text-sm md:text-base mx-auto mt-[60px]"
          >
            <p>See all FAQs</p>
            <ArrowUpRight className="text-[20px]" />
          </Link>
        </div>
      </section>
    </div>
  );
};

export default FAQ;
