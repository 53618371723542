import React, { useState } from "react";
import security from "../../assets/images/security.webp";
import residents from "../../assets/images/residents.webp";
import admin from "../../assets/images/admin.webp";
import { ArrowRight } from "phosphor-react";
import { useNavigate } from "react-router-dom";

const FeaturesTab = () => {
  const [currentTab, setCurrentTab] = useState(0);

  const navigate = useNavigate();

  return (
    <>
      <div className="h-fit relative xl:mt-[170px] container mx-auto mt-16">
        <section className=" p-4 sticky top-[calc(12vh)] z-[10]  ">
          <div className="p-1 bg-grey-50 rounded-full flex items-center mb-6 max-w-[600px] xl:w-[819px] mx-auto  ">
            <div
              className={`rounded-full py-4 lg:py-6  flex-1 ${
                currentTab === 0 && "bg-light-green-500"
              }  hover:bg-light-green-500 cursor-pointer center transition-all duration-500`}
              onClick={() => {
                setCurrentTab(0);
              }}
            >
              <p className="font-sora text-xs lg:text-lg font-medium">
                <span className="hidden md:inline">For </span> FMs
              </p>
            </div>
            <div
              className={`rounded-full py-4 lg:py-6  flex-1 ${
                currentTab === 1 && "bg-light-green-500"
              }  hover:bg-light-green-500 cursor-pointer center transition-all duration-700 ease-in-out`}
              onClick={() => {
                setCurrentTab(1);
              }}
            >
              <p className="font-sora text-xs lg:text-lg font-medium">
                <span className="hidden md:inline">For </span>Residents
              </p>
            </div>
            <div
              className={`rounded-full py-4 lg:py-6  flex-1 ${
                currentTab === 2 && "bg-light-green-500"
              }  hover:bg-light-green-500 cursor-pointer center transition-all duration-500`}
              onClick={() => {
                setCurrentTab(2);
              }}
            >
              <p className="font-sora text-xs lg:text-lg font-medium">
                <span className="hidden md:inline">For </span> Security
              </p>
            </div>
          </div>
        </section>
        <div
          className="container mx-auto
      md:rounded-[20px] overflow-hidden"
        >
          <div
            className={`flex w-[300%] duration-1000 ease-in-out transition-all ${
              currentTab === 1 && "-translate-x-1/3"
            } ${currentTab === 2 && "-translate-x-2/3"}`}
          >
            <div className="bg-light-purple-200 flex flex-col md:flex-row w-1/3 md:overflow-hidden">
              <div className="md:w-[40%]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 732 813"
                  fill="none"
                >
                  <defs>
                    <clipPath id="shape">
                      <path
                        fill="#CBF07F"
                        d="M.895.746v812h541.207c10.08 0 19.746-3.967 26.874-11.028 7.127-7.061 11.131-16.638 11.131-26.623V634.329c0-4.95.986-9.852 2.9-14.424a37.62 37.62 0 0 1 8.256-12.223 38.032 38.032 0 0 1 12.352-8.157 38.298 38.298 0 0 1 14.565-2.847h75.109c5.014 0 9.978-.983 14.606-2.892a38.01 38.01 0 0 0 12.364-8.23 37.625 37.625 0 0 0 8.22-12.305 37.353 37.353 0 0 0 2.816-14.49V.746H.895Z"
                      />
                    </clipPath>
                  </defs>
                  <image
                    clip-path="url(#shape)"
                    xlinkHref={admin}
                    height={"100%"}
                  ></image>
                </svg>
              </div>

              <div className="py-20 px-4 center  md:w-[60%] md:p-10">
                <div className="flex flex-col gap-[30px] items-center justify-center md:items-start text-light-blue-950">
                  <p className="text-center text-2xl font-sora font-semibold text-new-black-1000 lg:text-3xl xl:text-[40px]">
                    Venco for Facility Managers
                  </p>
                  <p className="font-inter text-sm leading-[22px] text-center md:text-left lg:text-base xl:text-[20px] xl:leading-[150%] md:w-[300px] lg:w-[400px] xl:w-[547px]">
                    Our all-in-one app for managing communities allows admins to
                    configure bills and tariffs, automate collections and
                    reconciliations, implement revenue assurance measures,
                    communicate individually or generally with residents, track
                    issues and generate tonnes of reports that provide
                    information on how well the community is running - all from
                    your mobile phone.
                  </p>
                  <button
                    className="flex py-4 px-10 border-green-500  text-green-500 rounded-lg border font-semibold items-center justify-center gap-2.5 hover:bg-light-green-100 duration-300 transition-all text-sm lg:text-base w-full md:w-fit"
                    onClick={() => {
                      navigate("/products/admin");
                    }}
                  >
                    <p>Learn More</p>
                    <ArrowRight className="h-4" />
                  </button>
                </div>
              </div>
            </div>
            <div className="bg-blue-150 flex flex-col md:flex-row w-1/3 md:overflow-hidden">
              <div className="md:w-[40%]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 732 813"
                  fill="none"
                >
                  <defs>
                    <clipPath id="shape">
                      <path
                        fill="#CBF07F"
                        d="M.895.746v812h541.207c10.08 0 19.746-3.967 26.874-11.028 7.127-7.061 11.131-16.638 11.131-26.623V634.329c0-4.95.986-9.852 2.9-14.424a37.62 37.62 0 0 1 8.256-12.223 38.032 38.032 0 0 1 12.352-8.157 38.298 38.298 0 0 1 14.565-2.847h75.109c5.014 0 9.978-.983 14.606-2.892a38.01 38.01 0 0 0 12.364-8.23 37.625 37.625 0 0 0 8.22-12.305 37.353 37.353 0 0 0 2.816-14.49V.746H.895Z"
                      />
                    </clipPath>
                  </defs>
                  <image
                    clip-path="url(#shape)"
                    xlinkHref={residents}
                    height={"100%"}
                  ></image>
                </svg>
              </div>

              <div className="py-20 px-4 center  md:w-[60%] md:p-10">
                <div className="flex flex-col gap-[30px] items-center justify-center md:items-start text-light-blue-950">
                  <p className="text-center text-2xl font-sora font-semibold text-new-black-1000 lg:text-3xl xl:text-[40px]">
                    Venco for Residents
                  </p>
                  <p className="font-inter text-sm leading-[22px] text-center md:text-left lg:text-base xl:text-[20px] xl:leading-[150%] md:w-[300px] lg:w-[400px] xl:w-[547px]">
                    Pay bills easily, receive immediate value for payments, vend
                    energy any time and from anywhere, book in visitors securely
                    without stress, download electronic statements and utility
                    bills; report and track issues right from the app
                  </p>
                  <button
                    className="flex py-4 px-10 border-green-500  text-green-500 rounded-lg border font-semibold items-center justify-center gap-2.5 hover:bg-light-green-100 duration-300 transition-all text-sm lg:text-base w-full md:w-fit"
                    onClick={() => {
                      navigate("/products/residents");
                    }}
                  >
                    <p>Learn More</p>
                    <ArrowRight className="h-4" />
                  </button>
                </div>
              </div>
            </div>
            <div className="bg-light-green-500 flex flex-col md:flex-row w-1/3 md:overflow-hidden">
              <div className="md:w-[40%]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 732 813"
                  fill="none"
                >
                  <defs>
                    <clipPath id="shape">
                      <path
                        fill="#CBF07F"
                        d="M.895.746v812h541.207c10.08 0 19.746-3.967 26.874-11.028 7.127-7.061 11.131-16.638 11.131-26.623V634.329c0-4.95.986-9.852 2.9-14.424a37.62 37.62 0 0 1 8.256-12.223 38.032 38.032 0 0 1 12.352-8.157 38.298 38.298 0 0 1 14.565-2.847h75.109c5.014 0 9.978-.983 14.606-2.892a38.01 38.01 0 0 0 12.364-8.23 37.625 37.625 0 0 0 8.22-12.305 37.353 37.353 0 0 0 2.816-14.49V.746H.895Z"
                      />
                    </clipPath>
                  </defs>
                  <image
                    clip-path="url(#shape)"
                    xlinkHref={security}
                    height={"100%"}
                  ></image>
                </svg>
              </div>

              <div className="py-20 px-4 center  md:w-[60%] md:p-10">
                <div className="flex flex-col gap-[30px] items-center justify-center md:items-start">
                  <p className="text-center text-2xl font-sora font-semibold text-new-black-1000 lg:text-3xl xl:text-[40px]">
                    Venco for Security
                  </p>
                  <p className="font-inter text-sm leading-[22px] text-center text-[#4D4D4D] md:text-left lg:text-base xl:text-[20px] xl:leading-[150%] md:w-[300px] lg:w-[400px] xl:w-[547px]">
                    Implement high-level security without breaking the bank with
                    our code-based access, Virtual IDs and NFC-based digital
                    cards and car-stickers. Already have physical access
                    devices? Not to worry, Venco integrates with all popular
                    electronic boom-barriers and turnstiles.
                  </p>
                  <button
                    className="flex py-4 px-10 border-green-500  text-green-500 rounded-lg border font-semibold items-center justify-center gap-2.5 hover:bg-light-green-100 duration-300 transition-all text-sm lg:text-base w-full md:w-fit"
                    onClick={() => {
                      navigate("/products/security");
                    }}
                  >
                    <p>Learn More</p>
                    <ArrowRight className="h-4" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeaturesTab;
