import React from "react";
import { RiBardFill } from "react-icons/ri";
import smartAccess from "../../assets/images/SmartAccess.svg";
import onlineBill from "../../assets/images/onlineBill.svg";
import property from "../../assets/images/property.svg";
import report from "../../assets/images/reportIssues.svg";
import emergency from "../../assets/images/emergencyReporting.svg";
import amenities from "../../assets/images/AmmenitiesScheduling.svg";

const ResidentFeatures = () => {
  return (
    <section className="bg-[#f8f8f8] py-10 pb-20">
      <div className="container mx-auto px-4 mt-5 xl:mt-24 flex flex-col items-center justify-center md:w-[65%] xl:mb-[70px] lg:w-[75%] ">
        <div className="inline-flex  items-center bg-light-purple-100 gap-2.5 px-5 py-2 rounded-full mx-auto justify-center ">
          <RiBardFill className="text-light-purple-600 text text-2xl " />
          <p className="font-inter text-xs md:text-xl text-new-black-1000 font-medium">
            Features
          </p>
        </div>
        <p
          className="font-sora text-center text-[30px] leading-[40px] font-semibold my-4 xl:my-5 xl:text-5xl xl:leading-[60px]"
          style={{ textWrap: "balance" }}
        >
          Simplifying Living Experiences Across Africa
        </p>
      </div>
      <div className="space-y-4 md:space-y-10 p-4">
        <div className="mx-auto container flex flex-col sm:flex-row gap-4 md:gap-10 justify-center  ">
          <div className="rounded-lg border border-light-purple-500 flex-1 max-w-[594px] overflow-hidden bg-white">
            <div className="p-6 xl:p-[66px] xl:pb-[45px] ">
              <p className="font-sora font-semibold text-new-black-1000 sm:text-lg lg:text-2xl">
                Smart Access Control
              </p>
              <p className="font-inter text-xs text-[#4D4D4D] mt-3 leading-[150%]   lg:text-base xl:leading-[26px]">
                Use our low-cost, low-maintenance resident and visitor
                identification tools to improve security in your community. Also
                integrates easily with 3rd party physical access devices.
              </p>
            </div>
            <div className="bg-light-purple-200 h-[287px] md:h-[320px] lg:h-[468px] relative  overflow-hidden">
              <img
                src={smartAccess}
                alt=""
                className="absolute left-1/2 -translate-x-1/2 xl:top-[59px] scale-[80%] md:scale-[80%] lg:scale-100 lg:top-14"
              />
            </div>
          </div>
          <div className="rounded-lg border border-light-purple-500 flex-1 max-w-[594px] overflow-hidden bg-white">
            <div className="p-6 xl:p-[66px] xl:pb-[45px] ">
              <p className="font-sora font-semibold text-new-black-1000 sm:text-lg lg:text-2xl">
                Online Bill & Utility Payments
              </p>
              <p className="font-inter text-xs text-[#4D4D4D] mt-3 leading-[150%]   lg:text-base xl:leading-[26px]">
                Simplify billing, collections, and payments. Prevent late fees
                with integrated revenue assurance. Streamline online utility
                payments and offer residents the lowest convenience fees
                available.
              </p>
            </div>
            <div className="bg-light-purple-200 h-[287px] md:h-[320px] lg:h-[468px] relative  overflow-hidden">
              <img
                src={onlineBill}
                alt=""
                className="absolute left-1/2 -translate-x-1/2 xl:top-[59px] scale-[80%] md:scale-[80%] lg:scale-100 lg:top-14"
              />
            </div>
          </div>
        </div>
        <div className="mx-auto container flex flex-col sm:flex-row gap-4 md:gap-10 justify-center  ">
          <div className="rounded-lg border border-light-purple-500 flex-1 max-w-[594px] overflow-hidden bg-white">
            <div className="p-6 xl:p-[66px] xl:pb-[45px] ">
              <p className="font-sora font-semibold text-new-black-1000 sm:text-lg lg:text-2xl">
                Property Dashboard
              </p>
              <p className="font-inter text-xs text-[#4D4D4D] mt-3 leading-[150%]   lg:text-base xl:leading-[26px]">
                Access all information about your property in one place –
                property data, billing and payment history, pre-paid meter
                assignment, energy purchase and consumption history and much
                more.
              </p>
            </div>
            <div className="bg-light-purple-200 h-[287px] md:h-[320px] lg:h-[468px] relative  overflow-hidden">
              <img
                src={property}
                alt=""
                className="absolute left-1/2 -translate-x-1/2 xl:top-[48px] scale-[80%] md:scale-[80%] lg:scale-100 lg:top-14"
              />
            </div>
          </div>
          <div className="rounded-lg border border-light-purple-500 flex-1 max-w-[594px] overflow-hidden bg-white">
            <div className="p-6 xl:p-[66px] xl:pb-[45px] ">
              <p className="font-sora font-semibold text-new-black-1000 sm:text-lg lg:text-2xl">
                Report Issues
              </p>
              <p className="font-inter text-xs text-[#4D4D4D] mt-3 leading-[150%]   lg:text-base xl:leading-[26px] md:min-h-[104px]">
                Raise and track issues directly from the Mobile App anywhere you
                are.
              </p>
            </div>
            <div className="bg-light-purple-200 h-[287px] md:h-[320px] lg:h-[468px] relative  overflow-hidden">
              <img
                src={report}
                alt=""
                className="absolute left-1/2 -translate-x-1/2 xl:top-[59px] scale-[80%] md:scale-[80%] lg:scale-100 lg:top-14"
              />
            </div>
          </div>
        </div>
        <div className="mx-auto container flex flex-col sm:flex-row gap-4 md:gap-10 justify-center">
          <div className="rounded-lg border border-light-purple-500 flex-1 max-w-[594px] overflow-hidden">
            <div className="p-6 xl:p-[66px] xl:pb-[45px] ">
              <p className="font-sora font-semibold text-new-black-1000 sm:text-lg lg:text-2xl">
                24/7 Emergency Reporting
              </p>
              <p className="font-inter text-xs text-[#4D4D4D] mt-3 leading-[150%]   lg:text-base xl:leading-[26px] md:min-h-[104px]">
                Report emergencies within your community as they happen and
                receive direct responses from the right authority.
              </p>
            </div>
            <div className="bg-light-purple-200 h-[287px] md:h-[320px] lg:h-[468px] relative  overflow-hidden">
              <img
                src={emergency}
                alt=""
                className="absolute left-1/2 -translate-x-1/2 xl:top-[59px] scale-[80%] md:scale-[80%] lg:scale-100 lg:top-14 mt-14"
              />
            </div>
          </div>
          <div className="rounded-lg border border-light-purple-500 flex-1 max-w-[594px] overflow-hidden">
            <div className="p-6 xl:p-[66px] xl:pb-[45px] ">
              <p className="font-sora font-semibold text-new-black-1000 sm:text-lg lg:text-2xl">
                Amenities Scheduling
              </p>
              <p className="font-inter text-xs text-[#4D4D4D] mt-3 leading-[150%]   lg:text-base xl:leading-[26px] md:min-h-[104px]">
                Avoid clashing with neighbours for shared amenities by
                scheduling them when you want to use them.
              </p>
            </div>
            <div className="bg-light-purple-200 h-[287px] md:h-[320px] lg:h-[468px] relative  overflow-hidden">
              <img
                src={amenities}
                alt=""
                className="absolute left-1/2 -translate-x-1/2 xl:top-[59px] scale-[80%] md:scale-[80%] lg:scale-100 lg:top-14"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResidentFeatures;
