import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player';
import LoadingJson from '../assets/animations/loading.json'

const Loading = () => {
  return (
    <div className='flex flex-1 bg-white h-screen w-full justify-center items-center'>
      <Player src={LoadingJson} loop autoplay speed={1}/>
    </div>
  )
}

export default Loading