import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import Footer from "../components/FOOTER/Footer";
import NavBarDark from "../components/NAVBAR/NavBarDark";
import { Helmet } from "react-helmet";
import Loading from "../components/Loading";
// import unlock from "../assets/images/unlock.svg";
import { verify_request } from "../api/auth";

const VerifyEmailPage = () => {
  const [isLoading, seIsLoading] = useState(true)
  const [showGoToApp, setShowGoToApp] = useState(false)
  
  const [verificationRes, setVerificationRes] = useState({
    title: '',
    message: ''
  })
  const { tmp_id } = useParams();

  useEffect(() => {
    verifyEmail()
    // eslint-disable-next-line 
  }, [])

  const verifyEmail = async () => {
    try {
      seIsLoading(true)
      const res = await verify_request(tmp_id)
      const response = await res.json()
      setVerificationRes(response)
      checkUserAgent()
      seIsLoading(false)
    } catch (error) {
      setVerificationRes({
        title: '',
        message: ''
      })
    }
  }

  function checkUserAgent () {
    console.log('navigator.userAgent', navigator.userAgent);
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      // || navigator.userAgent.match(/BlackBerry/i)
      // || navigator.userAgent.match(/Windows Phone/i)
    ) {
        setShowGoToApp(true)
        // $('.primary-action').html("<a href='vencoafrica://home' class='btn btn-primary btn-sm'>Go To App</a>");
      }

	}


  return (
    <>
      <Helmet>
        <title>Verify Email - Venco | All-in-one Community Management Software</title>
        <meta
          property="og:title"
          content="Verify Email - Venco | All-in-one Community Management Software"
        />
        <meta
          name="twitter:title"
          content="Verify Email - Venco | All-in-one Community Management Software"
        />
      </Helmet>
      <NavBarDark />
      {isLoading && (
        <div className="h-[420px] flex flex-col items-center justify-center">
          <Loading />
        </div>
      )}
      {!isLoading && (
        <div className="flex flex-col items-center justify-center md:p-4 py-16 lg:py-24">
          <p className="font-sora text-xl font-semibold text-black text-balance text-center mx-20 lg:mx-100 lg:text-4xl lg:leading-[150%] xl:text-5xl xl:leading-[150%]">            
            {verificationRes.message}
          </p>
          <div className="flex justify-center md:justify-start gap-4 flex-col lg:flex-row mt-[30px]">
            {
              showGoToApp && (
                <Link
                  to="vencoafrica://home"
                  className="py-4 px-10 text-green-500 bg-light-green-500 hover:bg-light-green-600 rounded-lg font-semibold duration-300 transition-all text-sm md:text-base xl:text-lg text-center flex center gap-1.5 mb-10"
                >
                  Go To App{" "}
                </Link>
              )
            }
          
          </div>
        </div>
      )}
     
      <Footer />
    </>
  );
};

export default VerifyEmailPage;
