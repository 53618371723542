import { X } from "phosphor-react";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const Cookies = () => {
  const [displayCookie, setDisplayCookie] = useState(true);
  const handleCookieClose = () => {
    setDisplayCookie(false);
    // You can also set a flag in localStorage to remember that the user has closed the cookie component
    localStorage.setItem("cookieAccepted", "true");
  };
  return (
    <>
      {displayCookie ? (
        <div className="fixed bottom-0 md:bottom-8   z-[1999] w-full">
          <div className="space-y-5 p-5 w-full bg-white lift md:hidden">
            <div className="flex justify-end">
              <X
                weight="bold"
                className="text-grey-400 cursor-pointer"
                onClick={handleCookieClose}
              />
            </div>
            <p className="font-inter font-semibold text-green-400 text-sm">
              By using our website, you agree to use cookies stated in our{" "}
              <Link
                to="/privacy-policy"
                className="text-light-purple-500 underline underline-offset-4"
              >
                Privacy Policy
              </Link>
            </p>
            <div className="flex gap-3">
              <button
                className="secondaryButton w-full text-green-400 text-xs"
                onClick={handleCookieClose}
              >
                Reject Cookies
              </button>
              <button
                className="primaryButton w-full text-green-400 text-xs"
                onClick={handleCookieClose}
              >
                Accept Cookies
              </button>
            </div>
          </div>

          <div className="w-fit hidden md:flex items-center justify-between p-5  bg-white lift mx-auto h-[104px] lg:p-10 gap-10">
            <p className="font-inter font-medium text-green-400 ">
              By using our website, you agree to use cookies stated in our{" "}
              <Link
                to="/privacy-policy"
                className="text-light-purple-500 underline underline-offset-4"
              >
                Privacy Policy
              </Link>
            </p>

            <div className="flex items-center gap-8">
              <div className="flex gap-3">
                <button
                  className=" w-full text-green-400 text-sm whitespace-nowrap hover:underline underline-offset-4 transition-all duration-300 font-medium"
                  onClick={handleCookieClose}
                >
                  Reject Cookies
                </button>
                <button
                  className="primaryButton w-full text-green-400 text-sm whitespace-nowrap px-6 font-medium font-inter"
                  onClick={handleCookieClose}
                >
                  Accept Cookies
                </button>
              </div>

              <div className="flex justify-end">
                <X
                  weight="bold"
                  className="text-grey-400 cursor-pointer"
                  onClick={handleCookieClose}
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Cookies;
