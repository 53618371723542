import React from "react";
// import NavBar from "../components/NAVBAR/NavBar";
import NavBarDark from "../components/NAVBAR/NavBarDark";

import ResidentsHero from "../components/RESIDENTS/ResidentsHero";
import Stats from "../components/HOMEPAGE/Stats";
import ResidentFeatures from "../components/RESIDENTS/ResidentFeatures";
import SimplifiedPayment from "../components/RESIDENTS/SimplifiedPayment";
import Footer from "../components/FOOTER/Footer";
import JoinTheBest from "../components/HOMEPAGE/JoinTheBest";
import FAQ from "../components/FAQ/FAQ";
import Testimonial from "../components/HOMEPAGE/Testimonial";
import { Helmet } from "react-helmet";
import HowCTAComp from "../components/HowItWorks/HowCTAComp";

const ResidentsPage = () => {
  return (
    <>
      <Helmet>
        <title>
          Residents - Venco | All-in-one Community Management Software
        </title>
        <meta
          property="og:title"
          content="Residents - Venco | All-in-one Community Management Software"
        />
        <meta
          name="twitter:title"
          content="Residents - Venco | All-in-one Community Management Software"
        />
      </Helmet>
      <NavBarDark />
      {/* <NavBar /> */}
      <ResidentsHero />
      <Stats />
      <SimplifiedPayment />
      <ResidentFeatures />
      <HowCTAComp
        title={
          "Our Resident product section is tailored to enhance your day-to-day life within multi-unit residential and commercial communities."
        }
        link={"/how-it-works-for-residents"}
      />
      <Testimonial />
      <FAQ />
      <JoinTheBest />
      <Footer />
    </>
  );
};

export default ResidentsPage;
