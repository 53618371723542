import { ArrowUpRight, X } from "phosphor-react";
import React, { useState } from "react";

const ViewDemoButton = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleModal = (value) => {
    setModalIsOpen(value);
  };

  return (
    <>
      <button
        className="flex py-4 px-10 border-light-green-500  text-light-green-500 rounded-lg border font-semibold items-center justify-center gap-2.5 hover:bg-green-700 duration-300 transition-all text-sm md:text-base xl:text-lg"
        onClick={() => {
          toggleModal(true);
        }}
        // href="https://www.youtube.com/watch?v=uXnaU9TP4c8"
        // target="_blank"
        // rel="noreferrer"
      >
        <p>Watch Demo</p>
        <ArrowUpRight className="text-[20px]" />
      </button>
      {modalIsOpen && (
        <div
          className="fixed top-0 bottom-0 left-0 z-[1000] w-full h-full bg-[#0d2d0e8e] p-4 flex items-center"
          id="overlay1"
          onClick={(e) => {
            if (e.target.id === "overlay1") {
              toggleModal(false);
            }
          }}
        >
          {/* <div className="max-w-[600px] center mx-auto md:w-[60%] bg-white p-4 md:p-6 relative top-1/2 -translate-y-1/2 rounded-lg"> */}
          <div
            className="
                  md:h-[60%] lg:h-[70%] lg:w-[60%] overflow-hidden flex items-center justify-center aspect-video mx-auto
                "
          >
            <div
              className="w-8 h-8 rounded-full center absolute top-4 md:top-6 right-4 md:right-6 z-1 bg-white"
              onClick={() => {
                toggleModal(false);
              }}
            >
              <X weight="bold" className="text-xl" />
            </div>
            {/* <iframe
              src="https://tally.so/popup/wQKONg?originPage=%2F&amp;alignLeft=1"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
              title="Tally Forms"
              className="h-fit"
              style={{
                width: "1px",
                minWidth: "100%",
                overflow: "auto",
                height: "720px",
              }}
              data-tally-embed-widget-initialized="1"
              id="iFrameResizer0"
              // scrolling="yes"
            ></iframe> */}
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/uXnaU9TP4c8"
              title="Venco | Estate and Property Management Solutions"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewDemoButton;
