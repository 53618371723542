import React, { useEffect } from "react";
import { RiBuilding2Line, RiCommunityLine, RiGroupLine } from "react-icons/ri";

const Stats = () => {
  useEffect(() => {
    const counters = document.querySelectorAll(".value");
    const speed = 400;

    // Define the target div you want to observe
    const targetDiv = document.querySelector("#statSection");

    // Create an Intersection Observer
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio >= 0.4) {
            // Run your JavaScript function here
            counters.forEach((counter) => {
              const animate = () => {
                const value = +counter.getAttribute("akhi");
                const data = +counter.innerText.replace(/,/g, "");

                const time = value / speed;
                if (data < value) {
                  // Format the number with commas for thousands
                  counter.innerText = Math.ceil(data + time).toLocaleString();
                  setTimeout(animate, 4);
                } else {
                  // Format the final value with commas
                  counter.innerText = value.toLocaleString();
                }
              };

              animate();
            });
          }
        });
      },
      { threshold: 0.4, rootMargin: "60%" }
    );

    // Start observing the target div
    observer.observe(targetDiv);

    // Cleanup the observer when the component unmounts
    return () => {
      observer.unobserve(targetDiv);
      observer.disconnect();
    };
  }, []);

  return (
    <section
      className="mt-24 xl:py-24 mx-auto container px-4 font-sora"
      id="statSection"
    >
      <div className="flex justify-center gap-14 md:gap-24 lg:gap-20 xl:gap-32 items-center flex-col md:flex-row">
        <div
          data-aos="fade-up"
          className="
        gap-4 lg:gap-6 xl:gap-8 flex items-center
        flex-col justify-center lg:flex-row md:justify-start
        "
        >
          <div className="rounded-full center bg-light-green-500 p-6 lg:p-4 xl:p-6">
            <RiBuilding2Line className="text-[36px] lg:[42px] xl:text-[50px] text-green-300" />
          </div>
          <div className="text-green-500 font-medium">
            <p className="text-3xl lg:text-4xl 2xl:text-5xl">
              {" "}
              <span className="value" akhi="12">
                0
              </span>{" "}
              Cities
            </p>
            <div className="h-1.5 lg:h-2 xl:h-2.5" />
            <p className="text-sm lg:text-xl font-inter text-center md:text-left">
              Across Africa
            </p>
          </div>
        </div>

        <div
          data-aos="fade-up"
          data-aos-delay="0"
          data-aos-easing="ease-out"
          className="
        gap-4 lg:gap-6 xl:gap-8 flex items-center
        flex-col justify-center lg:flex-row md:justify-start
        "
        >
          <div className="rounded-full center bg-light-green-500 p-6 lg:p-4 xl:p-6">
            <RiCommunityLine className="text-[36px] lg:[42px] xl:text-[50px] text-green-300" />
          </div>
          <div className="text-green-500 font-medium">
            <p className="text-3xl lg:text-4xl 2xl:text-5xl">
              <span className="value" akhi="22000">
                0
              </span>
              {""}+
            </p>
            <div className="h-1.5 lg:h-2 xl:h-2.5" />
            <p className="text-sm lg:text-xl font-inter text-center md:text-left">
              Property Units
            </p>
          </div>
        </div>

        <div
          data-aos="fade-up"
          data-aos-delay="0"
          data-aos-easing="ease-out"
          className="
        gap-4 lg:gap-6 xl:gap-8 flex items-center
        flex-col justify-center lg:flex-row md:justify-start
        "
        >
          <div className="rounded-full center bg-light-green-500 p-6 lg:p-4 xl:p-6">
            <RiGroupLine className="text-[36px] lg:[42px] xl:text-[50px] text-green-300" />
          </div>
          <div className="text-green-500 font-medium">
            <p className="text-3xl lg:text-4xl 2xl:text-5xl">
              <span className="value" akhi="31000">
                0
              </span>
              {""}+
            </p>
            <div className="h-1.5 lg:h-2 xl:h-2.5" />
            <p className="text-sm lg:text-xl font-inter text-center md:text-left">
              Residents
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Stats;
