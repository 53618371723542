import React, { useState } from "react";
import { Helmet } from "react-helmet";
import NavBarDark from "../../components/NAVBAR/NavBarDark";
import Footer from "../../components/FOOTER/Footer";
import FAQ from "../../components/FAQ/FAQ";
import HowItWorksDesktop from "../../components/HowItWorks/howItWorksDesktop";
import {
  residentExisting,
  residentNew,
} from "../../components/HowItWorks/howItWorksData";
import DownloadCTA from "../../components/HowItWorks/DownloadCTA";
import HowItWorksMobile from "../../components/HowItWorks/howItWorksMobile";

const ResidentsHowItWorks = () => {
  const [tab, setTab] = useState(1);
  const [accordion, setAccordion] = useState(1);
  const [userType, setUserType] = useState("new");

  return (
    <>
      <Helmet>
        <title>
          Get Started - Venco | All-in-one Community Management Software
        </title>
        <meta
          property="og:title"
          content="Get Started - Venco | All-in-one Community Management Software"
        />
        <meta
          name="twitter:title"
          content="Get Started - Venco | All-in-one Community Management Software"
        />
      </Helmet>
      <NavBarDark />
      <section className="container mx-auto my-10 xl:py-[100px] p-4">
        <p
          className="rounded-full px-6 py-2 bg-light-purple-150  center w-fit mx-auto leading-8 font-medium text-xs
        
        md:bg-transparent md:rounded-none md:text-3xl lg:text-5xl md:font-sora md:font-semibold
        "
        >
          How it works
        </p>
        <div className="h-10 hidden md:block"></div>
        <p className="font-sora font-semibold leading-10 text-center md:hidden">
          Joining your Community As
        </p>

        {/* Section Tab */}
        <div className="p-2.5 rounded-full bg-[#F8F8F7] center relative max-w-[875px] lg:mx-auto">
          <div
            className={`absolute w-1/2 bg-[#CBF07F] h-[calc(100%-20px)] rounded-full transition-all z-0 duration-500 ${
              tab === 1 ? "left-2.5" : "left-[calc(50%-10px)]"
            }`}
          ></div>
          <button
            onClick={() => {
              setTab(1);
              setAccordion(1);
              setUserType("new");
            }}
            className={`transition-all duration-300 flex-1 py-4 center font-sora font-medium text-green-500 rounded-full relative z-[1]
            text-sm lg:text-lg`}
            // ${tab === 1 ? "bg-[#CBF07F]" : "bg-transparent"}
          >
            <span className="hidden md:inline-block whitespace-pre">
              Join your community as a{" "}
            </span>
            New User
          </button>
          <button
            onClick={() => {
              setTab(2);
              setAccordion(1);
              setUserType("existing");
            }}
            className={`transition-all duration-300 flex-1 py-4 center font-sora font-medium text-green-500 rounded-full relative z-[1]
            text-sm lg:text-lg`}
            // ${tab === 2 ? "bg-[#CBF07F]" : "bg-transparent"}
          >
            <span className="hidden md:inline-block whitespace-pre">
              Join your community as an{" "}
            </span>
            Existing User
          </button>
        </div>
        <div className="h-14"></div>

        {/* Content Section Mobile */}

        {userType === "new" && <HowItWorksMobile data={residentNew} />}

        {userType === "existing" && (
          <HowItWorksMobile data={residentExisting} />
        )}

        {userType === "new" && (
          <HowItWorksDesktop
            accordion={accordion}
            setAccordion={setAccordion}
            data={residentNew}
            compId="resident1"
          />
        )}

        {userType === "existing" && (
          <HowItWorksDesktop
            accordion={accordion}
            setAccordion={setAccordion}
            data={residentExisting}
            compId="resident2"
          />
        )}
      </section>
      <DownloadCTA />
      <FAQ />
      <Footer />
    </>
  );
};

export default ResidentsHowItWorks;
