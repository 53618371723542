import React from "react";
import featuresShape4 from "../../assets/images/features3.svg";
import Payment from "../../assets/images/residentPayment.svg";


const SimplifiedPayment = () => {
  return (
    <section
      className="mt-[30px] xl:my-[170px] container mx-auto lg:p-4"
      data-aos="fade-up"
      data-aos-delay="20"
    >
      <div className=" keyFeatures">
        <div className=" flex items-center justify-center relative p-4 overflow-hidden md:w-1/2 lg:w-[45%] ">
          <div className="absolute bottom-0 lg:top-0 left-0 z-0 w-full ">
            <img
              src={featuresShape4}
              alt=""
              className="w-full lg:-scale-x-100"
            />
          </div>
          <img src={Payment} alt="" className="relative z-1 scale-75" />
        </div>

        <div className="py-20 px-4 center md:w-1/2  lg:w-[55%] lg:p-10">
          <div className="flex flex-col gap-[30px] items-center justify-center md:items-start">
            <p className="text-center text-2xl font-sora font-semibold text-new-black-1000 lg:text-3xl">
              Simplified Payments
            </p>
            <p className="font-inter text-sm leading-[22px] text-center text-[#4D4D4D] md:text-left lg:text-base xl:text-[20px] xl:leading-[32px] md:w-[300px] lg:w-[400px] xl:w-[505px]">
              Automated billing, collections and reconciliation functionality on
              our apps means that late or no-payment of communal dues are a
              thing of the past. We’ve built in revenue assurance features that
              reduce defaults in payments.
            </p>
            
          </div>
        </div>
      </div>
    </section>
  );
};

export default SimplifiedPayment;
